import { connect } from 'react-redux';
import Villages from '../pages/Villages';
import { toggleCategory, updateCategoriesBySlug } from '../actions/Categories';

import {
  getDataFlat,
  dataQuickSearch,
  exportData,
  importData,
  getDataFlatForMap,
  dataQuickSearchForMap
} from '../actions/Data';
import { saveUserState } from '../actions/User';
import { deepSearch } from '../services/helpers';

const mapStateToProps = state => {
  const { columns } = state.Data;
  const cats = state.Categories.data;
  const filterdCategories = [];

  for (let i = 0; i < cats.length; i++) {
    if (cats[i].children) {
      filterdCategories.push(Object.assign({}, cats[i], { children: deepSearch(cats[i].children) }));
    }
  }

  return {
    User: state.User,
    Categories: state.Categories,
    filterdCategories: filterdCategories.filter(i => i.children.length),
    Data: state.Data,
    Columns: {
      fixed: columns.filter(i => i.fixed && i.is_visible),
      others: columns.filter(i => !i.fixed && i.is_visible)
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDataFlat(params) {
      return dispatch(getDataFlat(params));
    },
    dataQuickSearch(params) {
      return dispatch(dataQuickSearch(params));
    },
    exportData(params) {
      dispatch(exportData(params));
    },
    importData(body) {
      return dispatch(importData(body));
    },
    toggleCategory(cat, checked) {
      return dispatch(toggleCategory(cat, checked));
    },
    saveUserState(body, id) {
      return dispatch(saveUserState(body, id));
    },
    updateCategoriesBySlug(slugs) {
      dispatch(updateCategoriesBySlug(slugs));
    },
    getDataFlatForMap(params) {
      return dispatch(getDataFlatForMap(params));
    },
    dataQuickSearchForMap(params) {
      return dispatch(dataQuickSearchForMap(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Villages);
