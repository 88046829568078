import { connect } from "react-redux"
import Bookmarks from "../../pages/MyListings/Bookmarks"
import { editBookmark, getBookmarks, deleteBookmark } from "../../actions/Villages"

const mapStateToProps = state => {
	return {
		User: state.User,
		Villages: state.Villages
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getBookmarks() {
			dispatch(getBookmarks())
		},
		deleteBookmark(id) {
			return dispatch(deleteBookmark(id))
		},
		editBookmark(body, id) {
			return dispatch(editBookmark(body, id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Bookmarks)
