import Store from "../store";

export const isAdmin = () => {
	const { data } = Store.getState().User;
	const role = data.role ? data.role : data.group ? data.group.role : "";

	return role === "admin";
};

export const isModerator = () => {
	const { data } = Store.getState().User;
	const role = data.group ? data.group.role : "";
	return role === "moderator";
};

export const isViewer = () => {
	const { data } = Store.getState().User;
	const role = data.group ? data.group.role : "";
	return role === "viewer";
};
