import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Lightbox from 'react-images';
import Slider from 'react-slick';
import { isImage, updateFiles } from '../services/helpers';
import { sliderSettings } from '../constants/';

class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lightboxIsOpen: false,
      currentIndex: props.current || 0
    };
  }

  openLightbox = (e, file, arr) => {
    e.stopPropagation();
    const images = arr.filter(i => isImage(i.name));
    this.setState({
      currentIndex: images.findIndex(i => i.name === file.name) || 0,
      lightboxIsOpen: true
    });
  };

  closeLightbox = () => {
    this.setState({
      currentIndex: 0,
      lightboxIsOpen: false
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentIndex: this.state.currentIndex - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentIndex: this.state.currentIndex + 1
    });
  };

  gotoImage = index => {
    this.setState({
      currentIndex: index
    });
  };

  handleClickImage = images => {
    const { currentIndex } = this.state;
    if (currentIndex === images.length - 1) return false;
    this.gotoNext();
  };

  renderList = files => {
    const { deleteFile } = this.props;
    return files.map((file, i) => (
      <div key={i} className="item">
        <div className="attached-image-wrapper">
          {isImage(file.name) ? (
            <div
              className="attached-image"
              style={{ backgroundImage: `url(${file.src})` }}
              onClick={e => this.openLightbox(e, file, files)}
            />
          ) : (
            <div className="attached-image">
              <a href={file.src} download target="_blank" rel="noopener noreferrer">
                .{file.name.split('.').last()}
              </a>
            </div>
          )}
          <span className="image-caption">
            {file.name}
            {!!deleteFile && (
              <Button color="link" onClick={e => deleteFile(file)}>
                <i className="material-icons">delete</i>
              </Button>
            )}
          </span>
        </div>
      </div>
    ));
  };

  render() {
    const { data, listView } = this.props;
    if (!data && !data.length) return null;

    const { currentIndex, lightboxIsOpen } = this.state;
    const files = updateFiles(data);
    const images = files.filter(i => isImage(i.name));

    return (
      <div className="mb-4 mt-4">
        {!listView ? (
          <Slider className="attached-files" {...sliderSettings}>
            {this.renderList(files)}
          </Slider>
        ) : (
          <ul className="attached-files files-list">{this.renderList(files)}</ul>
        )}
        <Lightbox
          images={images}
          isOpen={lightboxIsOpen}
          currentImage={currentIndex}
          onClickImage={() => this.handleClickImage(images)}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
          backdropClosesModal={true}
          preloadNextImage={false}
          showThumbnails={true}
        />
      </div>
    );
  }
}

export default Files;
