import React, { Fragment } from 'react';
import classnames from 'classnames';
import { isString, isObject } from 'util';
import Checkbox from '../Checkbox';
import { Button } from 'reactstrap';
import i18n from '../../locale/i18n';

const RowRenderer = props => {
  const { items, columns, isfixed, checked, hovered, history, toggleVillage, goToMouments, setHovered } = props;

  return items.map((item, index) => (
    <tr
      key={item.id_village}
      onClick={() => history.push(`village/${item.id_village}`)}
      onMouseEnter={() => setHovered(index)}
      className={classnames({ hovered: hovered === index })}>
      {isfixed && (
        <td className="fixed action-col" onClick={e => e.stopPropagation()}>
          <Checkbox
            name={item.id_village}
            className="custom-control-input"
            checked={checked.includes(item.id_village)}
            onChange={e => toggleVillage(e.target.checked, item.id_village)}
          />
        </td>
      )}
      {columns.map(({ key, fixed }, i) => (
        <td key={key} className={classnames({ fixed: fixed })}>
          {key === 'monument_count' && item[key] !== '0' ? (
            <Button
              color="link cell-text"
              block
              onClick={e => goToMouments(e, item.id_village)}
              disabled={!Boolean(item[key])}>
              {item[key] || '-'}
            </Button>
          ) : (
            <Fragment>
              {isString(item[key]) ? (
                <span className="cell-text" title={item[key]}>
                  {item[key] || '-'}
                </span>
              ) : isObject(item[key]) ? (
                <Fragment>
                  <span className="cell-text" title={item[key].value}>
                    {(item[key] && item[key].value) || '-'}
                  </span>
                  <div className="data-sources">
                    {item[key].sources.map((source, j) => (
                      <b key={`${i}_${j}`} className="source-code">
                        <span data-tooltip={source[`description_${i18n.language}`]}>{source.code}</span>
                      </b>
                    ))}
                  </div>
                </Fragment>
              ) : (
                '-'
              )}
            </Fragment>
          )}
        </td>
      ))}
    </tr>
  ));
};

export default RowRenderer;
