import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const SummaryListItem = ({ item, tab }) => {
  const [expanded, toggleCollaps] = useState(false);
  return (
    <div className="collapse-item">
      <div className="collapse-herader">
        <div className={`collapse-toggler ${expanded ? 'active' : ''}`} onClick={() => toggleCollaps(!expanded)}>
          {item.place.place_name} ({item.forms.length})<i className="item-icon material-icons">arrow_drop_down</i>
        </div>
      </div>
      {expanded && (
        <div className="collapse-body">
          {item.forms.map((form) => (
            <NavLink
              to={`/forms/${tab.key}/${form.id}/${item.place.place_id}`}
              className="summary-list-item"
              key={form.id}>
              <span>{form.name}</span>
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default SummaryListItem;
