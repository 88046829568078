import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import AdvancedSearchProps from '../components/AdvancedSearchProps';
import Pagination from '../components/Pagination';
import { getMapData } from '../services/helpers';
import VillagesMap from '../pages/VillagesMap';
import Loading from '../components/Loading';
import Total from '../components/Total';
import i18n from '../locale/i18n';

class AdvancedSearchResults extends Component {
  state = {
    mapsModal: false,
    mapData: [],
    expoertLoading: false,
    saveModal: false
  };

  componentDidMount() {
    const {
      history,
      advanceSearch,
      Data: {
        advancedSearch: { params, data, loading }
      }
    } = this.props;
    const { searchParams } = localStorage;
    const parsed = searchParams && searchParams !== 'undefined' ? JSON.parse(searchParams) : null;

    if (!data.length && !loading) {
      if (params.queries.length) {
        advanceSearch(params);
      } else if (parsed) {
        advanceSearch({ params: parsed });
      } else {
        history.replace('/advanced-search');
      }
    }
  }

  handlePageChange = pageNumber => {
    const {
      advanceSearch,
      Data: {
        advancedSearch: { params }
      }
    } = this.props;
    advanceSearch({ params: { ...params, page: pageNumber.selected + 1 } });
  };

  toggleSaveModal = () => this.setState(state => ({ saveModal: !state.saveModal }));

  handleSubmitSave = (e, values) => {
    const {
      advanceSearchSave,
      Data: {
        advancedSearch: { params }
      }
    } = this.props;
    advanceSearchSave({
      name: values.name,
      params
    }).then(() => {
      this.setState({
        saveModal: false
      });
    });
  };

  advanceSearchExportHandler = () => {
    const {
      advanceSearchExport,
      Data: {
        advancedSearch: { params }
      }
    } = this.props;
    this.setState({ expoertLoading: true });
    advanceSearchExport({ params: { queries: params.queries } })
      .then(() => {
        this.setState({ expoertLoading: false });
      })
      .catch(() => {
        this.setState({ expoertLoading: false });
      });
  };

  togglemapsModal = () => {
    this.setState(state => ({ mapsModal: !state.mapsModal }));
  };

  openGooleMaps = () => {
    const {
      Data: {
        advancedSearch: { params }
      },
      advanceSearchForMap
    } = this.props;
    this.setState({ mapsModal: true, mapData: [] });
    advanceSearchForMap({ params: params, coordinates: true }).then(({ data }) => {
      this.setState({
        mapData: data.data ? getMapData(data.data) : []
      });
    });
  };

  render() {
    const {
      history,
      Data: {
        advancedSearch: { params, data, loading, total }
      }
    } = this.props;
    const { saveModal, expoertLoading, mapsModal, mapData } = this.state;

    return (
      <div className="app-content-wrapper">
        <div className="app-content content-fluid">
          <div className="app-content-header">
            <h1>{i18n.t('ADVANCED_SEARCH_RESULTS')}</h1>
          </div>
          <div className="app-content-inner pt-5">
            <Button onClick={e => history.goBack()} color="link btn-back">
              <i className="material-icons">&#xE317;</i>
              {i18n.t('BACK')}
            </Button>
            <div className="content-container">
              {params.queries.length ? (
                <AdvancedSearchProps queries={params.queries} onSaveHandler={this.toggleSaveModal} />
              ) : null}
              {loading ? (
                <Loading />
              ) : data.length ? (
                <Fragment>
                  <div className="d-flex justify-content-between mt-5 mb-3">
                    <h3 className="m-0">
                      {i18n.t('RESULTS')} <Total {...{ total, page: params.page, limit: params.limit }} />
                    </h3>
                    <div>
                      <Button
                        color="primary"
                        className={expoertLoading ? 'btn-loading' : ''}
                        onClick={this.advanceSearchExportHandler}>
                        <i className="material-icons">file_download</i> {i18n.t('DOWNLOAD')}
                      </Button>
                      <Button color="primary ml-3" onClick={this.openGooleMaps}>
                        <i className="material-icons">map</i> {i18n.t('SHOW_ON_MAP')}
                      </Button>
                    </div>
                  </div>
                  <div className="content-box">
                    <table className="table table-simple">
                      {/* list-group-table */}
                      <tbody>
                        {data.map((village, i) => (
                          <tr
                            key={i}
                            className="with-action"
                            onClick={() => history.push(`/village/${village.id_village}`)}>
                            <td>{village.village}</td>
                            <td>
                              <strong className="d-block">{i18n.t('state')}</strong> {village.state}
                            </td>
                            <td>
                              <strong className="d-block">{i18n.t('district')}</strong> {village.district}
                            </td>
                            <td>
                              <strong>{village.category}</strong>
                              <div className="text-highlight" dangerouslySetInnerHTML={{ __html: village.highlight }} />
                            </td>
                            <td className="text-right">
                              <i className="material-icons">&#xE315;</i>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    total={total}
                    limit={params.limit}
                    page={params.page}
                    onPageChange={this.handlePageChange}
                  />
                </Fragment>
              ) : (
                <div className="empty-result">No results</div>
              )}
            </div>
          </div>
        </div>
        <Modal isOpen={saveModal} toggle={this.toggleSaveModal} size="sm">
          <ModalHeader toggle={this.toggleSaveModal}>Անվանել Որոնումը</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitSave}>
            <ModalBody>
              <AvGroup>
                <AvInput name="name" type="text" required />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleSaveModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE_AND_SEARCH')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={mapsModal} toggle={this.togglemapsModal} className="map-modal">
          <Button color="default" className="close" onClick={this.togglemapsModal}>
            <i className="material-icons">close</i>
          </Button>
          {mapData.length ? (
            <VillagesMap villages={mapData} />
          ) : (
            <div className="empty-result py-5 text-center">Loading...</div>
          )}
        </Modal>
      </div>
    );
  }
}

export default AdvancedSearchResults;
