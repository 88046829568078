import React, { Fragment } from 'react';
import classnames from 'classnames';

const FormFieldPreview = ({ field, data, toggleRejected }) => {
  return (
    <div className={classnames({ rejected: data.rejected, active: !!toggleRejected }, 'form-control-preview')}>
      {data.content}
      {!!toggleRejected && (
        <Fragment>
          <label className="reject-toggle" data-tooltip="Մերժել">
            <input
              type="checkbox"
              className="d-none"
              name={field.name}
              checked={data.rejected}
              onChange={toggleRejected}
            />
            <i className="material-icons">remove_circle</i>
          </label>
          <span className="reject-label">Մերժված է</span>
        </Fragment>
      )}
    </div>
  );
};

export default FormFieldPreview;
