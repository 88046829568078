import { initialUser } from '../services/token';
import { initialLanguages } from '../constants/';
import i18n from '../locale/i18n';

const User = (
  state = {
    data: initialUser(),
    languages: initialLanguages,
    savedStates: [],
    comparisons: [],
    recentState: null,
    language: i18n.language,
    loading: false,
    regions: {
      states: [],
      districts: [],
      places: []
    }
  },
  action
) => {
  switch (action.type) {
    case 'USER_REQ':
      return { ...state, loading: true };
    case 'USER_RES':
      return { ...state, data: { ...state.data, ...action.payload }, loading: false };
    case 'LANGUAGES_RES':
      return { ...state, languages: action.payload, loading: false };
    case 'USER_EDIT_REQ':
      return { ...state, loading: true };
    case 'USER_EDIT_RES':
      return { ...state, data: { ...state.data, ...action.payload }, loading: false };
    case 'CHANEGE_LANGUAGE':
      return { ...state, language: action.payload };
    case 'USER_STATE_RES':
      return { ...state, savedStates: action.payload };
    case 'USER_STATE_DELETE':
      return { ...state, savedStates: state.savedStates.filter(i => i.id_user_state !== action.payload) };
    case 'RECENTSTATE_RES':
      return { ...state, recentState: action.payload };
    case 'COMPARE_LIST':
      return { ...state, comparisons: action.payload };
    case 'COMPARE_SAVED':
      return { ...state, comparisons: [...state.comparisons, action.payload] };
    case 'USER_SETTINGS_RES':
      return {
        ...state,
        regions: action.payload.regions || action.payload
      };
    case 'USER_LOGOUT':
      return { ...state, data: null };
    default:
      return state;
  }
};

export default User;
