import React, { Component } from 'react';
import { TabContent, Nav, NavItem, NavLink } from 'reactstrap';
import SummaryList from '../../components/Forms/SummaryList';
import Loading from '../../components/Loading';
import { summaryTabs } from '../../constants';
import classnames from 'classnames';
import moment from 'moment';

class FormsSummary extends Component {
  state = {
    activeTab: this.props.location.hash || 'drafts'
  };

  componentDidMount() {
    this.props.resetCurrent();
  }

  toggleTab = (activeTab) => this.setState({ activeTab });

  render() {
    const { Summary } = this.props;
    const { activeTab } = this.state;
    return (
      <div className="app-content">
        <div className="app-content-header" />
        <div className="app-content-inner summary-content-wrapper">
          <h1 className="text-center">
            Ամփոփում առ <span className="text-capitalize">{moment().format('DD MMMM YYYY')}</span>
          </h1>
          {Summary ? (
            <div className="content-box ">
              <div className="summary-header">
                <h2>Բնակավայրեր</h2>
                <div className="summary-stats">
                  {Summary.submitted.total} / {Summary.formsTotal}
                </div>
              </div>
              <div className="summary-content">
                <Nav className="summary-nav-tabs" tabs>
                  {summaryTabs.map((tab) => (
                    <NavItem key={tab.key}>
                      <NavLink
                        className={classnames({ active: activeTab === tab.key })}
                        onClick={() => {
                          this.toggleTab(tab.key);
                        }}>
                        {tab.title} ({Summary[tab.key].total})
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={activeTab}>
                  {summaryTabs.map((tab) => (
                    <SummaryList summary={Summary} tab={tab} isTabContent={true} key={tab.key} />
                  ))}
                </TabContent>
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

export default FormsSummary;
