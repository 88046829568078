import { connect } from "react-redux"
import SavedSearches from "../../pages/MyListings/SavedSearches"
import { advanceSearch, getAdvanceSearchList } from "../../actions/Data"

const mapStateToProps = state => {
	return {
		User: state.User,
		Data: state.Data
	}
}

const mapDispatchToProps = dispatch => {
	return {
		advanceSearch(params) {
			dispatch(advanceSearch(params))
		},
		getAdvanceSearchList() {
			dispatch(getAdvanceSearchList())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedSearches)
