import React, { Component, Fragment } from 'react';
import i18n from '../locale/i18n';
import { clone } from '../services/helpers';
import { limits } from '../constants/';
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { isAdmin, isViewer } from '../services/permissions';
import { getMapData } from '../services/helpers';
import { Link } from 'react-router-dom';
import {
  Input,
  Button,
  UncontrolledDropdown as Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from 'reactstrap';
import VillagesMap from '../pages/VillagesMap';

class VillagesActions extends Component {
  state = {
    saveStateModal: false,
    useStateModal: false,
    columnsModal: false,
    mapsModal: false,
    mapData: [],
    checked: []
  };

  toggleSaveStateModal = () => this.setState(state => ({ saveStateModal: !state.saveStateModal }));
  toggleUseStateModal = () => this.setState(state => ({ useStateModal: !state.useStateModal }));
  toggleColumnsModal = () => this.setState(state => ({ columnsModal: !state.columnsModal }));

  toggleColumn = key => {
    const {
      getDataFlat,
      Data: { params }
    } = this.props;
    const cols = params.hiddenColums;
    const hiddenColums = cols.includes(key) ? cols.filter(c => c !== key) : [...cols, key];

    getDataFlat({ ...params, hiddenColums });
  };

  handleSubmitSave = (event, values) => {
    const {
      User: { data },
      Data: { params },
      saveUserState
    } = this.props;
    const cloned = clone(params);
    const body = { name: values.name, state: cloned };
    this.setState(state => ({ saveStateModal: false }));
    saveUserState(body, data.id_user).then(() => {
      toast.success(i18n.t('SUCCESS_SAVE_FILTERS'));
    });
  };

  handleSubmitUse = (event, values) => {
    const {
      User: { savedStates, language },
      getDataFlat,
      updateCategoriesBySlug
    } = this.props;
    const curState = savedStates.find(i => i.id_user_state === values.id_user_state);
    const params = {
      recent_state: false,
      id_user_state: values.id_user_state,
      categories: curState.state.categories || [],
      order_by: curState.state.order_by || 'asc',
      order_column: curState.state.order_column || null,
      archive_year: curState.state.archive_year || '',
      hiddenColums: curState.state.hiddenColums || [],
      id_district: curState.state.id_district || 0,
      id_state: curState.state.id_state || 0,
      limit: curState.state.limit || limits.dataFlat,
      page: curState.state.page || 1,
      lang: language
    };
    getDataFlat(params).then(() => {
      this.setState(state => ({ useStateModal: false }));
      updateCategoriesBySlug(params.categories);
    });
  };

  togglemapsModal = () => {
    this.setState(state => ({ mapsModal: !state.mapsModal }));
  };

  openGooleMaps = () => {
    const {
      Data: { params },
      getDataFlatForMap,
      dataQuickSearchForMap
    } = this.props;
    this.setState({ mapsModal: true, mapData: [] });
    if (params.keywords && params.keywords.length && params.keywords[0].keyword) {
      dataQuickSearchForMap({ ...params, coordinates: true }).then(({ data }) => {
        this.setState({
          mapData: data.data ? getMapData(data.data) : []
        });
      });
    } else {
      getDataFlatForMap({ ...params, coordinates: true }).then(({ data }) => {
        this.setState({
          mapData: data.data ? getMapData(data.data) : []
        });
      });
    }
  };

  openSelectedOnGooleMaps = () => {
    const {
      Data: { data },
      checked
    } = this.props;
    this.setState({
      mapsModal: true,
      mapData: getMapData(data.filter(v => checked.includes(v.id_village)))
    });
  };

  render() {
    const {
      Data,
      checked,
      exportData,
      handleCompare,
      toggleFilters,
      filterdsVisible,
      handleImportFile,
      filterdCategories,
      User: { language, savedStates }
    } = this.props;
    const { saveStateModal, useStateModal, columnsModal, mapsModal, mapData } = this.state;
    const hiddenCols = Data.columns.filter(c => !c.is_visible).sort(c => !c.fixed);
    const idAdminUser = isAdmin();

    return (
      <Fragment>
        <div className="filter-actions">
          {!!checked.length && (
            <Fragment>
              <Button color="primary" onClick={this.openSelectedOnGooleMaps} data-tooltip={i18n.t('SHOW_ON_MAP')}>
                <i className="material-icons m-0">map</i>
              </Button>
              <Button color="primary" onClick={handleCompare} data-tooltip={i18n.t('COMPARE')}>
                <i className="material-icons m-0">compare_arrows</i>
              </Button>
            </Fragment>
          )}
          <Link className="btn btn-success" to="/advanced-search">
            <i className="material-icons">search</i>
            {i18n.t('ADVANCED_SEARCH')}
          </Link>
          <Button color="primary" onClick={this.openGooleMaps}>
            <i className="material-icons">map</i>
            {i18n.t('MAP')}
          </Button>

          <Dropdown className="d-inline-block ml-2">
            <DropdownToggle color="secondary" caret>
              {i18n.t('TOOLS')}
            </DropdownToggle>
            <DropdownMenu right>
              {idAdminUser && (
                <DropdownItem onClick={() => exportData({ language: language, source: 1 })}>
                  <i className="material-icons">file_download</i>
                  {i18n.t('EXPORT_ADVANCED')}
                </DropdownItem>
              )}
              <DropdownItem onClick={() => exportData({ language: language, source: 0 })}>
                <i className="material-icons">cloud_download</i>
                {i18n.t('EXPORT_SIMPLE')}
              </DropdownItem>
              {!isViewer() && (
                <Label className="dropdown-item">
                  <i className="material-icons">file_upload</i>
                  {i18n.t('IMPORT')}
                  <Input
                    type="file"
                    onChange={handleImportFile}
                    className="d-none"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Label>
              )}
              <DropdownItem onClick={this.handleCompare} disabled={!Boolean(checked.length)}>
                <i className="material-icons">compare_arrows</i>
                {i18n.t('COMPARE')}
              </DropdownItem>
              <DropdownItem onClick={this.toggleColumnsModal} disabled={!Boolean(hiddenCols.length)}>
                <i className="material-icons icon-remove">add_circle</i>
                {i18n.t('SHOW_HIDDEN_COLUMNS')}
              </DropdownItem>
              <DropdownItem onClick={this.toggleSaveStateModal}>
                <i className="material-icons">check</i>
                {i18n.t('SAVE_FILTERS')}
              </DropdownItem>
              <DropdownItem onClick={this.toggleUseStateModal} disabled={!Boolean(savedStates.length)}>
                <i className="material-icons">replay</i>
                {i18n.t('USE_SAVED_FILTERS')}
              </DropdownItem>
              <DropdownItem onClick={toggleFilters} disabled={!Boolean(filterdCategories.length)}>
                <i className="material-icons">filter_list</i>
                {i18n.t(filterdsVisible ? 'HIDE_FILTERS' : 'SHOW_FILTERS')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>

        <Modal isOpen={saveStateModal} toggle={this.toggleSaveStateModal} size="sm">
          <ModalHeader toggle={this.toggleSaveStateModal}>{i18n.t('SAVE_FILTERS')}</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitSave}>
            <ModalBody>
              <AvGroup>
                <AvInput name="name" type="text" required />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleSaveStateModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={useStateModal} toggle={this.toggleUseStateModal} size="sm">
          <ModalHeader toggle={this.toggleUseStateModal}>{i18n.t('USE_SAVED_FILTERS')}</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitUse}>
            <ModalBody>
              <AvGroup>
                <AvInput name="id_user_state" type="select">
                  {savedStates.map((item, index) => (
                    <option key={index} value={item.id_user_state}>
                      {item.name}
                    </option>
                  ))}
                </AvInput>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleUseStateModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('USE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={columnsModal} toggle={this.toggleColumnsModal} size="sm">
          <ModalHeader toggle={this.toggleColumnsModal}>{i18n.t('HIDDEN_COLUMNS')}</ModalHeader>
          <ModalBody className="p-0">
            <div className="columns-list">
              {hiddenCols.map((col, i) => (
                <li key={i}>
                  <Button color="link text-dark" onClick={e => this.toggleColumn(col.key)}>
                    <i className="material-icons m-0">close</i>
                  </Button>
                  <span>{col.label}</span>
                </li>
              ))}
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={mapsModal} toggle={this.togglemapsModal} className="map-modal">
          <Button color="default" className="close" onClick={this.togglemapsModal}>
            <i className="material-icons">close</i>
          </Button>
          {mapData.length ? (
            <VillagesMap villages={mapData} />
          ) : (
            <div className="empty-result py-5 text-center">Loading...</div>
          )}
        </Modal>
      </Fragment>
    );
  }
}
export default VillagesActions;
