import instance, { setInstance, removeInstance } from "../services/api"
import errorHandler from "../services/errorHandler"
import { toast } from "react-toastify"
import { getLanguages } from "./User"
import i18n from "../locale/i18n"

export const login = body => {
  return dispatch => {
    dispatch({ type: "USER_REQ" })
    return instance
      .post(`/auth/login`, body)
      .then(({ data }) => {
        setInstance(data)
        dispatch(getLanguages())
        i18n.changeLanguage(data.user.system_lang)
        return dispatch({ type: "USER_RES", payload: data.user })
      })
      .catch(errorHandler)
  }
}

export const logout = () => {
  return dispatch => {
    dispatch({ type: "USER_LOGOUT" })
    removeInstance()
  }
}

export const forgotPassword = body => {
  return dispatch => {
    return instance
      .post(`/auth/forgot-password`, body)
      .then(({ data }) => {
        toast.success(data.message || "We have sent you an email with a link to reset your password.")
      })
      .catch(errorHandler)
  }
}

export const registration = (body, code) => {
  return dispatch => {
    return instance
      .post(`/user`, body, { headers: { Invitation: code } })
      .then(({ data }) => {
        return Promise.resolve(data)
      })
      .catch(errorHandler)
  }
}

export const resetPassword = (body, props) => {
  return dispatch => {
    return instance
      .post(`/auth/reset-password`, body)
      .then(({ data }) => {
        toast.success(data.message || "Your password successfuly changed!")
        props.history.push("/login")
      })
      .catch(errorHandler)
  }
}
