import React, { Component, Fragment } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { isAdmin, isModerator } from '../services/permissions';
import i18n from '../locale/i18n';

class AccountSidebar extends Component {
  isActiveRoute = (match, location) => {
    if (!match) {
      return false;
    }
    return match.path === location.pathname;
  };

  isActiveLocation = name => {
    const path = this.props.location.pathname;
    return path.indexOf(name) === 0;
  };

  isActiveRoute = (match, location) => {
    return location.pathname.indexOf('/account/user') > -1;
  };

  render() {
    return (
      <div className="app-sidebar">
        <div className="sidebar-nav">
          {this.isActiveLocation('/account') && (
            <Nav vertical>
              <NavItem>
                <NavLink className="nav-item-header" to={'/account/my-profile'}>
                  <i className="nav-item-icon material-icons">person</i>
                  <span className="nav-item-title">{i18n.t('MY_PROFILE')}</span>
                </NavLink>
              </NavItem>

              {isAdmin() && (
                <Fragment>
                  <NavItem>
                    <NavLink className="nav-item-header" to={'/account/groups'}>
                      <i className="nav-item-icon material-icons">group_work</i>
                      <span className="nav-item-title">{i18n.t('GROUPS_MANAGEMENT')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-item-header" isActive={this.isActiveRoute} to={'/account/users'}>
                      <i className="nav-item-icon material-icons">supervisor_account</i>
                      <span className="nav-item-title">{i18n.t('USER_MANAGEMENT')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-item-header" to={'/account/categories'}>
                      <i className="nav-item-icon material-icons">select_all</i>
                      <span className="nav-item-title">{i18n.t('CATEGORY_MANAGEMENT')}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-item-header" to={'/account/content-types'}>
                      <i className="nav-item-icon material-icons">create</i>
                      <span className="nav-item-title">{i18n.t('CONTENT_TYPES')}</span>
                    </NavLink>
                  </NavItem>
                </Fragment>
              )}
              {(isAdmin() || isModerator()) && (
                <NavItem>
                  <NavLink className="nav-item-header" to={'/account/activity-log'}>
                    <i className="nav-item-icon material-icons">query_builder</i>
                    <span className="nav-item-title">{i18n.t('ACTIVITY_LOG')}</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          )}
          {this.isActiveLocation('/my-listings') && (
            <Nav vertical>
              <NavItem>
                <NavLink className="nav-item-header" to={'/my-listings/saved-searches'}>
                  <i className="nav-item-icon material-icons">zoom_in</i>
                  <span className="nav-item-title">{i18n.t('SAVED_SEARCHES')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-item-header" to={'/my-listings/filtered-sections'}>
                  <i className="nav-item-icon material-icons">filter_list</i>
                  <span className="nav-item-title">{i18n.t('FILTERED_SECTIONS')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-item-header" to={'/my-listings/bookmarks'}>
                  <i className="nav-item-icon material-icons">star</i>
                  <span className="nav-item-title">{i18n.t('BOOKMARKS')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="nav-item-header" to={'/my-listings/comparisons'}>
                  <i className="nav-item-icon material-icons">compare_arrows</i>
                  <span className="nav-item-title">{i18n.t('COMPARISONS')}</span>
                </NavLink>
              </NavItem>
            </Nav>
          )}
        </div>
      </div>
    );
  }
}

export default AccountSidebar;
