const Forms = (
  state = {
    data: null,
    current: null,
    summary: null,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case 'FORMS_REQ':
      return {
        ...state,
        loading: true
      };
    case 'FORMS_RES':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'FORM_RES':
      return {
        ...state,
        current: action.payload,
        loading: false
      };
    case 'FORMS_RESET_CURRENT':
      return {
        ...state,
        current: null
      };
    case 'FORMS_SUMMARY_RES':
      return {
        ...state,
        summary: action.payload,
        loading: false
      };
    case 'FORMS_DRAFT_RES':
      const drafts = state.current.drafts.slice();
      const index = drafts.findIndex((d) => +d.id_place === +action.payload.id_place);
      if (index !== -1) drafts.splice(index, 1);
      drafts.push(action.payload);

      return {
        ...state,
        current: { ...state.current, drafts },
        data: state.data.map((i) => (i.id_form === state.current.id_form ? { ...i, drafts: drafts.length } : i))
      };

    default:
      return state;
  }
};

export default Forms;
