import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const date = new Date();
    return (
      <footer className="app-footer">
        <div className="text-center mt-2">© All Rights Reserved. {date.getFullYear()}</div>
      </footer>
    );
  }
}

export default Footer;
