export const icons = [
	"local_movies",
	"location_city",
	"bar_chart",
	"home",
	"account_balance",
	"local_hotel",
	"local_bar",
	"filter_5",
	"group",
	"nature_black",
	"directions_transit",
	"call",
	"extension",
	"place",
	"palette_black",
	"local_dining",
	"pool",
	"person_pin",
	"directions_bike",
	"beach_access",
	"nature_people",
	"local_florist",
	"wb_cloudy",
	"drive_eta",
	"ac_unit",
	"explore",
	"group_work",
	"pets",
	"track_changes",
	"brightness_medium"
]
