import { connect } from 'react-redux';
import Login from '../pages/Login';
import { login } from '../actions/Auth';
import { getUserData } from '../actions/User';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    login(body) {
      return dispatch(login(body, props.history));
    },
    getUserData(id) {
      return dispatch(getUserData(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
