import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import AccountSidebar from '../components/AccountSidebar';

import MyProfile from '../containers/Account/MyProfile';
// import MonumentsManagement from "../containers/Account/MonumentsManagement"
import CategoryManagement from '../containers/Account/CategoryManagement';
import ContentTypes from '../containers/Account/ContentTypes';
import GroupManagement from '../containers/Account/GroupManagement';
import UserManagement from '../containers/Account/UserManagement';
import AddMember from '../containers/Account/AddMember';
import UserProfile from '../containers/Account/UserProfile';
import ActivityLog from '../containers/Account/ActivityLog';
import { isAdmin } from '../services/permissions';

class Account extends Component {
  render() {
    return (
      <div className="app-content-wrapper">
        <AccountSidebar {...this.props} />
        <div className="app-content">
          <Switch>
            <Route exact path="/account/my-profile" component={MyProfile} />
            {isAdmin() && [
              <Route path="/account/groups" component={GroupManagement} key="GroupManagement" />,
              <Route exact path="/account/users" component={UserManagement} key="UserManagement" />,
              <Route exact path="/account/user/:userId" component={UserProfile} key="UserProfile" />,
              <Route exact path="/account/users/add" component={AddMember} key="AddMember" />,
              <Route exact path="/account/categories" component={CategoryManagement} key="CategoryManagement" />,
              <Route exact path="/account/content-types" component={ContentTypes} key="ContentTypes" />
              // <Route exact path="/account/monuments" component={MonumentsManagement} key="MonumentsManagement" />
            ]}
            <Route exact path="/account/activity-log" component={ActivityLog} />
            <Redirect from="/account" to="/account/my-profile" />
            <Redirect to="/account" />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Account;
