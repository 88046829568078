import React, { Component } from "react"
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { formatDate } from "../services/formatDate"
import { toast } from "react-toastify"
import i18n from "../locale/i18n"

class ProfileInfo extends Component {
	constructor(props) {
		super(props)

		this.state = {
			invitationModal: false
		}
	}

	toggleModal = () => {
		this.setState({
			invitationModal: !this.state.invitationModal
		})
	}

	render() {
		const { data, invitation } = this.props
		return (
			<div>
				<ListGroup>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("group")}</div>
						<div className="item-value">{(data.group && data.group.title) || "--"}</div>
					</ListGroupItem>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("email")}</div>
						<div className="item-value">{data.email}</div>
					</ListGroupItem>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("phone")}</div>
						<div className="item-value">{data.phone || "--"}</div>
					</ListGroupItem>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("address")}</div>
						<div className="item-value">{data.address || "--"}</div>
					</ListGroupItem>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("bday")}</div>
						<div className="item-value">{data.bday ? formatDate(data.bday) : "--"}</div>
					</ListGroupItem>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("position")}</div>
						<div className="item-value">{data.position || "--"}</div>
					</ListGroupItem>
					<ListGroupItem action>
						<div className="item-label">{i18n.t("department")}</div>
						<div className="item-value">{data.department || "--"}</div>
					</ListGroupItem>
					{invitation && (
						<ListGroupItem action>
							<div className="item-label">{i18n.t("invitation")}</div>
							<div className="item-value">
								<Button color="link" className="p-0" size="sm" onClick={this.toggleModal}>
									{invitation.title}
								</Button>
							</div>
						</ListGroupItem>
					)}
				</ListGroup>

				<Modal isOpen={this.state.invitationModal} toggle={this.toggleModal}>
					<ModalHeader toggle={this.toggleModal}>{i18n.t("invitation")}</ModalHeader>
					<ModalBody>
						{invitation && (
							<div>
								<ul className="invitation-details">
									<li>
										<b>{i18n.t("INVITATION_NAME")}:</b>
										<span>{invitation.title}</span>
									</li>
									<li>
										<b>{i18n.t("VALID_BEFORE")}:</b>
										<span>{invitation.expires}</span>
									</li>
									<li>
										<b>{i18n.t("MEMBERS_COUNT")}:</b>
										<span>{invitation.max_registrants}</span>
									</li>
								</ul>
								<div className="clipboard-wrapper">
									<input type="text" readOnly value={`${window.location.origin}/registration/${invitation.hash}`} />
									<CopyToClipboard
										onCopy={() => toast.success(i18n.t("LINK_COPIED"))}
										text={`${window.location.origin}/registration/${invitation.hash}`}>
										<span>{i18n.t("COPY_LINK")}</span>
									</CopyToClipboard>
								</div>
							</div>
						)}
					</ModalBody>
					<ModalFooter>
						<Button key="close" color="link" className="text-dark" onClick={this.toggleModal}>
							{i18n.t("CLOSE")}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

export default ProfileInfo
