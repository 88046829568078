import { connect } from 'react-redux';
import VillageEdit from '../pages/VillageEdit';
import { getVillage, editVillageData, addVillageFilles, deleteVillageFile } from '../actions/Villages';
import { getSources } from '../actions/Sources';
import { getDataFlat } from '../actions/Data';

const mapStateToProps = state => {
  return {
    User: state.User,
    Villages: state.Villages,
    Sources: (query = '') =>
      query.length
        ? state.Sources.data.filter(({ code }) => code.toLowerCase().indexOf(query.toLowerCase()) > -1)
        : state.Sources.data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVillage(id) {
      return dispatch(getVillage(id));
    },
    editVillageData(body, id) {
      return dispatch(editVillageData(body, id));
    },
    getDataFlat(params) {
      return dispatch(getDataFlat(params));
    },
    addVillageFilles(files, id) {
      return dispatch(addVillageFilles(files, id));
    },
    deleteVillageFile(file, id) {
      return dispatch(deleteVillageFile(file, id));
    },
    getSources() {
      dispatch(getSources());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VillageEdit);
