import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Label } from 'reactstrap';
import { baseUrl } from '../services/helpers';
import i18n from '../locale/i18n';
import Logos from '../components/Logos';

class Login extends Component {
  handleValidSubmit = (event, values) => {
    const { login, getUserData, history } = this.props;
    login(values).then(({ payload }) => {
      getUserData(payload.id_user);
      if (i18n.language !== payload.system_lang) i18n.changeLanguage(payload.system_lang);
      history.push('/');
    });
  };

  render() {
    return (
      <div className="auth-container">
        <div className="auth-content">
          <Logos />

          <div className="auth-form-wrapper">
            <div className="auth-form-header">
              <img src={baseUrl('img/shield.png')} width="50" alt="shield" />
              <div>
                <h5>Մուտք</h5>
                <h6>Տվյալների շտեմարան</h6>
              </div>
            </div>
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <AvGroup>
                <AvInput name="email" type="email" errorMessage="Տեքսը բացակայում է կամ ունի սխալ" required />
                <Label className="form-control-label">Էլ. հասցե</Label>
              </AvGroup>
              <AvGroup>
                <AvInput name="password" type="password" errorMessage="Տեքսը բացակայում է կամ ունի սխալ" required />
                <Label className="form-control-label">Գաղտանաբառ</Label>
              </AvGroup>
              <Link to="/forgot-password" className="form-link">
                Մոռացել եք գաղտանաբառը?
              </Link>
              <div className="form-actions">
                <Button color="primary" size="lg">
                  Մուտք
                </Button>
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
