import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { mapContentString } from '../services/helpers';

export default class MapComponent extends Component {
  componentDidMount() {
    this.loadMap();
  }

  markers = [];

  loadMap = () => {
    const { google } = this.props;
    if (google) {
      const maps = google.maps;
      const mapRef = this.refs.map;
      const node = ReactDOM.findDOMNode(mapRef);
      const mapConfig = Object.assign(
        {},
        {
          center: { lat: 40.7485722, lng: 44.0068633 },
          zoom: 11,
          scrollwheel: true,
          mapTypeId: 'roadmap'
        }
      );
      this.map = new maps.Map(node, mapConfig);

      this.setMarkers(this.map);
    }
  };

  setMarkers = map => {
    const { google, villages } = this.props;
    const bounds = new google.maps.LatLngBounds();

    villages.forEach(item => {
      const infowindow = new google.maps.InfoWindow({ content: mapContentString(item) });
      const marker = new google.maps.Marker({ position: item.location, map: map, title: item.name, infowindow });

      marker.addListener('click', () => {
        this.hideAllInfoWindows(map);
        marker.infowindow.open(map, marker);
      });

      bounds.extend(item.location);
      this.markers.push(marker);
    });

    map.fitBounds(bounds);

    if (villages.length === 1) {
      const listener = google.maps.event.addListener(map, 'idle', () => {
        if (map.getZoom() > 16) map.setZoom(14);
        google.maps.event.removeListener(listener);
      });
    }
  };

  hideAllInfoWindows = map => {
    this.markers.forEach(marker => {
      marker.infowindow.close(map, marker);
    });
  };

  render() {
    return (
      <div ref="map" className="map-component">
        loading map...
      </div>
    );
  }
}
