import React, { Component } from 'react';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { conditions, queryCondition, archiveYears } from '../constants/';
import QueryCollapse from '../components/QueryCollapse';
import { clone, toggleOne, updateCategoriesBySlug, isNumeric } from '../services/helpers';
import Checkbox from '../components/Checkbox';
import i18n from '../locale/i18n';
import { limits } from '../constants/';

class AdvancedSearch extends Component {
  state = {
    expands: {},
    categories: [],
    saveModal: false,
    queries: []
  };

  componentDidMount() {
    const { Categories } = this.props;

    this.setInitialCategories(Categories.data);
  }

  componentDidUpdate() {
    const { Categories } = this.props;
    if (!this.state.categories.length && Categories.data.length) {
      this.setInitialCategories(Categories.data);
    }
  }

  uncheckAll = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      arr[i].is_checked = 0;
      if (arr[i].children) this.uncheckAll(arr[i].children);
    }
  };

  setInitialCategories = (data) => {
    const {
      match: { path },
      history
    } = this.props;
    const { searchParams } = localStorage;
    const parsed = searchParams && searchParams !== 'undefined' ? JSON.parse(searchParams) : null;
    const categories = clone(data);
    this.uncheckAll(categories);

    if (path === '/advanced-search/edit') {
      if (parsed) {
        this.setState({
          categories: categories,
          queries: parsed.queries.map((query, i) => ({
            ...query,
            keywordStr: '',
            collapsed: true,
            tree: updateCategoriesBySlug(clone(categories), query.categories)
          }))
        });
      } else {
        history.replace('/advanced-search');
      }
    } else {
      this.setState({
        categories: categories,
        queries: [
          {
            collapsed: false,
            query_condition: '',
            id_state: '',
            id_district: '',
            keyword: [],
            keywordStr: '',
            condition: 'contain',
            archive_year: '',
            keyword_source: '',
            tree: clone(categories),
            selectedCategories: []
          }
        ]
      });
    }
  };

  handleChangeCondition = ({ target }, index) => {
    this.setState((state) => {
      const query = state.queries[index];
      if (isNumeric(target.value) !== isNumeric(query.condition)) {
        query.selectedCategories = this.getSelectedCategories(target.value, query.tree);
      }
      query.condition = target.value;
      return {
        queries: state.queries
      };
    });
  };

  handleChangeInput = ({ target }, index, name) => {
    this.setState((state) => {
      const query = state.queries[index];
      query[name] = target.value;
      return {
        queries: state.queries
      };
    });
  };

  handleChangeLocation = ({ target }, index, name) => {
    const { districts, states } = this.props.User.regions;
    let data = null;

    if (name === 'state') {
      data = states.find((i) => i.id === target.value).name;
    } else if (name === 'district') {
      data = districts.find((i) => i.id === target.value).name;
    }

    this.setState((state) => {
      const query = state.queries[index];
      query[`id_${name}`] = target.value;
      query[name] = data;
      return {
        queries: state.queries
      };
    });
  };

  toggleCollapse = (catId, index) => {
    this.setState(({ expands }) => {
      if (expands[index]) {
        if (!expands[index].includes(catId)) {
          expands[index] = [...expands[index], catId];
        } else {
          expands[index] = expands[index].filter((id) => id !== catId);
        }
      } else {
        expands[index] = [catId];
      }

      return {
        expands: expands
      };
    });
  };

  getSelectedCategories = (condition, tree, arr = []) => {
    tree.forEach((item) => {
      if (item.is_checked === 2) {
        const cat = { slug: item.slug, name: item.name, type: item.type, has_content: item.has_content };
        const has_content = Number(item.has_content);
        const numericCat = item.type === 'number';
        const numericType = isNumeric(condition);
        if (!has_content || (has_content && !numericType) || (has_content && numericType && numericCat)) {
          arr.push(cat);
        }
      }
      if (item.children) {
        this.getSelectedCategories(condition, item.children, arr);
      }
    });
    return arr;
  };

  onCategoryToggle = (id, checked, index) => {
    this.setState((state) => {
      const updated = clone(state.queries);
      toggleOne(updated[index].tree, id, checked, updated[index].tree);
      updated[index].selectedCategories = this.getSelectedCategories(updated[index].condition, updated[index].tree);
      return {
        queries: updated
      };
    });
  };

  categoryList = (tree, index, condition) => {
    const lang = this.props.User.language;
    const { expands } = this.state;

    if (Boolean(tree))
      return (
        <ul className="panel-list">
          {tree.map((item, i) => {
            const vars = {
              has_content: Number(item.has_content),
              checked: item.is_checked === 2,
              indeterminate: item.is_checked === 1,
              visible: isNumeric(condition) ? item.type === 'number' : true,
              expanded: expands[index] && expands[index].includes(item.id_category)
            };
            if (!vars.visible && vars.has_content) return null;
            return (
              <li className={`list-item ${vars.expanded ? 'active' : ''}`} key={i}>
                <div
                  className="item-header"
                  onClick={(e) => item.children && this.toggleCollapse(item.id_category, index)}>
                  <div className="item-title">
                    <Checkbox
                      name={item.id_category}
                      className="custom-control-input"
                      checked={vars.checked}
                      indeterminate={vars.indeterminate.toString()}
                      onChange={(e) => this.onCategoryToggle(item.id_category, e.target.checked, index)}
                    />
                    {item.name[lang]}
                  </div>
                  {item.children && <i className="material-icons item-arrow">keyboard_arrow_down</i>}
                </div>
                {item.children && vars.expanded && (
                  <div className="item-body">{this.categoryList(item.children, index, condition)}</div>
                )}
              </li>
            );
          })}
        </ul>
      );
  };

  addQuery = () => {
    this.setState((state) => {
      return {
        queries: [
          ...state.queries.map((i) => ({ ...i, collapsed: true })),
          {
            query_condition: 'and',
            id_state: '',
            id_district: '',
            keyword: [],
            keywordStr: '',
            condition: 'contain',
            archive_year: '',
            keyword_source: '',
            tree: clone(state.categories),
            selectedCategories: []
          }
        ]
      };
    });
  };

  handleKeyPress = (e, index) => {
    const {
      target: { value },
      keyCode
    } = e;
    const val = value.trim();
    if (keyCode === 13 || keyCode === 9) {
      e.preventDefault();
      this.handleAddTag(val, index);
    } else if (keyCode === 8 && !val) {
      e.preventDefault();
      this.setState((state) => {
        state.queries[index].keywordStr = state.queries[index].keyword.length
          ? state.queries[index].keyword.last()
          : '';
        state.queries[index].keyword.splice(-1, 1);
        return {
          queries: state.queries
        };
      });
    }
  };

  handleAddTag = (value, index) => {
    const val = value.trim();
    if (val) {
      this.setState((state) => {
        state.queries[index].keyword.push(val);
        state.queries[index].keywordStr = '';
        return {
          queries: state.queries
        };
      });
    }
  };

  removeTagItem = (e, key, i) => {
    e.preventDefault();
    this.setState((state) => {
      const cloned = clone(state.queries);
      cloned[key].keyword.splice(i, 1);
      return {
        queries: cloned
      };
    });
  };

  handleSaveSearch = (e) => {
    this.setState({
      saveModal: !this.state.saveModal
    });
  };

  handleSubmitSave = (e, values) => {
    const {
      advanceSearch,
      advanceSearchSave,
      User: { language },
      history
    } = this.props;
    const queries = clone(this.state.queries);

    queries.forEach((query) => {
      delete query.tree;
      delete query.keywordStr;
      query.categories = query.selectedCategories.map((i) => i.slug);
    });

    const params = { params: { page: 1, limit: limits.advancedSearch, language, queries } };

    advanceSearchSave({
      name: values.name,
      params
    }).then(() => {
      this.setState({
        saveModal: false
      });
      advanceSearch(params);
      history.push({
        pathname: '/advanced-search/results'
      });
    });
  };

  handleSearch = () => {
    const {
      advanceSearch,
      User: { language },
      history
    } = this.props;
    const queries = clone(this.state.queries);

    queries.forEach((query) => {
      delete query.tree;
      delete query.keywordStr;
      query.categories = query.selectedCategories.map((i) => i.slug);
    });
    const params = { params: { page: 1, limit: limits.advancedSearch, language, queries } };
    advanceSearch(params);
    history.push('/advanced-search/results');
  };

  onMonumentToggle = (checked, index) => {
    this.setState((state) => {
      state.queries[index].keyword_source = checked ? 'monuments' : '';
      return {
        queries: state.queries
      };
    });
  };

  checkValidity = ({ condition, keywordStr }) => {
    const reg = new RegExp('^[0-9]+(.[0-9]+)?$');
    const isNumber = condition === 'more' || condition === 'less';
    return isNumber && keywordStr ? reg.test(keywordStr) : true;
  };

  toggleCollapseQuery = (index) => {
    this.setState((state) => {
      state.queries[index].collapsed = !state.queries[index].collapsed;
      return {
        queries: state.queries
      };
    });
  };

  deleteQuery = (index) => {
    this.setState((state) => {
      state.queries.splice(index, 1);
      return {
        queries: state.queries
      };
    });
  };

  render() {
    const { queries, saveModal } = this.state;
    const {
      User: {
        regions: { districts, states }
      }
    } = this.props;
    const isDiabled = !Boolean(queries.length && queries[0].keyword.length);
    return (
      <div className="app-content-wrapper">
        <div className="app-content content-fluid">
          <div className="app-content-header">
            <h1>{i18n.t('ADVANCED_SEARCH')}</h1>
          </div>
          <div className="app-content-inner">
            <div className="advanced-search-queries">
              {queries.map((item, index) => (
                <div className="query-item" key={index}>
                  <div className="query-collapse-toggle">
                    {queries.length > 1 && (
                      <Button color="link p-2 text-danger" onClick={(e) => this.deleteQuery(index)}>
                        <i className="material-icons">delete</i>
                      </Button>
                    )}
                    <Button color="link p-2" onClick={(e) => this.toggleCollapseQuery(index)}>
                      <i className="material-icons">
                        {item.collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
                      </i>
                    </Button>
                  </div>
                  {!item.collapsed ? (
                    <div className="query-item-inner">
                      <label className="form-group tag-input-wrapper">
                        <div className="control-label">{i18n.t('KEYWORDS')}</div>
                        <ul className="keywords">
                          {queries[index].keyword.map((kwd, i) => (
                            <li className="tag-item" key={i}>
                              {kwd}
                              <Button color="link" onClick={(e) => this.removeTagItem(e, index, i)}>
                                <i className="material-icons">close</i>
                              </Button>
                            </li>
                          ))}
                          <li className="tag-input">
                            <input
                              autoFocus
                              type="text"
                              name={`keywordStr_${index}`}
                              value={queries[index].keywordStr}
                              onChange={(e) => this.handleChangeInput(e, index, 'keywordStr')}
                              onKeyDown={(e) => this.checkValidity(item) && this.handleKeyPress(e, index)}
                              onBlur={(e) => this.checkValidity(item) && this.handleAddTag(e.target.value, index)}
                              className={`form-control ${!this.checkValidity(item) ? 'has-error' : ''}`}
                            />
                            <hr />
                          </li>
                        </ul>
                      </label>
                      <div className="query-condition">
                        <div>
                          {conditions.map(({ key, label }) => (
                            <label className="custom-control custom-radio" key={key}>
                              <input
                                value={key}
                                type="radio"
                                onChange={(e) => this.handleChangeCondition(e, index)}
                                checked={queries[index].condition === key}
                                name={`condition_${index}`}
                                className="custom-control-input"
                              />
                              <span className="custom-control-label">{i18n.t(label)}</span>
                            </label>
                          ))}
                        </div>
                        <div>
                          {index > 0 &&
                            queryCondition.map(({ key, label }) => (
                              <label className="custom-control custom-radio" key={key}>
                                <input
                                  type="radio"
                                  value={key}
                                  onChange={(e) => this.handleChangeInput(e, index, 'query_condition')}
                                  checked={queries[index].query_condition === key}
                                  name={`query_condition_${index}`}
                                  className="custom-control-input"
                                />
                                <span className="custom-control-label">{i18n.t(label)}</span>
                              </label>
                            ))}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="control-label mb-2">{i18n.t('CATEGORIES')}</div>
                          {this.categoryList(queries[index].tree, index, queries[index].condition)}
                          <ul className="panel-list">
                            <li className="list-item">
                              <div className="item-header">
                                <div className="item-title">
                                  <Checkbox
                                    name="keyword_source"
                                    className="custom-control-input"
                                    checked={item.keyword_source === 'monuments'}
                                    onChange={(e) => this.onMonumentToggle(e.target.checked, index)}
                                  />
                                  {i18n.t('monuments')}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col">
                              <div className="form-group">
                                <label className="control-label">{i18n.t('archive_year')}</label>
                                <select
                                  name={`archive_year_${index}`}
                                  onChange={(e) => this.handleChangeInput(e, index, 'archive_year')}
                                  value={queries[index].archive_year}
                                  className="form-control">
                                  <option value="">{i18n.t('CHOOSE')}</option>
                                  {archiveYears.map((item, i) => (
                                    <option key={i} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group">
                                <label className="control-label">{i18n.t('state')}</label>
                                <select
                                  name={`id_state_${index}`}
                                  onChange={(e) => this.handleChangeLocation(e, index, 'state')}
                                  value={queries[index].id_state}
                                  className="form-control">
                                  <option value="">{i18n.t('CHOOSE')}</option>
                                  {states.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col">
                              <div className="form-group">
                                <label className="control-label">{i18n.t('district')}</label>
                                <select
                                  name={`id_district_${index}`}
                                  onChange={(e) => this.handleChangeLocation(e, index, 'district')}
                                  value={queries[index].id_district}
                                  className="form-control">
                                  <option value="">{i18n.t('CHOOSE')}</option>
                                  {districts.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <QueryCollapse item={item} toggle={(e) => this.toggleCollapseQuery(index)} />
                  )}
                </div>
              ))}
            </div>

            <div className="form-actions">
              <Button color="secondary" size="sm" onClick={this.handleSearch} disabled={isDiabled}>
                {i18n.t('SEARCH')}
              </Button>
              <Button color="success" size="sm" onClick={this.handleSaveSearch} disabled={isDiabled}>
                {i18n.t('SAVE_AND_SEARCH')}
              </Button>
            </div>
          </div>
        </div>
        <div className="add-action">
          <Button color="success" onClick={this.addQuery}>
            <i className="material-icons">&#xE145;</i>
          </Button>
        </div>
        <Modal isOpen={saveModal} toggle={this.handleSaveSearch} size="sm">
          <ModalHeader toggle={this.handleSaveSearch}>Անվանել Որոնումը</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitSave}>
            <ModalBody>
              <AvGroup>
                <AvInput name="name" type="text" required />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.handleSaveSearch}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE_AND_SEARCH')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default AdvancedSearch;
