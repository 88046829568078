import { connect } from 'react-redux';
import GroupCategories from '../../../pages/Account/Groups/GroupCategories';
import { putGroupCategory } from '../../../actions/Groups';

const mapStateToProps = state => {
  return {
    User: state.User,
    Groups: state.Groups,
    Categories: state.Categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    putGroupCategory(body, id) {
      return dispatch(putGroupCategory(body, id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupCategories);
