import React, { Component } from "react"
import { Bar, Pie } from "react-chartjs-2"
import { ButtonGroup, Button } from "reactstrap"
import { colors } from "../constants/"
import i18n from "../locale/i18n"

const opt = {
	maintainAspectRatio: false,
	legend: {
		display: false
	}
}
const optBar = { ...opt, scales: { yAxes: [{ ticks: { beginAtZero: true } }] } }

class ChartsComponent extends Component {
	state = {
		chartsData: []
	}

	componentDidMount() {
		const { data, language } = this.props
		const chartsData = []

		data.villages[0].data.forEach((item, index) => {
			const cat = item.category
			const datasets = this.getValues(index)

			chartsData.push({
				type: "bar",
				labels: datasets.labels,
				legends: datasets.legends,
				title: cat.name[language],
				path: (cat.parent && cat.parent.path) || "",
				datasets: [
					{
						label: cat.name[language],
						backgroundColor: colors,
						data: datasets.data
					}
				]
			})
		})

		this.setState({ chartsData })
	}

	getValues = index => {
		const { data, language } = this.props
		const dataset = { labels: [], data: [], legends: [] }
		data.villages.forEach(village => {
			if (village.data[index].data[language]) {
				const content = +village.data[index].data[language].content
				// if (content !== 0) {
				dataset.data.push(content)
				dataset.labels.push(village.name[language])
				dataset.legends.push({
					name: village.name[language],
					year: village.archive_year,
					value: content
				})
				// }
			}
		})
		return dataset
	}

	toggleType = (type, i) => {
		this.setState(state => {
			state.chartsData[i].type = type
			return {
				chartsData: state.chartsData
			}
		})
	}

	removeChart = i => {
		this.setState(state => {
			state.chartsData.splice(i, 1)
			return {
				chartsData: state.chartsData
			}
		})
	}

	render() {
		const { data, language } = this.props
		const { chartsData } = this.state

		return (
			<div className="charts-wrapper">
				<div className="charts-header p-3 text-center">
					<h6>IRTDP Chart Report</h6>
					{data.villages.map(v => v.name[language]).join(", ")}
				</div>
				{chartsData.map(
					(chartItem, i) =>
						chartItem.labels.length ? (
							<div key={i} className="chart-item">
								<div className="chart-item-header">
									<h6 className="chart-item-title">
										<small className="mt-1 d-block text-secondary">
											<b>{chartItem.path}</b>
										</small>
										{chartItem.title}
									</h6>
									<div className="chart-actions">
										<Button
											className="text-danger"
											color="link"
											onClick={() => this.removeChart(i)}
											data-tooltip={i18n.t("DELETE")}>
											<i className="material-icons">delete</i>
										</Button>
										<ButtonGroup>
											<Button
												size="sm"
												active={chartItem.type === "bar"}
												onClick={() => this.toggleType("bar", i)}
												color="primary">
												Bar Chart
											</Button>
											<Button
												size="sm"
												active={chartItem.type === "pie"}
												onClick={() => this.toggleType("pie", i)}
												color="primary">
												Pie Chart
											</Button>
										</ButtonGroup>
									</div>
								</div>
								<div className="chart-wrapper">
									<div className="chart">
										{chartItem.type === "bar" ? (
											<Bar data={chartItem} height={150} options={optBar} />
										) : (
											<Pie data={chartItem} height={150} options={opt} />
										)}
									</div>
									<div className="chart-legend">
										<ul>
											{chartItem.legends.map((legend, index) => (
												<li className="legend" key={index}>
													<span style={{ backgroundColor: colors[index] }} /> {legend.name}
													{legend.year ? " (" + legend.year + ")" : ""} - <b>{legend.value}</b>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						) : null
				)}
			</div>
		)
	}
}

export default ChartsComponent
