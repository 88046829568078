/*eslint no-extend-native: 0, no-extra-label: 0*/
import i18n from '../locale/i18n';

export const updateFiles = (arr = []) => {
  return arr.map((img, index) => {
    const name = img.split('/images/')[1];
    return {
      src: img,
      name: name,
      caption: name
    };
  });
};

export const isEncoded = (str) => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const updateRegexp = (data) => {
  if (data) {
    if (isEncoded(data.regex)) {
      data.regex = window.atob(data.regex);
    }
    return data.regex.replace(/^\/|\/$/g, '');
  } else {
    return '';
  }
};

export const decodeTypes = (types) => {
  return types.map((t) => {
    if (isEncoded(t.regex)) {
      t.regex = window.atob(t.regex);
    }
    return t;
  });
};

export const isNumeric = (type) => {
  return type === 'more' || type === 'less';
};

export const shorten = (text) => {
  let ret = text;
  if (ret.length > 100) {
    ret = ret.substr(0, 100 - 3) + '…';
  }
  return ret;
};

export const isImage = (file) => {
  return /\.(jpe?g|png|gif)$/i.test(file);
};

export const isObject = (value) => {
  return value && typeof value === 'object' && value.constructor === Object;
};

export const isString = (value) => {
  return typeof value === 'string' || value instanceof String;
};

export const isArray = (value) => {
  return Array.isArray(value);
};

export const isJson = (value) => {
  try {
    JSON.parse(value);
  } catch (err) {
    return false;
  }
  return true;
};

export const nullToString = (data) => {
  return JSON.parse(JSON.stringify(data), (key, val) => (val === null ? '' : val));
};

export const baseUrl = (imgName) => {
  return `${window.location.origin}/${imgName}`;
};

export const clone = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};

export const groupBy = function(arr, prop1, prop2) {
  return arr.reduce(function(groups, item) {
    const val = prop2 ? item[prop1][prop2] : item[prop1];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});
};

export const getCount = (arr, key, val) => {
  let count = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === val) count++;
  }
  return count;
};

export const updateCategoriesBySlug = (tree, slugs = []) => {
  const loop1 = (arr) => {
    arr.forEach((item) => {
      if (slugs.includes(item.slug)) {
        item.is_checked = 2;
      } else {
        item.is_checked = 0;
      }
      if (item.children) loop1(item.children);
    });
    return arr;
  };

  const loop2 = (arr) => {
    arr.forEach((item) => {
      if (item.children) {
        loop2(item.children);
        if (!item.children.find((c) => c.is_checked !== 2)) {
          item.is_checked = 2;
        } else if (item.children.find((c) => c.is_checked)) {
          item.is_checked = 1;
        } else {
          item.is_checked = 0;
        }
      }
    });
    return arr;
  };

  return loop2(loop1(tree));
};

export function deepSearch(tree, newTree = []) {
  for (let i = 0; i < tree.length; i++) {
    if (Number(tree[i].has_content) === 1 && tree[i].is_checked === 2) {
      newTree.push(Object.assign({}, tree[i], { children: undefined }));
    }
    if (tree[i].children) {
      deepSearch(tree[i].children, newTree);
    }
  }

  return newTree;
}

function toggleParents(arr, id) {
  arr.forEach((item) => {
    if (id === item.id_category) {
      if (item.children && !item.children.find((c) => c.is_checked !== 2)) {
        item.is_checked = 2;
      } else if (item.children && item.children.find((c) => c.is_checked === 2)) {
        item.is_checked = 1;
      } else {
        item.is_checked = 0;
      }
    } else {
      if (item.children) {
        toggleParents(item.children, id);
        if (!item.children.find((c) => c.is_checked !== 2)) {
          item.is_checked = 2;
        } else if (item.children.find((c) => c.is_checked)) {
          item.is_checked = 1;
        } else {
          item.is_checked = 0;
        }
      }
    }
  });
}

function toggleChilds(arr, checked) {
  arr.forEach((i) => {
    i.is_checked = checked ? 2 : 0;
    if (i.children) {
      toggleChilds(i.children, checked);
    }
  });
}

export function toggleOne(arr, id, checked, data) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id_category === id) {
      arr[i].is_checked = checked ? 2 : 0;
      if (arr[i].children) toggleChilds(arr[i].children, checked);
      if (arr[i].id_parent) toggleParents(data, arr[i].id_parent);
      break;
    }
    if (arr[i].children) toggleOne(arr[i].children, id, checked, data);
  }
}

export function getCategorySlugs(tree, arr = []) {
  for (let i = 0; i < tree.length; i++) {
    if (Number(tree[i].has_content)) arr.push({ name: tree[i].name, slug: tree[i].slug });
    if (tree[i].children) getCategorySlugs(tree[i].children, arr);
  }

  return arr;
}

export const makeValidData = (obj) => {
  if (!obj.data) {
    obj.data = {
      am: { content: '', archive_year: '', sources: [] },
      en: { content: '', archive_year: '', sources: [] },
      ru: { content: '', archive_year: '', sources: [] }
    };
  } else {
    if (!obj.data.am) obj.data.am = { content: '', archive_year: '', sources: [] };
    if (!obj.data.en) obj.data.en = { content: '', archive_year: '', sources: [] };
    if (!obj.data.ru) obj.data.ru = { content: '', archive_year: '', sources: [] };
  }
  return obj;
};

export const modifyData = (arr) => {
  let data = [];
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    data.push(
      {
        language: 'am',
        archive_year: item.data.am.archive_year.trim() || new Date().getFullYear(),
        id_category: item.category.id_category,
        content: item.data.am.content.trim(),
        sources: item.data.am.sources ? item.data.am.sources.map((sc) => sc.id_source) : []
      },
      {
        language: 'ru',
        archive_year: item.data.ru.archive_year.trim() || new Date().getFullYear(),
        id_category: item.category.id_category,
        content: item.data.ru.content.trim(),
        sources: item.data.ru.sources ? item.data.ru.sources.map((sc) => sc.id_source) : []
      },
      {
        language: 'en',
        archive_year: item.data.en.archive_year.trim() || new Date().getFullYear(),
        id_category: item.category.id_category,
        content: item.data.en.content.trim(),
        sources: item.data.en.sources ? item.data.en.sources.map((sc) => sc.id_source) : []
      }
    );
  }
  return data;
};

function extractFileNameHeader(value) {
  let responseFilename = /filename="([^;]*);?"/i.exec(value);
  if (responseFilename === null) {
    responseFilename = /filename=([^;]*);?/i.exec(value);
  }
  if (responseFilename !== null && responseFilename.length > 1) {
    return responseFilename[1];
  }
  return null;
}

export const saveData = (() => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  return ({ data, headers }) => {
    let disposition = headers['content-disposition'] || headers['Content-Disposition'];
    let filename = 'Export.xlsx';
    if (typeof disposition !== 'undefined') {
      let responseFilename = extractFileNameHeader(disposition);
      if (responseFilename !== null) {
        filename = responseFilename;
      }
    }

    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  };
})();

export const mapContentString = (info) => {
  return `<div id="content">
            <div id="siteNotice"></div>
              <h2 id="firstHeading" class="mb-2">${info.name}</h2>
              <div id="bodyContent">
              <b>${i18n.t('state')}</b>:${info.state}
            </div>
          </div>`;
};

export const isEqual = function(value, other) {
  var type = Object.prototype.toString.call(value);
  if (type !== Object.prototype.toString.call(other)) return false;
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  var compare = function(item1, item2) {};
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      compare(value[i], other[i]);
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        compare(value[key], other[key]);
      }
    }
  }

  return true;
};

export const getMapData = (data) => {
  return data
    .filter((v) => {
      if (v.coordinates && isJson(v.coordinates)) {
        const obj = JSON.parse(v.coordinates);
        const regexp = /^([-+]?)([\d]{1,2})(((\.)(\d+)))$/;
        return regexp.test(obj.lat) && regexp.test(obj.lng) && !isString(obj.lat) && !isString(obj.lng);
      } else {
        return false;
      }
    })
    .map(({ coordinates, village, state, name }) => ({
      name: village ? village : name.am,
      state,
      location: JSON.parse(coordinates)
    }));
};

export const filterList = (key) => {
  let select = key;
  let tmp = {};
  return (id, list, excludeList = []) => {
    if (!list.length) return [];
    if (id && !tmp[id]) {
      tmp[id] = list.filter((i) => i[select] === id);
    }
    return excludeList.length ? tmp[id].filter((i) => !excludeList.includes(+i.id)) : tmp[id];
  };
};

export const findById = () => {
  let tmp = {};
  return (id, list) => {
    if (!list.length) return null;
    if (id && !tmp[id]) {
      tmp[id] = list.find((i) => i.id.toString() === id.toString());
    }
    return tmp[id];
  };
};
