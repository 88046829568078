import { connect } from "react-redux"
import ApprovalSimple from "../../pages/Approvals/ApprovalSimple"

const mapStateToProps = state => {
	return {
		languages: state.User.languages
	}
}

export default connect(mapStateToProps)(ApprovalSimple)
