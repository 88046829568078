import React, { Component } from 'react';
import { AvForm, AvInput } from 'availity-reactstrap-validation';
import { Button, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import i18n from '../locale/i18n';
import { clone } from '../services/helpers';

class QuickSearch extends Component {
  state = {
    tags: [],
    synced: false,
    categories: null,
    searchKyeword: '',
    searchPlaceholder: '',
    filterKey: '',
    plKey: '',
    searchDropdown: false
  };

  componentWillReceiveProps(nextProps) {
    this.initComponent(nextProps);
  }

  componentDidMount() {
    this.initComponent(this.props);
  }

  initComponent = props => {
    const {
      Categories: { slugs },
      Data: {
        params: { keywords }
      }
    } = props;
    const { categories } = this.state;
    const hasKeywords = keywords.length && keywords[0].keyword;

    if (!categories && slugs.length) {
      this.setState({
        categories: slugs
      });
    }

    if (hasKeywords && slugs.length) {
      let tagsFromProps = [];
      keywords.forEach(item => {
        const tagItem = { keyword: item.keyword };
        if (item.category) tagItem.category = slugs.find(i => i.slug === item.category);
        tagsFromProps.push(tagItem);
      });
      this.setState({
        tags: tagsFromProps
      });
    }
  };

  dataQuickSearch = () => {
    const { tags } = this.state;
    const {
      Data: {
        params: { id_state, limit, order_column, order_by, categories, hiddenColums }
      },
      dataQuickSearch
    } = this.props;
    const keywords = tags.length
      ? tags.map(i => {
          const item = { keyword: i.keyword };
          if (i.category) item.category = i.category.slug;
          return item;
        })
      : [{ keyword: '' }];
    dataQuickSearch({
      page: 1,
      keywords,
      id_state,
      limit,
      order_column,
      order_by,
      categories,
      hiddenColums
    });
  };

  selectSearchCat = cat => {
    this.setState(state => {
      const arr = state.searchKyeword.split(' in:');
      const keyword = { keyword: arr[0], category: cat };
      return {
        tags: [...state.tags, keyword],
        searchKyeword: '',
        searchPlaceholder: ''
      };
    });
  };

  handleSearchChange = e => {
    e.persist();
    const {
      User: { language },
      Categories: { slugs }
    } = this.props;
    const query = e.target.value.trim().toLowerCase();
    let placeholder = query;
    let filterKey = '';
    let item = null;

    if (query.indexOf(' in:') !== -1) {
      filterKey = query.split(' in:')[1];

      item = slugs.find(i => i.name[language].toLowerCase().indexOf(filterKey) === 0);
      placeholder += item ? item.name[language].toLowerCase().replace(filterKey, '') : '';
    }

    this.setState({
      searchKyeword: query,
      searchPlaceholder: placeholder,
      plKey: item && item.slug,
      categories: slugs.filter(i => i.name[language].toLowerCase().indexOf(filterKey) === 0),
      searchDropdown: Boolean(item)
    });
  };

  handleSearchClick = e => {
    const { categories, plKey, searchPlaceholder, searchKyeword } = this.state;
    if (searchKyeword) {
      const {
        Categories: { slugs }
      } = this.props;
      const arr = searchPlaceholder.split(' in:');

      const keyword = { keyword: arr[0] };
      const cat = categories.find(i => i.slug === plKey);
      if (arr[1]) keyword.category = cat;
      this.setState(state => {
        return {
          searchKyeword: '',
          searchPlaceholder: '',
          tags: [...state.tags, keyword],
          searchDropdown: false,
          categories: slugs
        };
      }, this.dataQuickSearch);
    }
  };

  handleKeyPress = e => {
    const {
      target: { value },
      keyCode
    } = e;
    const {
      Categories: { slugs },
      User: { language }
    } = this.props;
    const { categories, plKey, searchPlaceholder } = this.state;
    const val = value.trim();
    const arr = searchPlaceholder.split(' in:');

    if (arr.length) {
      if (keyCode === 13 || keyCode === 9) {
        e.stopPropagation();
        e.preventDefault();
        if (val) {
          const keyword = { keyword: arr[0] };
          const cat = categories.find(i => i.slug === plKey);
          if (arr[1]) keyword.category = cat;
          this.setState(state => {
            return {
              searchKyeword: '',
              searchPlaceholder: '',
              tags: [...state.tags, keyword],
              searchDropdown: false,
              categories: slugs
            };
          }, this.dataQuickSearch);
        }
      } else if (keyCode === 40) {
        e.preventDefault();
        let index = categories.findIndex(i => i.slug === plKey);
        if (index !== -1 && index <= categories.length) {
          index++;
          const key = categories[index] ? categories[index].slug : '';
          this.setState(state => ({
            searchPlaceholder: `${arr[0]} in:${categories[index].name[language].toLowerCase()}`,
            plKey: key
          }));
        }
      } else if (keyCode === 38) {
        e.preventDefault();
        let index = categories.findIndex(i => i.slug === plKey);
        if (index !== -1 && index > 0) {
          index--;
          const key = categories[index] ? categories[index].slug : '';
          this.setState(state => ({
            searchPlaceholder: `${arr[0]} in:${categories[index].name[language].toLowerCase()}`,
            plKey: key
          }));
        }
      } else if (keyCode === 8 && !val) {
        e.preventDefault();
        this.setState(state => {
          state.tags.splice(-1, 1);
          return {
            tags: state.tags
          };
        }, this.dataQuickSearch);
      }
    }
  };

  removeTagItem = (e, i) => {
    e.preventDefault();
    this.setState(
      state => {
        const tags = clone(state.tags);
        tags.splice(i, 1);
        return { tags };
      },
      () => {
        this.dataQuickSearch();
      }
    );
  };

  toggleSearchDropdown = () => this.setState(state => ({ searchDropdown: !state.searchDropdown }));

  render() {
    const {
      User: { language }
    } = this.props;
    const { categories, searchKyeword, searchPlaceholder, searchDropdown, plKey, tags, focused } = this.state;

    return (
      <AvForm className={focused || tags.length ? 'active' : ''}>
        <div className="form-group">
          <label className="tag-input-wrapper">
            <ul className="keywords">
              {tags.map((tag, i) => (
                <li className="tag-item" key={i}>
                  {tag.keyword} {tag.category && <span>in:{tag.category.name[language].toLowerCase()}</span>}
                  <button className="btn btn-link" onClick={e => this.removeTagItem(e, i)}>
                    <i className="material-icons">close</i>
                  </button>
                </li>
              ))}
              <li className="tag-input">
                <div className="tag-inputs">
                  <Input
                    className="search-placeholder"
                    autoComplete="off"
                    spellCheck="false"
                    name="placeholder"
                    value={searchPlaceholder}
                    disabled
                  />
                  <AvInput
                    name="keyword"
                    spellCheck="false"
                    autoComplete="off"
                    className="search-input"
                    value={searchKyeword}
                    onKeyDown={this.handleKeyPress}
                    onChange={this.handleSearchChange}
                    onFocus={() => this.setState({ focused: true })}
                    onBlur={() => this.setState({ focused: false })}
                    placeholder={i18n.t('SEARCH')}
                  />
                </div>
                <hr />
              </li>
            </ul>
          </label>

          <Dropdown className="search-dropdown" toggle={this.toggleSearchDropdown} isOpen={searchDropdown}>
            <DropdownToggle className="d-none" />
            <DropdownMenu>
              <div className="dropdown-menu-scroll">
                {categories &&
                  categories.map((cat, i) => (
                    <DropdownItem
                      color="link text-dark"
                      onClick={e => this.selectSearchCat(cat)}
                      active={plKey === cat.slug}
                      key={i}>
                      {cat.name[language].toLowerCase()}
                    </DropdownItem>
                  ))}
              </div>
            </DropdownMenu>
          </Dropdown>
        </div>
        <Button color="primary search-btn" onMouseDown={this.handleSearchClick} data-tooltip={i18n.t('SEARCH')}>
          <i className="material-icons">search</i>
        </Button>
      </AvForm>
    );
  }
}

export default QuickSearch;
