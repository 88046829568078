import { connect } from "react-redux";
import MyProfile from "../../pages/Account/MyProfile";
import { editProfile, deleteProfile } from "../../actions/User";
import { getGroups } from "../../actions/Groups";

const mapStateToProps = state => {
	return {
		User: state.User,
		Groups: state.Groups
	};
};

const mapDispatchToProps = dispatch => {
	return {
		editProfile(body, id) {
			return dispatch(editProfile(body, id));
		},
		deleteProfile(id) {
			dispatch(deleteProfile(id));
		},
		getGroups() {
			dispatch(getGroups());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
