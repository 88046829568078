import React, { Fragment } from 'react';
import ViewModeToggler from '../ViewModeToggler';
import Button from 'reactstrap/lib/Button';

const FormsTopActions = (props) => {
  return (
    <Fragment>
      <Button color="link" className="btn-back" onClick={() => props.history.goBack()}>
        <i className="material-icons">arrow_back</i>
      </Button>
      <ViewModeToggler {...props} />
    </Fragment>
  );
};

export default FormsTopActions;
