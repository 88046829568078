import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import i18n from '../locale/i18n';

class PlaceFields extends Component {
  state = {
    id_district: 0,
    id_village: 0,
    id_state: 0
  };

  componentDidMount() {
    const { data } = this.props;
    if (data) {
      this.setState({
        id_district: data.id_district || 0,
        id_village: data.id_village || 0,
        id_state: data.id_state || 0
      });
    }
  }

  filterPlaces = ({ target: { name, value } }) => {
    this.setState((state, props) => {
      let id_district = state.id_district;
      let id_village = state.id_village;

      if (name === 'id_state') {
        id_district = 0;
        id_village = 0;
      }
      return {
        id_district,
        id_village,
        [name]: value
      };
    });
  };

  render() {
    const {
      regions: { states, districts, places },
      showVillage
    } = this.props;
    const { id_state, id_district, id_village } = this.state;
    const districtsArr = id_state ? districts.filter((d) => d.id_state === +id_state) : [];
    const placesArr = id_district ? places.filter((d) => d.id_district === +id_district) : [];

    return (
      <div className="row">
        <div className="col">
          <AvGroup>
            <AvInput type="select" name="id_state" onChange={this.filterPlaces}>
              <option value="0" />
              {states.map((s) => (
                <option value={s.id} key={s.id}>
                  {s.name}
                </option>
              ))}
            </AvInput>
            <Label className="form-control-label">{i18n.t('state')}*</Label>
          </AvGroup>
        </div>
        <div className="col">
          <AvGroup>
            <AvInput type="select" name="id_district" value={id_district} disabled={!Boolean(id_state)}>
              <option value="0" />
              {districtsArr.map((d) => (
                <option value={d.id} key={d.id}>
                  {d.name}
                </option>
              ))}
            </AvInput>
            <Label className="form-control-label">{i18n.t('district')}*</Label>
          </AvGroup>
        </div>
        {showVillage && (
          <div className="col">
            <AvGroup>
              <AvInput type="select" name="id_village" value={id_village} disabled={!Boolean(id_state)}>
                <option value="0" />
                {placesArr.map((d) => (
                  <option value={d.id} key={d.id}>
                    {d.name}
                  </option>
                ))}
              </AvInput>
              <Label className="form-control-label">{i18n.t('village')}*</Label>
            </AvGroup>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    regions: state.User.regions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaceFields);
