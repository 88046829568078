import React, { Component } from 'react';

import Checkbox from '../../../components/Checkbox';
import i18n from '../../../locale/i18n';
import { Row, Col, Carousel, CarouselItem } from 'reactstrap';
import RegionsTree from '../../../components/RegionsTree';
import { filterList } from '../../../services/helpers';

class GroupRegions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      places: new Set(),
      activeState: null,
      activeDistrict: null,
      activeIndex: 0,
      prevTab: '',
      synced: false
    };
    this.filterDistricts = filterList('id_state');
    this.filterPlaces = filterList('id_district');
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.Group.places && !prevState.synced) {
      return { places: new Set(nextProps.Group.places), synced: true };
    }
    return null;
  }

  togglePlaces = (checked, ids) => {
    this.setState((prev) => {
      return {
        places: checked ? prev.places.insert(ids) : prev.places.remove(ids)
      };
    }, this.updateGroupPlaces);
  };

  updateGroupPlaces = () => {
    clearTimeout(this.tout);
    this.tout = setTimeout(() => {
      const { match, Regions } = this.props;
      const { places } = this.state;
      const states = new Set();
      const districts = new Set();
      const fullPlaces = Regions.places.filter((i) => places.has(i.id));

      fullPlaces.forEach((i) => {
        states.add(i.id_state);
        districts.add(i.id_district);
      });
      const data = {
        places: fullPlaces,
        states: Regions.states.filter((i) => states.has(i.id)),
        districts: Regions.districts.filter((i) => districts.has(i.id))
      };
      this.props.putGroupRegions(match.params.groupId, { visibility_json: { regions: data } }, [...places]);
    }, 2000);
  };

  setActiveState = (state) => {
    const { activeState } = this.state;
    if (!activeState || activeState.id !== state.id) {
      this.setState({ activeState: state, activeDistrict: null });
    }
    this.goToTab(1);
  };

  setActiveDistrict = (district) => {
    const { activeDistrict } = this.state;
    if (!activeDistrict || activeDistrict.id !== district.id) {
      this.setState({ activeDistrict: district });
    }
    this.goToTab(2);
  };

  isAllChecked = (arr) => {
    let a = true;
    for (let i = 0; i < arr.length; i++) {
      if (!this.state.places.has(arr[i].id)) {
        a = false;
        break;
      }
    }
    return a;
  };

  onExiting = () => {
    this.animating = true;
  };
  onExited = () => {
    this.animating = false;
  };

  goToTab = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { Regions } = this.props;
    const { activeIndex, places, activeState, activeDistrict } = this.state;
    const filteredDistricts = activeState ? this.filterDistricts(activeState.id, Regions.districts) : [];
    const filteredPlaces = activeDistrict ? this.filterPlaces(activeDistrict.id, Regions.places) : [];

    return (
      <div className="app-content-inner">
        <div className="content-container">
          <Row>
            <Col sm="8">
              <div className="wizard">
                <div className="wizard-header">
                  <button className={activeIndex === 0 ? 'active' : ''} onClick={() => this.goToTab(0)}>
                    {i18n.t('state')}
                  </button>
                  <button
                    className={activeIndex === 1 ? 'active' : ''}
                    disabled={!activeState}
                    onClick={() => this.goToTab(1)}>
                    {i18n.t('district')}
                  </button>
                  <button
                    className={activeIndex === 2 ? 'active' : ''}
                    disabled={!activeDistrict}
                    onClick={() => this.goToTab(2)}>
                    {i18n.t('place')}
                  </button>
                  {/* <button onClick={() => this.setState({ activeIndex: 'result' })}>Result</button> */}
                </div>
                <div className="wizard-body">
                  <Carousel activeIndex={activeIndex} next={() => {}} previous={() => {}}>
                    <CarouselItem tag="div" key="tab_state" onExiting={this.onExiting} onExited={this.onExited}>
                      <div className="panel-list-wrapper">
                        <h3>{i18n.t('state')}</h3>
                        <ul className="panel-list">
                          {Regions.states.map((s) => {
                            const filtered = s.places.filter((i) => places.has(+i));
                            const checked = filtered.length === s.places.length;
                            const indeterminate = !checked && filtered.length;
                            return (
                              <li className="list-item" key={'s_' + s.id}>
                                <div className="item-header" onClick={(e) => this.setActiveState(s)}>
                                  <div className="item-title">
                                    <Checkbox
                                      name={`state_${s.id}`}
                                      className="custom-control-input"
                                      checked={checked}
                                      indeterminate={indeterminate.toString()}
                                      onChange={(e) => this.togglePlaces(e.target.checked, s.places)}
                                    />
                                    {s.name}
                                  </div>
                                  <i className="material-icons item-arrow">keyboard_arrow_right</i>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </CarouselItem>
                    <CarouselItem tag="div" key="tab_district" onExiting={this.onExiting} onExited={this.onExited}>
                      <div className="panel-list-wrapper">
                        <h3>{activeState && activeState.name}&nbsp;</h3>
                        <ul className="panel-list">
                          {filteredDistricts.map((d) => {
                            const filtered = d.places.filter((i) => places.has(i));
                            const checked = filtered.length === d.places.length;
                            const indeterminate = !checked && filtered.length;
                            return (
                              <li className="list-item" key={'d_' + d.id}>
                                <div className="item-header" onClick={(e) => this.setActiveDistrict(d)}>
                                  <div className="item-title">
                                    <Checkbox
                                      name={`district_${d.id}`}
                                      className="custom-control-input"
                                      checked={checked}
                                      indeterminate={indeterminate.toString()}
                                      onChange={(e) => this.togglePlaces(e.target.checked, d.places)}
                                    />
                                    {d.name}
                                  </div>
                                  <i className="material-icons item-arrow">keyboard_arrow_right</i>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </CarouselItem>
                    <CarouselItem tag="div" key="tab_place" onExiting={this.onExiting} onExited={this.onExited}>
                      <div className="panel-list-wrapper">
                        <h3>{`${activeState && activeState.name} > ${activeDistrict && activeDistrict.name}`}</h3>
                        {filteredPlaces.length ? (
                          <div className="text-right mb-3">
                            <label className="btn btn-primary btn-sm">
                              <input
                                type="checkbox"
                                name={`place_all`}
                                className="d-none"
                                checked={this.isAllChecked(filteredPlaces)}
                                onChange={(e) => this.togglePlaces(e.target.checked, filteredPlaces.map((p) => p.id))}
                              />
                              {i18n.t('SELECT_ALL')}
                            </label>
                          </div>
                        ) : null}
                        <ul className="panel-list">
                          {filteredPlaces.map((p) => (
                            <li className="list-item" key={'p_' + p.id}>
                              <div className="item-header">
                                <div className="item-title">
                                  <Checkbox
                                    name={`place_${p.id}`}
                                    className="custom-control-input"
                                    checked={places.has(p.id)}
                                    onChange={(e) => this.togglePlaces(e.target.checked, p.id)}
                                    label={p.name}
                                  />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </CarouselItem>
                  </Carousel>
                </div>
              </div>
            </Col>
            <Col sm="4">
              {!!Regions.places.length && (
                <RegionsTree regions={Regions} places={places} togglePlaces={this.togglePlaces} />
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default GroupRegions;
