import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import i18n from "../../locale/i18n"
import Groups from "../../containers/Account/Groups/Groups"
import Group from "../../containers/Account/Groups/Group"

class GroupManagement extends Component {
	render() {
		return (
			<div>
				<div className="app-content-header">
					<h1>{i18n.t("GROUPS_MANAGEMENT")}</h1>
				</div>
				<Switch>
					<Route exact path="/account/groups" component={Groups} />
					<Route path="/account/groups/:groupId" component={Group} />
					<Redirect to="/account/groups" />
				</Switch>
			</div>
		)
	}
}

export default GroupManagement
