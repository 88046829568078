import { connect } from 'react-redux';
import Forms from '../pages/Forms';
import { getForms, getFormsSummary } from '../actions/Forms';

const mapStateToProps = state => {
  return {
    regions: state.User.regions,
    Forms: state.Forms
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getForms() {
      return dispatch(getForms());
    },
    getFormsSummary() {
      return dispatch(getFormsSummary());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forms);
