import React, { Fragment } from 'react';
import classnames from 'classnames';
import { Input, FormText } from 'reactstrap';
import { updateRegexp } from '../../services/helpers';

const FormField = ({ field, data, onChange }) => {
  return (
    <Fragment>
      <Input
        id={field.name}
        type={field.type}
        name={field.name}
        className={classnames({ 'is-invalid': !data.isValid }, 'simple')}
        value={data.content}
        onChange={onChange}
        pattern={updateRegexp(field)}>
        {field.options && (
          <Fragment>
            {field.options.map((opt) => (
              <option value={opt} key={opt}>
                {opt}
              </option>
            ))}
          </Fragment>
        )}
      </Input>
      <FormText>{field.hint}</FormText>
    </Fragment>
  );
};

export default FormField;
