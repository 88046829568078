import React, { Component, Fragment } from "react"
import { Table, Button } from "reactstrap"
import errorHandler from "../../services/errorHandler"
import Pagination from "../../components/Pagination"
import Loading from "../../components/Loading"
import Files from "../../components/Files"
import { limits } from "../../constants/"
import instance from "../../services/api"
import i18n from "../../locale/i18n"
import { isObject } from "../../services/helpers"

class ApprovalMerged extends Component {
	state = {
		loading: false,
		old: [],
		data: [],
		params: {
			limit: limits.approval,
			page: 1
		},
		total: 0
	}

	componentDidMount() {
		this.getApprovalMergedData()
	}

	getApprovalMergedData = (params = this.state.params) => {
		const { match: { params: { type, id } } } = this.props
		this.setState({ loading: true })
		instance
			.get(`/${type}/${id}/mergedApprovalList`, { params })
			.then(({ data }) => {
				this.setState({
					loading: false,
					params,
					...data
				})
			})
			.catch(err => {
				this.setState({ loading: false })
				errorHandler(err)
			})
	}

	handlePageChange = pageNumber => {
		const { params } = this.state
		this.getApprovalMergedData({ ...params, page: pageNumber.selected + 1 })
	}

	handleApprove = (data, action) => {
		const { match: { params: { type, id } } } = this.props
		this.setState({ loading: true })
		instance
			.post(`/${type}/${id}/${action}`, data)
			.then(({ data }) => {
				this.getApprovalMergedData()
			})
			.catch(err => {
				this.setState({ loading: false })
				errorHandler(err)
			})
	}

	handleDeclineBlock = arr => {
		this.handleApprove({ data: arr.map(i => i.data.id_data) }, "decline")
	}

	getOldValue = ({ filed, language }, old) => {
		if (filed === "sources") {
			return old.source.map((source, i) => (
				<b className="source-code" key={i}>
					<span data-tooltip={source.description}>{source.code}</span>
				</b>
			))
		} else if (filed === "villages") {
			return old.villages.map((v, i) => v.name[i18n.language]).join(", ")
		} else if (filed === "images") {
			return <Files data={old.images} listView={true} />
		} else if (filed === "type") {
			return old.type.name ? old.type.name[language] : "---"
		} else if (isObject(old[filed])) {
			return old[filed][language]
		} else {
			return old[filed]
		}
	}

	getNewValue = ({ filed, value }) => {
		if (filed === "sources") {
			return value.length
				? value.map((source, i) => (
						<b className="source-code" key={i}>
							<span data-tooltip={source.description}>{source.code}</span>
						</b>
					))
				: "---"
		} else if (filed === "villages") {
			return value.map((v, i) => v.name[i18n.language]).join(", ")
		} else if (filed === "images") {
			return value.length ? <Files data={value} listView={true} /> : "---"
		} else {
			return value
		}
	}

	render() {
		const { data, params, total, old, loading } = this.state
		const { languages, match: { params: { type } } } = this.props

		return (
			<div>
				{loading ? (
					<Loading />
				) : data && data.length ? (
					data.map((item, i) => (
						<div className="mb-5" key={i}>
							<div className="text-right mb-3">
								<Button color="danger" onClick={() => this.handleDeclineBlock(item.new)}>
									<i className="material-icons mr-2">done_all</i>
									{i18n.t("DECLINE_BLOCK")}
								</Button>
							</div>
							<Table className="table-simple table-approval">
								<thead>
									<tr>
										<th className="col-highlight" colSpan={2}>
											{i18n.t("OLD_DATA")}
										</th>
										<th className="w-50">{i18n.t("NEW_DATA")}</th>
										<th>{i18n.t("INSTRUCTION")}</th>
									</tr>
								</thead>
								{type === "village" ? (
									<tbody>
										<tr className="row-highlight">
											<td className="col-highlight">
												<strong data-tooltip={item.category.path} data-placement="bottom">
													{item.category.parent} <br />
													{item.category.name}
												</strong>
											</td>
											<td>
												<strong>
													{item.archive_year} <br />
													{languages.find(l => item.language === l.language).title}
												</strong>
											</td>
											<td colSpan={2} />
										</tr>
										{item.new.map((newItem, index) => (
											<Fragment key={index}>
												<tr>
													{index === 0 ? (
														<td className="col-highlight" colSpan={2} rowSpan={item.new.length * 2}>
															{item.old && (
																<Fragment>
																	{item.old.content}
																	{!!item.old.sources.length && (
																		<div>
																			{item.old.sources.map((source, j) => (
																				<b className="source-code" key={j}>
																					<span data-tooltip={source.description}>{source.code}</span>
																				</b>
																			))}
																		</div>
																	)}
																	{!!item.old.files && <Files data={item.old.files} listView={true} />}
																</Fragment>
															)}
														</td>
													) : null}
													<td colSpan={2} className="col-highlight-gray">
														<strong>{newItem.user.first_name + " " + newItem.user.last_name}</strong>
													</td>
												</tr>
												<tr>
													<td>
														{newItem.data.content}
														{!!newItem.data.sources.length && (
															<div>
																{newItem.data.sources.map((source, l) => (
																	<b className="source-code" key={l}>
																		<span data-tooltip={source.description}>{source.code}</span>
																	</b>
																))}
															</div>
														)}
														{!!newItem.data.files && <Files data={newItem.data.files} listView={true} />}
													</td>
													<td className="nowrap p-0">
														<Button
															color="link"
															onClick={() => this.handleApprove({ data: [newItem.data.id_data] }, "approve")}>
															{i18n.t("CONFIRM")}
														</Button>
													</td>
												</tr>
											</Fragment>
										))}
									</tbody>
								) : (
									<tbody>
										<tr className="row-highlight">
											<td>
												<strong>{i18n.t(item.new[0].data.filed)}</strong>
											</td>
											<td>
												{!!item.new[0].data.language && (
													<strong>{languages.find(l => item.new[0].data.language === l.language).title}</strong>
												)}
											</td>
											<td colSpan={2} />
										</tr>
										{item.new.map((newItem, index) => (
											<Fragment key={index}>
												<tr>
													{index === 0 ? (
														<td className="col-highlight" colSpan={2} rowSpan={item.new.length * 2}>
															{old[newItem.data.filed] || newItem.data.filed === "sources"
																? this.getOldValue(newItem.data, old)
																: "---"}
														</td>
													) : null}
													<td colSpan={2} className="col-highlight-gray">
														<strong>{newItem.user.first_name + " " + newItem.user.last_name}</strong>
													</td>
												</tr>
												<tr>
													<td>{newItem.data.value ? this.getNewValue(newItem.data) : "---"}</td>
													<td className="nowrap p-0">
														<Button
															color="link"
															onClick={() => this.handleApprove({ data: [newItem.data.id_data] }, "approve")}>
															{i18n.t("CONFIRM")}
														</Button>
													</td>
												</tr>
											</Fragment>
										))}
									</tbody>
								)}
							</Table>
						</div>
					))
				) : (
					<div className="empty-result">There is nothing to review yet, thank you</div>
				)}
				<Pagination total={total} limit={params.limit} page={params.page} onPageChange={this.handlePageChange} />
			</div>
		)
	}
}
export default ApprovalMerged
