import { connect } from "react-redux"
import ContentTypes from "../../pages/Account/ContentTypes"
import { getContentTypes, addContentType, editContentType, deleteContentType } from "../../actions/Categories"

const mapStateToProps = state => {
	return {
		User: state.User,
		Categories: state.Categories
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getContentTypes() {
			dispatch(getContentTypes())
		},
		addContentType(body) {
			return dispatch(addContentType(body))
		},
		editContentType(body, id) {
			return dispatch(editContentType(body, id))
		},
		deleteContentType(id) {
			return dispatch(deleteContentType(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentTypes)
