import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Button, Label } from 'reactstrap';
import { baseUrl } from '../services/helpers';
import Logos from '../components/Logos';
import i18n from '../locale/i18n';
import instance from '../services/api';
import Loading from '../components/Loading';

class Register extends Component {
  state = {
    loading: true,
    invalidCode: false,
    show_visibility: false,
    visibility: {}
  };
  componentDidMount() {
    const {
      match: {
        params: { code }
      }
    } = this.props;
    instance
      .get(`/invitation/validate`, { headers: { Invitation: code } })
      .then(({ data: { show_visibility, visibility } }) => {
        this.setState({ loading: false, visibility: show_visibility ? visibility : {}, show_visibility });
      })
      .catch(() => this.setState({ loading: false, invalidCode: true }));
  }

  handleValidSubmit = (event, values) => {
    const {
      registration,
      match: {
        params: { code }
      },
      history
    } = this.props;
    const { visibility, show_visibility } = this.state;
    const visibility_json = show_visibility
      ? {
          regions: {
            districts: visibility.regions.districts.filter((i) => +i.id === +values.id_district),
            places: visibility.regions.places.filter((i) => +i.id_district === +values.id_district),
            states: visibility.regions.states
          }
        }
      : visibility;
    delete values.confirm_password;
    delete values.id_district;

    registration({ ...values, visibility_json }, code).then(() => {
      history.push('/login');
    });
  };

  render() {
    const { loading, visibility, invalidCode, show_visibility } = this.state;
    return (
      <div className="auth-container">
        <div className="auth-content">
          <Logos />
          <div className="auth-form-wrapper">
            <div className="auth-form-header">
              <img src={baseUrl('img/shield.png')} width="50" alt="shield" />
              <div>
                <h5>Գրանցվել</h5>
                <h6>Տվյալների Բազա</h6>
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : invalidCode ? (
              <div className="text-danger text-center h3 py-5">
                Ներողություն
                <br />
                Հրավերն անվավեր է։
              </div>
            ) : (
              <AvForm onValidSubmit={this.handleValidSubmit}>
                {show_visibility && (
                  <AvGroup>
                    <AvInput type="select" name="id_district" required>
                      <option value="0" />
                      {visibility.regions.districts.map((d) => (
                        <option value={d.id} key={d.id}>
                          {d.name}
                        </option>
                      ))}
                    </AvInput>
                    <Label className="form-control-label">{i18n.t('district')}*</Label>
                  </AvGroup>
                )}

                <AvGroup>
                  <AvInput name="first_name" type="text" required />
                  <Label className="form-control-label">{i18n.t('first_name')}</Label>
                </AvGroup>
                <AvGroup>
                  <AvInput name="last_name" type="text" required />
                  <Label className="form-control-label">{i18n.t('last_name')}</Label>
                </AvGroup>
                <AvGroup>
                  <AvInput name="email" type="email" required />
                  <Label className="form-control-label">{i18n.t('email')}</Label>
                </AvGroup>
                <AvGroup>
                  <AvInput name="password" type="password" pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/" required />
                  <Label className="form-control-label">{i18n.t('password')}</Label>
                  <AvFeedback>
                    Գաղտնաբառը պետք է պարունակի` փոքրատառ, մեծատառ, թիվ և բաղկացած լինի 8 ավել սիմվոլից
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <AvInput
                    type="password"
                    name="confirm_password"
                    validate={{ match: { value: 'password' } }}
                    required
                  />
                  <Label className="form-control-label">{i18n.t('password_confirm')}</Label>
                </AvGroup>
                <AvGroup>
                  <AvInput name="phone" type="text" required />
                  <Label className="form-control-label">{i18n.t('phone')}</Label>
                </AvGroup>
                <div className="form-actions">
                  <Button color="primary" size="lg">
                    Գրանցվել
                  </Button>
                </div>
              </AvForm>
            )}
            <div className="text-center pt-3">
              <Link to={'/login'}>Մուտք գործել</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
