import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Label, Row, Col } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import i18n from '../locale/i18n';
import classnames from 'classnames';
import PlaceFields from '../components/PlaceFields';
import LanguagesNav from '../components/LanguagesNav';

class VillageAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: i18n.getFixedT(props.User.language),
      activeLang: props.User.language,
      errors: []
    };
  }

  handleChangeLang = lang => {
    if (this.state.activeLang !== lang) {
      this.setState({
        t: i18n.getFixedT(lang),
        activeLang: lang
      });
    }
  };

  handleSubmit = (e, values) => {
    const { addVillage, history } = this.props;
    const params = { ...values, code: +values.code };

    if (params.coordinates.lat && params.coordinates.lng) {
      params.coordinates = JSON.stringify(params.coordinates);
    } else {
      delete params.coordinates;
    }

    addVillage(params).then(data => {
      if (data && data.payload) {
        history.replace(`/village/${data.payload}/edit`);
      }
    });
  };

  handleInvalidSubmit = (event, errors) => {
    this.setState({ errors });
  };

  render() {
    const {
      User: { languages }
    } = this.props;
    const { t, activeLang, errors } = this.state;

    return (
      <div className="app-content-wrapper">
        <LanguagesNav
          errors={errors}
          languages={languages}
          activeLang={activeLang}
          handleChangeLang={this.handleChangeLang}
        />
        <div className="app-content content-fluid">
          <div className="app-content-header">
            <h1>{i18n.t('village')}</h1>
          </div>
          <div className="app-content-inner">
            <Link to={`/`} className="btn btn-link btn-back">
              <i className="material-icons">&#xE317;</i>
              {i18n.t('BACK')}
            </Link>
            <AvForm
              id="myForm"
              className="mt-4"
              ref={form => (this.form = form)}
              onValidSubmit={this.handleSubmit}
              onInvalidSubmit={this.handleInvalidSubmit}>
              <Row>
                <Col sm={{ size: 6, offset: 3 }}>
                  <Row>
                    <Col sm="8">
                      {languages.map((item, i) => (
                        <AvGroup
                          key={i}
                          className={classnames({
                            'd-none': item.language !== activeLang
                          })}>
                          <AvInput
                            name={`name.${item.language}`}
                            autoComplete="off"
                            required={item.language !== 'ru'}
                          />
                          <Label className="form-control-label">{t('name')}*</Label>
                        </AvGroup>
                      ))}
                    </Col>
                    <Col sm="4">
                      <AvGroup>
                        <AvInput
                          maxLength="10"
                          name={`code`}
                          type="number"
                          autoComplete="off"
                          validate={{ number: true }}
                        />
                        <Label className="form-control-label">{t('CODE')}*</Label>
                      </AvGroup>
                    </Col>
                  </Row>
                  <PlaceFields t={t} />
                  <Row>
                    <Col>
                      <AvGroup>
                        <AvInput
                          name={`coordinates.lat`}
                          type="number"
                          validate={{ number: true, pattern: { value: /^([-+]?)([\d]{1,2})(((\.)(\d+)))$/ } }}
                          autoComplete="off"
                        />
                        <Label className="form-control-label">{t('LATITUDE')}*</Label>
                      </AvGroup>
                    </Col>
                    <Col>
                      <AvGroup>
                        <AvInput
                          name={`coordinates.lng`}
                          type="number"
                          validate={{ number: true, pattern: { value: /^([-+]?)([\d]{1,2})(((\.)(\d+)))$/ } }}
                          autoComplete="off"
                        />
                        <Label className="form-control-label">{t('LONGITUDE')}*</Label>
                      </AvGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="form-actions">
                <Link className="btn btn-secondary btn-sm" to={`/`}>
                  {i18n.t('CANCEL')}
                </Link>
                <Button color={`primary`} size="sm">
                  {i18n.t('SAVE')}
                </Button>
              </div>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

export default VillageAdd;
