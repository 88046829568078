import { connect } from 'react-redux';
import FormsSummary from '../../pages/Forms/FormsSummary';
import { resetCurrent } from '../../actions/Forms';

const mapStateToProps = (state) => {
  return {
    Summary: state.Forms.summary
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetCurrent() {
      dispatch(resetCurrent());
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormsSummary);
