import instance from '../services/api';
import errorHandler from '../services/errorHandler';

export const getGroups = () => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .get(`/group`)
      .then(({ data }) => {
        dispatch({ type: 'GROUPS_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const selectGroup = id => {
  return dispatch => {
    dispatch({ type: 'GROUP_SELECT', payload: id });
  };
};

export const getGroup = id => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .get(`/group/${id}`)
      .then(({ data }) => {
        dispatch({ type: 'GROUP_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const addGroup = body => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .post(`/group`, body)
      .then(({ data }) => {
        dispatch({ type: 'GROUP_ADDED', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const editGroup = (body, id) => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .put(`/group/${id}`, body)
      .then(({ data }) => {
        dispatch({ type: 'GROUP_EDITED', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const deleteGroup = id => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .delete(`/group/${id}`)
      .then(({ data }) => {
        dispatch({ type: 'GROUP_DELETED', payload: id });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const clearGroup = () => {
  return dispatch => {
    dispatch({ type: 'GROUP_CLEAR' });
  };
};

export const getGroupCategories = id => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .get(`/group/${id}/categories`)
      .then(({ data }) => {
        dispatch({ type: 'GROUP_CAT_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const putGroupCategory = (body, id) => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });

    return instance
      .put(`/group/${id}/categories`, body)
      .then(({ data }) => {
        return dispatch({ type: 'GROUP_CAT_EDITED', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const deleteGroupCategory = id => {
  return dispatch => {
    dispatch({ type: 'GROUPS_REQ' });
    return instance
      .delete(`/group/${id}/categories`)
      .then(({ data }) => {
        dispatch({ type: 'GROUP_CAT_DELETED', payload: [] });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};

export const getGroupRegions = id => {
  return dispatch => {
    return instance
      .get(`/group/${id}/region`)
      .then(({ data }) => {
        const places = data.regions ? data.regions.places.map(i => i.id) : [];
        dispatch({ type: 'GROUP_PLACES_RES', payload: places });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};
export const putGroupRegions = (id, body, places) => {
  return dispatch => {
    return instance
      .put(`/group/${id}/region`, body)
      .then(() => {
        dispatch({ type: 'GROUP_PLACES_RES', payload: places });
      })
      .catch(err => {
        dispatch({ type: 'GROUPS_REQ_ERR' });
        return errorHandler(err);
      });
  };
};
