import { connect } from "react-redux"
import Library from "../../pages/Libraries/Library"
import { getLibrary, searchLibrary, addLibrary, editLibrary, deleteLibrary } from "../../actions/Library"

const mapStateToProps = state => {
	return {
		User: state.User,
		Library: state.Library
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getLibrary(params) {
			dispatch(getLibrary(params))
		},
		editLibrary(body, id, lang) {
			return dispatch(editLibrary(body, id, lang))
		},
		addLibrary(body, lang) {
			return dispatch(addLibrary(body, lang))
		},
		deleteLibrary(id, lang) {
			return dispatch(deleteLibrary(id, lang))
		},
		searchLibrary(params) {
			dispatch(searchLibrary(params))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Library)
