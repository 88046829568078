import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"
import i18n from "../locale/i18n"
import QueryCollapse from "./QueryCollapse"

class AdvancedSearchProps extends Component {
  render() {
    const { queries, onSaveHandler } = this.props
    return (
      <div>
        <div className="advanced-search-queries">
          {queries.map((item, index) => (
            <Fragment key={index}>
              {index > 0 && <div className="px-4 py-2">{i18n.t(item.query_condition)}</div>}
              <div className="query-item mb-0">
                <QueryCollapse item={item} />
              </div>
            </Fragment>
          ))}
        </div>
        <div className="text-right mt-3">
          <Link className="btn btn-link" to="/advanced-search/edit">
            {i18n.t("EDIT")}
          </Link>
          <Button color="link" onClick={onSaveHandler}>
            {i18n.t("SAVE")}
          </Button>
        </div>
      </div>
    )
  }
}

export default AdvancedSearchProps
