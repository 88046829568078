import { connect } from "react-redux";
import UserProfile from "../../pages/Account/UserProfile";
import { getUser, selectUser, editUser, deleteUser } from "../../actions/Users";
import { getGroups } from "../../actions/Groups";
import { getInvitations } from "../../actions/Invitations";

const mapStateToProps = state => {
	return {
		Users: state.Users,
		Groups: state.Groups,
		Invitations: state.Invitations
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		selectUser(id) {
			return dispatch(selectUser(id));
		},
		getUser(id) {
			return dispatch(getUser(id));
		},
		editUser(body, id) {
			return dispatch(editUser(body, id));
		},
		deleteUser(id) {
			return dispatch(deleteUser(id, props));
		},
		getGroups() {
			dispatch(getGroups());
		},
		getInvitations() {
			dispatch(getInvitations());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
