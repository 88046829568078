import { limits } from "../constants/"

const initialState = {
  data: null,
  params: {
    year: null,
    date: null,
    page: 1,
    limit: limits.activityLog
  },
  total: 0,
  years: [],
  loading: false
}

const ActivityLog = (state = initialState, action) => {
  switch (action.type) {
    case "ACTIVITYLOGS_REQ":
      return {
        ...state,
        params: { ...state.params, ...(action.payload || {}) },
        loading: true
      }
    case "ACTIVITYLOGS_RES":
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case "ACTIVITYLOG_RES":
      return {
        ...state,
        current: action.payload,
        loading: false
      }
    case "ACTIVITYLOG_DELETED":
      return {
        ...state,
        data: state.data.filter(i => i.id_action !== action.payload),
        loading: false
      }
    case "USER_LOGOUT":
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default ActivityLog
