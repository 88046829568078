import i18next from 'i18next';
import am from './am/global.json';

const defaultLang = localStorage.locale || 'am';

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: defaultLang,
  resources: {
    am: {
      translation: am
    }
  }
});

export default i18next;
