import { combineReducers } from 'redux';
import User from './User';
import Users from './Users';
import Groups from './Groups';
import Categories from './Categories';
import Invitations from './Invitations';
import States from './States';

import Districts from './Districts';
import Library from './Library';
// import Monuments from './Monuments';
import Sources from './Sources';
import Data from './Data';
import Villages from './Villages';
import ActivityLog from './ActivityLog';
import Approvals from './Approvals';
import Forms from './Forms';

export default combineReducers({
  User,
  Users,
  Groups,
  Categories,
  Invitations,
  States,
  Districts,
  Library,
  // Monuments,
  Sources,
  Data,
  Villages,
  ActivityLog,
  Approvals,
  Forms
});
