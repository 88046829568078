import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import FormsSidebar from '../components/Forms/FormsSidebar';
import FormsSummary from '../containers/Forms/FormsSummary';
import FormRejected from '../containers/Forms/FormRejected';
import FormReview from '../containers/Forms/FormReview';
import FormPage from '../containers/Forms/FormPage';

class Forms extends Component {
  state = {
    activeForm: null,
    useDraft: true
  };

  componentDidMount() {
    const { Forms } = this.props;
    if (!Forms.data) {
      this.props.getForms();
      this.props.getFormsSummary();
    }
  }

  showSummary() {
    const type = this.props.location.pathname.split('/')[2];
    const types = ['submitted', 'drafts', 'sentback', 'approved'];
    return types.includes(type) ? type : null;
  }

  render() {
    const {
      history,
      Forms: { data, summary }
    } = this.props;
    const showSummary = summary && this.showSummary();

    return (
      <div className="app-content-wrapper">
        <FormsSidebar data={data} summary={summary} showSummary={showSummary} history={history} />
        <Switch>
          <Route exact path="/forms" component={FormsSummary} />
          <Route exact path="/forms/:id_form" component={FormPage} />
          <Route exact path="/forms/drafts/:id_form/:id_place" component={FormPage} />
          <Route exact path="/forms/submitted/:id_form/:id_place" component={FormReview} />
          <Route exact path="/forms/sentback/:id_form/:id_place" component={FormRejected} />
          <Route exact path="/forms/approved/:id_form/:id_place" component={FormReview} />
        </Switch>
      </div>
    );
  }
}

export default Forms;
