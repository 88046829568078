import React, { Component } from "react"
import { Nav, NavItem } from "reactstrap"
import { NavLink } from "react-router-dom"
import i18n from "../locale/i18n"

class AccountSidebar extends Component {
	isActiveRoute = (match, location) => {
		if (!match) {
			return false
		}
		return match.path === location.pathname
	}

	isActiveLocation = name => {
		const path = this.props.location.pathname
		return path.indexOf(name) === 0
	}

	render() {
		return (
			<div className="app-sidebar">
				<div className="sidebar-nav">
					<Nav vertical>
						<NavItem>
							<NavLink className="nav-item-header" to={"/libraries/library"}>
								<i className="nav-item-icon material-icons">sort_by_alpha</i>
								<span className="nav-item-title">{i18n.t("library")}</span>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className="nav-item-header" to={"/libraries/sources"}>
								<i className="nav-item-icon material-icons">content_paste</i>
								<span className="nav-item-title">{i18n.t("sources")}</span>
							</NavLink>
						</NavItem>
					</Nav>
				</div>
			</div>
		)
	}
}

export default AccountSidebar
