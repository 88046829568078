import React, { Component } from 'react';
import { baseUrl } from '../services/helpers';

class Logos extends Component {
  render() {
    return (
      <div className="logos content-box">
        <img src={baseUrl('img/logos/am.png')} height={94} alt="logo" />
        <h1>ՀՀ ՏԿԵՆ Տվյալների շտեմարան</h1>
      </div>
    );
  }
}

export default Logos;
