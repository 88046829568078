/*eslint no-extend-native: 0, no-extra-label: 0*/
Array.prototype.unique = function() {
  return Array.from(new Set(this));
};

Array.prototype.last = function() {
  return this[this.length - 1];
};

// Remove item and return set
Set.prototype.remove = function(i) {
  if (Array.isArray(i)) {
    i.forEach((j) => {
      this.delete(j);
    });
  } else {
    this.delete(i);
  }
  return this;
};

// Toggle item and return set
Set.prototype.toggle = function(i) {
  if (this.has(i)) {
    this.delete(i);
  } else {
    this.add(i);
  }
  return this;
};

// Insert item and return set
Set.prototype.insert = function(i) {
  if (Array.isArray(i)) {
    i.forEach((j) => {
      this.add(j);
    });
  } else {
    this.add(i);
  }
  return this;
};

Array.prototype.equals = function(array) {
  if (!array) return false;

  if (this.length !== array.length) return false;

  for (let i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] !== array[i]) {
      return false;
    }
  }
  return true;
};

Object.defineProperty(Array.prototype, 'equals', { enumerable: false });
