import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Loading from '../../../components/Loading';
import Checkbox from '../../../components/Checkbox';
import { clone, toggleOne } from '../../../services/helpers';
import i18n from '../../../locale/i18n';

class GroupCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tree: [],
      synced: false,
      changed: false,
      stateLoading: true,
      expanded: []
    };
  }

  componentDidMount() {
    this.setStateFromProps(this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    this.setStateFromProps(this.props);
  }

  setStateFromProps = props => {
    const {
      Groups: { currentGroup, loading }
    } = props;
    const { synced } = this.state;

    if (currentGroup && currentGroup.categories && !loading && !synced) {
      this.setState({
        stateLoading: false,
        tree: clone(currentGroup.categories),
        synced: true
      });
    }
  };

  treeToArray = (tree, arr) => {
    tree.forEach(item => {
      if (item.is_checked === 2) {
        arr.push(item.id_category);
      }
      if (item.children) {
        this.treeToArray(item.children, arr);
      }
    });
    return arr;
  };

  toggleCategory = (checked, cat) => {
    const tree = clone(this.state.tree);
    toggleOne(tree, cat.id_category, checked, tree);
    this.setState({ tree, changed: true });
  };

  handleSaveChanges = () => {
    const ids = this.treeToArray(this.state.tree, []);
    this.updateGroupCategories(ids);
  };

  handleCancelChanges = () => {
    const {
      Groups: { currentGroup }
    } = this.props;
    this.setState({
      changed: false,
      tree: clone(currentGroup.categories)
    });
  };

  updateGroupCategories = ids => {
    const {
      Groups: {
        currentGroup: { id_group, categories }
      }
    } = this.props;
    this.setState({ stateLoading: true });
    this.props
      .putGroupCategory({ categories: ids }, id_group)
      .then(() => {
        this.setState({ changed: false, stateLoading: false });
      })
      .catch(() => {
        this.setState({
          stateLoading: false,
          tree: clone(categories)
        });
      });
  };

  toggleCollapse = catId => {
    this.setState(prev => {
      return {
        expanded: !prev.expanded.includes(catId) ? [...prev.expanded, catId] : prev.expanded.filter(id => id !== catId)
      };
    });
  };

  categoryList = arr => {
    const {
      User: { language }
    } = this.props;

    if (Boolean(arr))
      return (
        <ul className="panel-list">
          {arr.map((item, index) => {
            const vars = {
              checked: item.is_checked === 2,
              indeterminate: item.is_checked === 1,
              isExpanded: this.state.expanded.includes(item.id_category)
            };
            return (
              <li className={`list-item ${vars.isExpanded ? ' active' : ''}`} key={index}>
                <div className="item-header" onClick={e => item.children && this.toggleCollapse(item.id_category)}>
                  <div className="item-title">
                    <Checkbox
                      name={item.id_category}
                      className="custom-control-input"
                      checked={vars.checked}
                      indeterminate={vars.indeterminate.toString()}
                      onChange={e => this.toggleCategory(e.target.checked, item)}
                    />
                    {!item.id_parent ? <i className="panel-icon material-icons">{item.icon || 'select_all'}</i> : null}
                    {item.name[language]}
                  </div>
                  {item.children && <i className="material-icons item-arrow">keyboard_arrow_down</i>}
                </div>
                {vars.isExpanded && item.children && (
                  <div className="item-body">{this.categoryList(item.children)}</div>
                )}
              </li>
            );
          })}
        </ul>
      );
  };

  groupCategoryList = arr => {
    const {
      User: { language }
    } = this.props;
    if (Boolean(arr))
      return (
        <ul className="panel-list list-tree">
          {arr.map((item, index) => {
            if (item.is_checked) {
              return (
                <li className="list-item" key={item.id_category}>
                  <div className="item-header" onClick={e => item.children && this.toggleCollapse(item.id_category)}>
                    <div className="item-title">
                      {!item.id_parent && <i className="panel-icon material-icons">{item.icon || 'select_all'}</i>}
                      {item.name[language]}
                    </div>
                  </div>
                  {item.children && <div className="item-body visible">{this.groupCategoryList(item.children)}</div>}
                </li>
              );
            } else {
              return null;
            }
          })}
        </ul>
      );
  };

  render() {
    const { tree, stateLoading, changed } = this.state;
    const {
      Groups: { currentGroup, loading }
    } = this.props;

    return (
      <div>
        <div className="app-content-inner">
          <div className="content-container">
            {stateLoading ? (
              <Loading />
            ) : (
              <div>
                {changed && (
                  <div className="form-actions mb-3">
                    <Button color="secondary" onClick={this.handleCancelChanges}>
                      {i18n.t('CANCEL')}
                    </Button>
                    <Button color="primary" onClick={this.handleSaveChanges}>
                      {i18n.t('SAVE')}
                    </Button>
                  </div>
                )}
                <Row>
                  <Col md={4}>
                    <h3>{i18n.t('ALL_CATEGORIES')}</h3>
                    <div className="content-box category-nav">{this.categoryList(tree)}</div>
                  </Col>
                  <Col md={8}>
                    <h3>{i18n.t('INCLUDED_CATEGORIES')}</h3>
                    <div className="content-box">
                      {currentGroup ? (
                        <div>{this.groupCategoryList(tree)}</div>
                      ) : loading ? (
                        <Loading />
                      ) : (
                        <div>
                          <h2 className="text-secondary text-center p-5">Not Found</h2>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default GroupCategories;
