import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import Loading from '../../components/Loading';
import { icons } from '../../constants/icons';
import i18n from '../../locale/i18n';

class CategoryManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetId: null,
      targetCat: null,
      addModal: false,
      stateLoading: false,
      expanded: []
    };
  }

  componentDidMount() {
    const { Categories, getContentTypes } = this.props;
    if (!Categories.contentTypes.length) getContentTypes();
  }

  addNewCategory = (e, id) => {
    e.preventDefault();
    this.setState({
      targetId: id,
      targetCat: null,
      addModal: true
    });
  };

  toggleEdit = (e, item) => {
    e.stopPropagation();
    this.setState({
      targetCat: item,
      targetId: item.id_category,
      addModal: true
    });
  };

  toggleAddModal = () => {
    this.setState({
      addModal: !this.state.addModal
    });
  };

  openAddCatgeory = () => {
    this.setState({
      addModal: true,
      targetId: null,
      targetCat: null
    });
  };

  handleDelete = () => {
    const { targetCat, targetId } = this.state;
    const {
      User: { language },
      Data: { params },
      updateCategoriesBySlug
    } = this.props;
    const confirmation = window.confirm(`${i18n.t('DELETE_CATEGORY_QUESTION')} "${targetCat.name[language]}"?`);
    this.setState({ addModal: false, stateLoading: true });
    if (confirmation) {
      this.props
        .deleteCategory(targetId)
        .then(() => {
          this.setState({ targetId: null, stateLoading: false });
          toast.success(i18n.t('SUCCESS_DELETE_CATEGORY'));
          updateCategoriesBySlug(params.categories);
        })
        .catch(() => {
          this.setState({ stateLoading: false });
        });
    }
  };

  handleValidSubmit = (ev, values) => {
    this.setState({ addModal: false, stateLoading: true });
    const {
      Data: { params },
      updateCategoriesBySlug
    } = this.props;
    const { targetCat, targetId } = this.state;
    if (!targetCat) {
      values.id_parent = targetId || null;
      this.props
        .addCategory(values)
        .then(() => {
          updateCategoriesBySlug(params.categories);
          toast.success(i18n.t('SUCCESS_ADD_CATEGORY'));
          this.setState({ targetId: null, stateLoading: false });
        })
        .catch(() => {
          this.setState({ stateLoading: false });
        });
    } else {
      this.props
        .editCategory(values, targetId)
        .then(() => {
          toast.success(i18n.t('SUCCESS_EDIT_CATEGORY'));
          updateCategoriesBySlug(params.categories);
          this.setState({ targetId: null, targetCat: null, stateLoading: false });
        })
        .catch(() => {
          this.setState({ stateLoading: false });
        });
    }
  };

  toggleCollapse = (e, catId) => {
    e.preventDefault();
    this.setState(prev => {
      return {
        expanded: !prev.expanded.includes(catId) ? [...prev.expanded, catId] : prev.expanded.filter(id => id !== catId)
      };
    });
  };

  categoryList = (arr, parentId) => {
    if (parentId && !this.state.expanded.includes(parentId)) return null;
    const {
      User: { language }
    } = this.props;
    const childrens = Boolean(arr) ? arr : [];

    return (
      <ul className="panel-list">
        {Boolean(parentId) && (
          <li className="list-item">
            <button className="item-header no-style" onClick={e => this.addNewCategory(e, parentId)}>
              <div className="item-title">
                <i className="material-icons">add</i>
                {i18n.t('ADD_NEW_SUBCATEGORY')}
              </div>
            </button>
          </li>
        )}
        {childrens.map((item, index) => (
          <li className={`list-item ${this.state.expanded.includes(item.id_category) ? ' active' : ''}`} key={index}>
            <div className="item-header" onClick={e => this.toggleCollapse(e, item.id_category)}>
              <div className="item-title">
                {!item.id_parent && <i className="panel-icon material-icons">{item.icon || 'select_all'}</i>}
                {item.name[language]}
              </div>
              <div className="item-actions">
                <Button color="link" onClick={e => this.toggleEdit(e, item)} data-tooltip={i18n.t('EDIT')}>
                  <i className="material-icons">mode_edit</i>
                </Button>
                <i className="material-icons item-arrow">keyboard_arrow_down</i>
              </div>
            </div>
            <div className="item-body">{this.categoryList(item.children, item.id_category)}</div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      Categories: { data, loading, contentTypes },
      User: { language, languages }
    } = this.props;
    const { targetCat, targetId, stateLoading } = this.state;

    return (
      <div>
        <div className="app-content-header">
          <h1>{i18n.t('CATEGORY_MANAGEMENT')}</h1>
        </div>
        {loading && !data.length ? (
          <Loading />
        ) : (
          <div className="app-content-inner">
            <div className="content-container content-sm">
              <h3>{i18n.t('ALL_CATEGORIES')}</h3>
              {this.categoryList(data)}
            </div>
          </div>
        )}

        <Modal isOpen={this.state.addModal} toggle={this.toggleAddModal}>
          <ModalHeader toggle={this.toggleAddModal}>
            {targetCat ? i18n.t('EDIT_CATEGORY') : targetId ? i18n.t('ADD_CATEGORY') : i18n.t('ADD_MAIN_CATEGORY')}
          </ModalHeader>
          <AvForm onValidSubmit={this.handleValidSubmit} model={targetCat}>
            <ModalBody>
              {languages.map(item => (
                <AvGroup key={item.language}>
                  <AvInput name={`name.${item.language}`} type="text" required />
                  <Label className="form-control-label">
                    {i18n.t('name')} <b>({item.title})</b>
                  </Label>
                </AvGroup>
              ))}

              {(targetCat && !targetCat.id_parent) || !targetId ? (
                <div className="category-icons">
                  <h5 className="mb-2">{i18n.t('SELECT_ICON')}</h5>
                  <AvRadioGroup name="icon" required>
                    <ul>
                      {icons.map(icon => (
                        <li key={icon}>
                          <AvRadio value={icon} label={<i className="material-icons">{icon}</i>} />
                        </li>
                      ))}
                    </ul>
                  </AvRadioGroup>
                </div>
              ) : (
                <AvGroup>
                  <AvInput
                    type="select"
                    name="id_content_type"
                    value={targetCat ? targetCat.id_content_type : '1'}
                    required>
                    <option value="" disabled />
                    {contentTypes.map((item, i) => (
                      <option key={i} value={item.id_content_type}>
                        {item.type} - {item[`name_${language}`]}
                      </option>
                    ))}
                  </AvInput>
                  <Label className="form-control-label">Content Type</Label>
                </AvGroup>
              )}
            </ModalBody>

            <ModalFooter>
              {targetCat && (
                <Button color="link" className="text-danger" onClick={this.handleDelete}>
                  {i18n.t('DELETE')}
                </Button>
              )}
              <Button color="link" className="text-dark" onClick={this.toggleAddModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{targetCat ? i18n.t('SAVE') : i18n.t('CREATE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <div className="add-action">
          <Button color="success" onClick={this.openAddCatgeory}>
            <i className="material-icons">&#xE145;</i>
          </Button>
        </div>
        {stateLoading && <Loading overlay={true} />}
      </div>
    );
  }
}

export default CategoryManagement;
