import React, { Component } from "react"
import { Link } from "react-router-dom"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import { Button, Label } from "reactstrap"
import { baseUrl } from "../services/helpers"
import Logos from "../components/Logos"

class ForgotPassword extends Component {
	handleValidSubmit = (event, values) => {
		values.url = `${window.location.origin}/reset-password`
		this.props.forgotPassword(values)
	}

	render() {
		return (
			<div className="auth-container">
				<div className="auth-content">
					<Logos />
					<div className="auth-form-wrapper">
						<div className="auth-form-header">
							<img src={baseUrl("img/shield.png")} width="50" alt="shield" />
							<div>
								<h5>Գաղտանաբառի վերականգնում</h5>
							</div>
						</div>
						<AvForm onValidSubmit={this.handleValidSubmit}>
							<AvGroup>
								<AvInput name="email" type="email" errorMessage="Տեքսը բացակայում է կամ ունի սխալ" required />
								<Label className="form-control-label">Էլ. հասցե</Label>
							</AvGroup>
							<Link to="/login" className="form-link">
								Մուտք
							</Link>
							<div className="form-actions">
								<Button color="primary" size="lg">
									Ուղարկել
								</Button>
							</div>
						</AvForm>
					</div>
				</div>
			</div>
		)
	}
}

export default ForgotPassword
