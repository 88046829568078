import React, { Component } from "react"
import { Nav, NavItem } from "reactstrap"
import { NavLink } from "react-router-dom"
import i18n from "../locale/i18n"

class ContentNav extends Component {
	render() {
		const { navItems } = this.props
		return (
			<div className="text-center">
				<Nav className="nav-tab nav-inline">
					{navItems.map((item, i) => (
						<NavItem key={i}>
							<NavLink className="nav-link" to={item.url}>
								{i18n.t(item.label)}
							</NavLink>
						</NavItem>
					))}
				</Nav>
			</div>
		)
	}
}

export default ContentNav
