import { connect } from 'react-redux';
import FormRejected from '../../pages/Forms/FormRejected';
import { getForm, getFormsSummary } from '../../actions/Forms';

const mapStateToProps = (state) => {
  return {
    Forms: state.Forms,
    Regions: state.User.regions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForm(id) {
      return dispatch(getForm(id));
    },
    updateForms() {
      dispatch(getFormsSummary());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormRejected);
