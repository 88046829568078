import React from 'react';
import { TabPane, Row, Col } from 'reactstrap';
import SummaryListItem from './SummaryListItem';
import { chunk } from 'lodash';

const SummaryList = ({ tab, summary, isTabContent }) => {
  if (!summary) return;
  const toArray = (data) => {
    const arr = Array.isArray(data) ? data : Object.values(data);
    return isTabContent ? chunk(arr, Math.ceil(arr.length / 2)) : arr;
  };

  return isTabContent ? (
    <TabPane tabId={tab.key}>
      <div className="summary-list summary-tab-content">
        <Row>
          {toArray(summary[tab.key].data).map((group, index) => (
            <Col key={index} sm="6">
              {group.map((item) => (
                <SummaryListItem item={item} tab={tab} key={item.place.place_id} />
              ))}
            </Col>
          ))}
        </Row>
      </div>
    </TabPane>
  ) : (
    <div className="forms-subnav">
      {toArray(summary[tab.key].data).map((item) => (
        <SummaryListItem item={item} tab={tab} key={item.place.place_id} />
      ))}
    </div>
  );
};

export default SummaryList;
