import { makeValidData, isJson } from "../services/helpers"

const initialState = {
  data: [],
  bookmarks: [],
  current: null,
  compare: null,
  params: null,
  loading: false
}

const Villages = (state = initialState, { payload, type }) => {
  switch (type) {
    case "VILLAGES_REQ":
      return { ...state, loading: true }
    case "VILLAGES_RES":
      return { ...state, data: payload }
    case "VILLAGE_RES":
      return {
        ...state,
        current: {
          ...payload,
          coordinates: payload.coordinates && isJson(payload.coordinates) ? JSON.parse(payload.coordinates) : null,
          data: payload.data.map(item => ({ ...makeValidData(item), name: item.category.slug }))
        },
        loading: false
      }
    case "VILLAGE_ADDED":
      return { ...state, current: null, loading: false }
    case "VILLAGE_FILES_RES":
      return { ...state, current: { ...state.current, files: payload } }
    case "VILLAGE_FILE_DELETED":
      return {
        ...state,
        current: { ...state.current, files: state.current.files.filter(file => file !== payload) }
      }
    case "VILLAGE_EDITED":
      return {
        ...state,
        current: {
          ...payload,
          coordinates: payload.coordinates && isJson(payload.coordinates) ? JSON.parse(payload.coordinates) : null,
          data: payload.data.map(item => ({ ...makeValidData(item), name: item.category.slug }))
        },
        loading: false
      }
    case "VILLAGE_DELETED":
      return { ...state, data: state.data.filter(i => i.id_village !== payload), loading: false }
    case "VILLAGE_COMPARE_RES":
      return { ...state, compare: payload, loading: false }
    case "VILLAGES_COMPARE_REQ":
      return { ...state, params: payload, loading: true }
    case "VILLAGES_COMPARE_CLEAR":
      return { ...state, compare: null }
    case "VILLAGE_ERROR":
      return { ...state, loading: false }
    case "VILLAGE_BOOKMARKS_RES":
      return { ...state, bookmarks: payload }
    case "VILLAGE_BOOKMARK_ADD":
      return { ...state, bookmarks: [...state.bookmarks, payload] }
    case "VILLAGE_BOOKMARK_DELETE":
      return { ...state, bookmarks: state.bookmarks.filter(i => i.id_bookmark !== payload) }
    case "VILLAGE_BOOKMARK_EDITED":
      return {
        ...state,
        bookmarks: state.bookmarks.map(i => {
          if (i.id_bookmark === payload.id_bookmark) {
            return payload
          } else {
            return i
          }
        })
      }
    default:
      return state
  }
}

export default Villages
