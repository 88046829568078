import React, { Component } from "react"
import { baseUrl } from "../services/helpers"

class Loading extends Component {
	componentDidMount() {
		if (this.props.overlay) {
			document.body.classList.add("no-scroll")
		}
	}
	componentWillUnmount() {
		if (this.props.overlay) {
			document.body.classList.remove("no-scroll")
		}
	}
	render() {
		const { size, overlay } = this.props
		return (
			<div className={`loading-content  ${overlay ? "overlay" : ""}`}>
				<img src={baseUrl(overlay ? "img/loading_inverse.svg" : "img/loading.svg")} alt="loading" height={size || 70} />
			</div>
		)
	}
}

export default Loading
