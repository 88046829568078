import React, { Component, Fragment } from 'react';
import Loading from '../../components/Loading';

import { toast } from 'react-toastify';
import { Button, Row } from 'reactstrap';
import { updateRegexp, filterList, clone } from '../../services/helpers';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import i18n from '../../locale/i18n';

import instance from '../../services/api';

import FormsTopActions from '../../components/Forms/FormsTopActions';
import FormProgress from '../../components/Forms/FormProgress';
import FormDrafts from '../../components/Forms/FormDrafts';
import FormsDate from '../../components/Forms/FormsDate';
import FormField from '../../components/Forms/FormField';

class FormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      id_state: '',
      id_district: '',
      id_place: '',
      formData: null,
      initialData: {},
      currentForm: null,
      loading: true,
      viewMode: window.localStorage.getItem('viewMode') || 'grid'
    };

    this.filterDistricts = filterList('id_state');
    this.filterPlaces = filterList('id_district');
    this.placeVal = null;
    this.changed = false;
    this.submitted = false;
  }

  componentWillUnmount() {
    if (this.placeVal && this.changed) {
      this.handleSaveDraft(this.placeVal);
    }
  }

  componentDidMount() {
    this.getForm(this.props);
  }

  componentDidUpdate(prevProps, ps) {
    const np = this.props.match.params;
    const pp = prevProps.match.params;
    if (ps.currentForm) {
      if (np.id_form !== pp.id_form) {
        this.setState({ loading: true, id_place: '' });
        this.getForm(this.props);
      } else if (np.id_place && np.id_place !== pp.id_place) {
        const draft = this.props.Forms.current.drafts.find((d) => d.id_place === np.id_place);
        this.useDraft(draft);
      } else if (pp.id_place && !np.id_place) {
        this.resetForm();
      }
    }
  }

  setFormData = (data, id_place, id_form) => {
    const { fields, drafts, submitted } = data;
    let draft = null;
    if (id_place) {
      draft = drafts.find((d) => d.id_place === id_place);
    }
    const formData = fields.reduce(
      (acc, i) => ({
        ...acc,
        [i.name]: { regex: updateRegexp(i), content: i.type === 'select' ? i.options[0] : '', isValid: true }
      }),
      {}
    );
    this.changed = false;
    this.setState(
      {
        loading: false,
        formData: formData,
        currentForm: id_form,
        submitedPlaes: submitted.map((i) => +i.id_place),
        initialData: clone(formData)
      },
      () => draft && this.useDraft(draft)
    );
  };

  getForm(props) {
    const {
      match,
      history,
      Forms: { current }
    } = props;
    const { id_form, id_place } = match.params;
    if (current && current.id_form === id_form) {
      this.setFormData(current, id_place, id_form);
    } else {
      this.props
        .getForm(id_form)
        .then(({ payload }) => {
          this.setFormData(payload, id_place, id_form);
        })
        .catch(() => history.push('/forms'));
    }
  }

  resetForm = () => {
    this.setState((prev) => ({
      formData: prev.initialData,
      id_state: '',
      id_district: '',
      id_place: '',
      submitted_date: null
    }));
    this.props.history.replace(`/forms/${this.state.currentForm}`);
    this.refs.form.reset();
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value, validity } = e.target;
    this.changed = true;
    this.setState(({ formData }) => {
      return {
        formData: { ...formData, [name]: { ...formData[name], isValid: validity.valid, content: value } }
      };
    });
  };

  handleChangePlace = ({ target: { name, value } }) => {
    this.changed = true;
    switch (name) {
      case 'id_state':
        this.setState({ id_state: +value, id_district: '', id_place: '' });
        break;
      case 'id_district':
        this.setState({ id_district: +value, id_place: '' });
        break;
      default:
        const {
          Regions: { places },
          Forms: { current }
        } = this.props;
        this.setState({ id_place: +value });
        const place = places.find((i) => +i.id === +value);
        const currentDraft = current.drafts.find((i) => +i.id_place === +value);
        if (currentDraft) {
          const confirm = window.confirm(
            place.name +
              ' բնակավայրը ունի պահպանված նախագիծ, ցանկանու՞մ եք շարունակել այն։ Եթե այո սեխմեք "Ok", եթե ցանկանում եք սկսեք նորը սեխմեք "Cancel։" Ինկատի ունեցեք որ պահպանված տվյալները կջնջվեն։'
          );
          if (confirm) {
            this.useDraft(currentDraft);
          }
        }
        break;
    }
  };

  checkFormValidity = () => {
    let invalids = 0;
    this.setState(({ formData }) => {
      const form = clone(formData);
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          const field = form[key];
          const regexp = new RegExp(field.regex);
          const valid = regexp.test(field.content);
          field.isValid = valid;
          if (!valid) {
            invalids++;
          }
        }
      }
      return { formData: form };
    });
    return invalids;
  };

  handleSubmit = (e, errors, values) => {
    if (this.submitted) return;
    const { Regions, updateForms, history } = this.props;
    const validity = this.checkFormValidity();
    const year = new Date().getFullYear();
    if (!validity && !errors.length) {
      const { formData, currentForm } = this.state;
      const btn = document.getElementById('formSubmitBtn');
      const place = Regions.places.find((i) => +i.id === +values.id_place);
      const data = Object.keys(formData).map((key) => ({
        archive_year: year,
        id_category: key,
        content: formData[key].content,
        language: 'am'
      }));
      btn.classList.add('btn-loading');
      this.submitted = true;
      instance
        .post('/forms/submit', {
          ...values,
          data_json: data,
          id_form: currentForm,
          place_name: place.name,
          archive_year: year
        })
        .then(() => {
          this.changed = false;
          this.submitted = false;
          toast.success('Նախագիծը հաջողությամբ ուղարկված է։');
          updateForms();
          history.push('/forms');
        });
    } else {
      window.scrollTo(0, 0);
      toast.error('Տվյալները սխալ են կամ բացակայում են!');
    }
  };

  handleSaveDraft = (id_place) => {
    if (this.submitted) return;
    this.submitted = true;
    const { formData } = this.state;
    const { id_state, id_district } = this.refs.form.getValues();
    const { Regions, saveDraft } = this.props;
    const id_form = this.state.currentForm;
    const place = Regions.places.find((i) => +i.id === +id_place);
    const archive_year = new Date().getFullYear();
    const data_json = Object.keys(formData).map((key) => ({
      id_category: key,
      content: formData[key].content
    }));
    if (!data_json.find((i) => i.content)) {
      return toast.error('Տվյալները բացակայում են!');
    }
    saveDraft({
      id_form,
      id_place,
      id_state,
      data_json,
      id_district,
      archive_year,
      place_name: place.name
    }).then(() => {
      this.changed = false;
      this.submitted = false;
      toast.success('Նախագիծը հաջողությամբ պահպանված է։');
    });
  };

  handleUseDraft = (draft) => {
    if (!draft.data_json) return;
    if (this.changed) {
      const confirm = window.confirm('Դուք ունեք չպահպանված փոփոխություններ! Հաստատելու դեպքում կկորցնեք այն!');
      if (confirm) {
        this.props.history.replace(`/forms/drafts/${draft.id_form}/${draft.id_place}`);
      }
    } else {
      this.props.history.replace(`/forms/drafts/${draft.id_form}/${draft.id_place}`);
    }
  };

  useDraft = (draft) => {
    if (!draft) {
      return;
    }
    this.changed = false;
    this.setState(({ initialData }) => {
      const formData = clone(initialData);
      draft.data_json.forEach((d) => {
        formData[d.id_category].content = d.content;
      });
      return {
        id_place: +draft.id_place,
        id_state: +draft.id_state,
        id_district: +draft.id_district,
        submitted_date: draft.submitted_date,
        formData
      };
    });
  };

  toggleMode = (mode) => {
    this.setState({ viewMode: mode });
    window.localStorage.setItem('viewMode', mode);
  };

  render() {
    const {
      match,
      history,
      Forms: { current },
      Regions: { states, districts, places }
    } = this.props;
    const {
      formData,
      id_state,
      id_district,
      id_place,
      currentDate,
      viewMode,
      loading,
      submitedPlaes,
      submitted_date
    } = this.state;
    const isGrid = viewMode === 'grid';
    const stateVal = id_state ? id_state : states.length === 1 ? states[0].id : '';
    const districtsArr = stateVal ? this.filterDistricts(stateVal, districts) : [];
    const districtVal = id_district ? id_district : districtsArr.length === 1 ? districtsArr[0].id : '';
    const placesArr = districtVal ? this.filterPlaces(districtVal, places, submitedPlaes) : [];
    this.placeVal = id_place ? id_place : placesArr.length === 1 ? placesArr[0].id : '';

    return (
      <div className="app-content forms-page-content">
        <AvForm onSubmit={this.handleSubmit} ref="form" id="myForm">
          <div className="app-content-header">
            <AvField type="select" onChange={this.handleChangePlace} name="id_state" value={stateVal} required>
              {states.length > 1 ? <option value="">{i18n.t('state')}</option> : null}
              {states.map((s) => (
                <option value={s.id} key={s.id}>
                  {s.name}
                </option>
              ))}
            </AvField>
            <AvField
              type="select"
              onChange={this.handleChangePlace}
              name="id_district"
              value={districtVal}
              disabled={!stateVal}
              required>
              {districtsArr.length > 1 || !stateVal ? <option value="">{i18n.t('district')}</option> : null}
              {districtsArr.map((d) => (
                <option value={d.id} key={d.id}>
                  {d.name}
                </option>
              ))}
            </AvField>
          </div>
          <div className="app-content-inner">
            {!!match.params.id_place && <h1 className="forms-page-title">Պահպանված հարցաշար</h1>}
            {!loading ? (
              <Fragment>
                <FormDrafts
                  drafts={current.drafts}
                  place_id={this.placeVal}
                  params={match.params}
                  onSlect={this.handleUseDraft}
                  handleReset={this.resetForm}
                />
                <FormProgress data={Object.values(formData)} />
                <div className="content-box">
                  <div className="content-inner-header">
                    <h1 className="content-header-title">{current.name}</h1>
                    <FormsTopActions history={history} mode={viewMode} toggle={this.toggleMode} />
                    <div className="content-header-input-wrapper">
                      <AvInput
                        type="select"
                        name="id_place"
                        value={this.placeVal}
                        disabled={!districtVal}
                        onChange={this.handleChangePlace}
                        className="content-header-input"
                        required>
                        {placesArr.length > 1 || !districtVal ? <option value="">{i18n.t('place')}</option> : null}
                        {placesArr.map((p) => (
                          <option value={p.id} key={p.id}>
                            {p.name}
                          </option>
                        ))}
                      </AvInput>
                      <FormsDate date={submitted_date || currentDate} preffix={submitted_date ? 'Պահպանվել է՝' : ''} />
                    </div>
                  </div>
                  <Row>
                    {current.fields.map((field) => (
                      <FormField
                        field={field}
                        isGrid={isGrid}
                        key={field.name}
                        formData={formData}
                        showInputs={true}
                        onChange={this.handleChange}
                      />
                    ))}
                  </Row>
                  <div className="form-actions mb-2">
                    <Button type="reset" color="secondary" onClick={this.resetForm}>
                      Չեղարկել
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => this.handleSaveDraft(this.placeVal)}
                      disabled={!this.placeVal || !this.changed}>
                      Պահպանել
                    </Button>
                    <Button color="success" id="formSubmitBtn" disabled={!this.placeVal}>
                      Ուղարկել
                    </Button>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Loading />
            )}
          </div>
        </AvForm>
      </div>
    );
  }
}

export default FormPage;
