import React, { useState } from 'react';
import SummaryList from './SummaryList';
import { NavItem } from 'reactstrap';

const FormsNavItem = ({ summary, tab }) => {
  const [expanded, toggleCollaps] = useState(false);
  const key = tab.key;
  const disabled = !summary[key].total;

  return (
    <NavItem className={`primary ${expanded ? 'active' : ''}`}>
      <div
        id={`nav_collapse_${key}`}
        className={`nav-item-header ${disabled ? 'disabled' : ''}`}
        onClick={() => toggleCollaps(!expanded)}>
        <strong className="nav-item-title">
          {tab.title} Հարցաշարեր ({summary[key].total})
          {!disabled && <i className="item-icon  material-icons">arrow_drop_down</i>}
        </strong>
      </div>
      {expanded && <SummaryList summary={summary} tab={tab} key={key} />}
    </NavItem>
  );
};

export default FormsNavItem;
