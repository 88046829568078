import React, { Component } from 'react';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import i18n from '../locale/i18n';
import Files from '../components/Files';
import Loading from '../components/Loading';
import PlaceFields from '../components/PlaceFields';
import LanguagesNav from '../components/LanguagesNav';
import { clone, modifyData, updateRegexp, nullToString } from '../services/helpers';
import { isViewer } from '../services/permissions';
import {
  Button,
  Label,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Input
} from 'reactstrap';

const currentYear = new Date().getFullYear();
class VillageEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t: i18n.getFixedT(props.User.language),
      activeLang: props.User.language,
      invalidGroups: new Set(),
      activeCategory: '',
      attachedSources: [],
      currentData: null,
      sourceModal: false,
      fileLoading: false,
      synced: false,
      data: {}
    };
  }

  componentDidMount() {
    const { Sources, getSources } = this.props;
    this.getVillage(this.props);
    if (!Sources().length) getSources();
  }

  componentDidUpdate() {
    const {
      Villages: { current }
    } = this.props;
    const { synced } = this.state;
    if (!synced && current) {
      const village = clone(current);
      this.setState({
        synced: true,
        data: village.data.reduce((acc, cur) => {
          acc[cur.name] = cur;
          return acc;
        }, {})
      });
    }
  }

  getVillage = props => {
    const {
      getVillage,
      location,
      history,
      Villages: { current },
      match: { params }
    } = props;
    const village = clone(current);
    const category = location.hash ? location.hash.replace('#', '') : null;

    if (!village || village.id_village !== params.id_village) {
      getVillage(params.id_village).then(data => {
        if (data && data.payload) {
          const { columns } = data.payload;
          const activeCategory = category ? category : columns.length ? columns[0].id_category : '';
          this.setState({ activeCategory });
          history.replace({ hash: activeCategory });
        }
      });
    } else {
      const activeCategory = category ? category : village.columns.length ? village.columns[0].id_category : '';
      this.setState({
        activeCategory,
        synced: true,
        data: village.data.reduce((acc, cur) => {
          acc[cur.name] = cur;
          return acc;
        }, {})
      });
      history.replace({ hash: activeCategory });
    }
  };

  handleChangeLang = lang => {
    if (this.state.activeLang !== lang) {
      this.setState({
        t: i18n.getFixedT(lang),
        activeLang: lang
      });
    }
  };

  openSourceModal = item => {
    this.setState(state => ({
      attachedSources:
        item.data && item.data[state.activeLang] && item.data[state.activeLang].sources
          ? item.data[state.activeLang].sources
          : [],
      currentData: item.name,
      sourceModal: true
    }));
  };

  toggleSourceModal = () => {
    this.setState(state => ({
      currentData: null,
      sourceModal: !state.sourceModal
    }));
  };

  attachSource = (sc, checked) => {
    this.setState(state => ({
      attachedSources: checked
        ? [...state.attachedSources, sc]
        : state.attachedSources.filter(i => i.id_source !== sc.id_source)
    }));
  };

  saveSources = () => {
    this.setState(state => {
      const item = state.data[state.currentData];
      if (item.category.content_type && item.category.content_type.type === 'number') {
        item.data.am.sources = clone(state.attachedSources);
        item.data.en.sources = clone(state.attachedSources);
        item.data.ru.sources = clone(state.attachedSources);
      } else {
        item.data[state.activeLang].sources = clone(state.attachedSources);
      }
      return {
        sourceModal: false,
        data: state.data
      };
    });
  };

  handleFileUpload = ({ target: { files } }) => {
    const {
      Villages: { current },
      addVillageFilles
    } = this.props;
    let formData = new FormData();
    if (files.length) {
      formData.append('file', files[0]);
      this.setState({ fileLoading: true });
      addVillageFilles(formData, current.id_village).then(() => {
        if (isViewer()) {
          toast.success(i18n.t('VIEWER_SUCCESS_MESSAGE'));
        }
        this.setState({ fileLoading: false });
      });
    }
  };

  handleDeleteFile = file => {
    const {
      Villages: { current },
      deleteVillageFile
    } = this.props;
    deleteVillageFile(file.src, current.id_village).then(() => {
      if (isViewer()) {
        toast.success(i18n.t('VIEWER_SUCCESS_MESSAGE'));
      }
    });
  };

  handleChageData = ({ target: { name, value, validity } }, type, key) => {
    this.setState(state => {
      const item = state.data[key];
      item.data[state.activeLang][`invalid_${type}`] = !validity.valid;
      if (item.category.content_type && item.category.content_type.type === 'number') {
        item.data.am[type] = value;
        item.data.ru[type] = value;
        item.data.en[type] = value;
      } else {
        item.data[state.activeLang][type] = value;
      }
      return {
        data: state.data
      };
    });
  };

  filterData = (d, data) => {
    const item = data.find(i => i.category.id_category === d.id_category);
    const itemLang = item.data[d.language];

    itemLang.sources = itemLang.sources || [];

    const sameArchive_year = !itemLang.archive_year || itemLang.archive_year === d.archive_year;
    const sameContent = itemLang.content === d.content;
    const sameSources = itemLang.sources.map(i => i.id_source).equals(d.sources);

    return !sameArchive_year || !sameContent || !sameSources;
  };

  handleSubmit = (e, values) => {
    const {
      User: { language },
      Villages: { current },
      editVillageData,
      getDataFlat,
      history
    } = this.props;

    let isValid = true;

    this.setState(state => {
      state.invalidGroups.clear();
      for (const field in state.data) {
        if (state.data.hasOwnProperty(field)) {
          const item = state.data[field];
          const content = item.data[state.activeLang].content;
          const vaidation = item.category.content_type;
          if (vaidation) {
            const regexp = new RegExp(updateRegexp(vaidation));
            const validity = regexp.test(content);
            item.data[state.activeLang].invalid_content = !validity;
            if (!validity) {
              isValid = false;
              state.invalidGroups.add(item.category.id_root);
            }
          }
        }
      }
      return {
        invalidGroups: state.invalidGroups,
        data: state.data
      };
    });

    const data = modifyData(Object.values(this.state.data));
    const changedData = [];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      if (this.filterData(d, current.data)) {
        changedData.push(d);
      }
    }

    if (isValid) {
      if (values.coordinates) {
        if (values.coordinates.lat && values.coordinates.lng) {
          values.coordinates = JSON.stringify({
            lat: Number(values.coordinates.lat),
            lng: Number(values.coordinates.lng)
          });
        } else {
          delete values.coordinates;
        }
      }

      editVillageData({ ...values, data: changedData, code: +values.code }, current.id_village).then(data => {
        if (data) {
          if (isViewer()) {
            toast.success(i18n.t('VIEWER_SUCCESS_MESSAGE'));
          }
          getDataFlat({ lang: language, recent_state: true });
          history.replace(`/village/${current.id_village}`);
        }
      });
    } else {
      window.scrollTo(0, 0);
    }
  };

  handleInvalidSubmit = () => {
    window.scrollTo(0, 0);
  };

  isRequired = ({ category: { content_type }, data }) => {
    const hasData = Boolean(data.am.content || data.en.content || data.ru.content);
    return Boolean(content_type && content_type.regex && !hasData);
  };

  setActiveCategory = activeCategory => {
    const { history } = this.props;
    this.setState({ activeCategory });
    history.replace({ hash: activeCategory });
  };

  handleGoBack = () => {
    const { activeCategory } = this.state;

    const {
      Villages: { current },
      history
    } = this.props;

    history.replace({
      pathname: `/village/${current.id_village}`,
      hash: activeCategory
    });
  };

  render() {
    const {
      User: { languages },
      Villages: { current, loading },
      Sources
    } = this.props;
    const {
      t,
      data,
      activeLang,
      sourceQuery,
      sourceModal,
      activeCategory,
      attachedSources,
      invalidGroups,
      fileLoading
    } = this.state;

    return (
      <div className="app-content-wrapper">
        <LanguagesNav activeLang={activeLang} languages={languages} handleChangeLang={this.handleChangeLang} />
        <div className="app-content content-fluid">
          <div className="app-content-header">
            <h1>{i18n.t('EDIT_VILLAGE')}</h1>
          </div>
          {loading ? (
            <Loading />
          ) : current ? (
            <div className="app-content-inner">
              <Button onClick={this.handleGoBack} color="link btn-back">
                <i className="material-icons">&#xE317;</i>
                {i18n.t('BACK')}
              </Button>
              <AvForm
                className="mt-4"
                onValidSubmit={this.handleSubmit}
                onInvalidSubmit={this.handleInvalidSubmit}
                model={nullToString(current)}>
                {isViewer() ? (
                  <div className="content-inner-header">
                    <h1 className="content-header-title">{current.name[activeLang]} </h1>
                    <div className="content-header-subtitle">
                      <ul>
                        <li>
                          <b>{t('CODE')}:</b> {current.code || ''}
                        </li>
                        <li>
                          <b>{t('state')}:</b>
                          {current.state.name[activeLang]}
                        </li>
                        <li>
                          <b>{t('district')}:</b>
                          {current.district.name[activeLang]}
                        </li>

                        <li>
                          <b>{t('archive_year')}:</b>
                          {current.archive_year || ''}
                        </li>
                        <li>
                          <b>{t('COORDINATES')}:</b>
                          {current.coordinates ? Object.values(current.coordinates).join(', ') : '- -'}
                        </li>
                      </ul>
                      <AvInput name={`code`} type="hidden" />
                    </div>
                  </div>
                ) : (
                  <Row>
                    <Col sm={{ size: 6, offset: 3 }}>
                      <Row>
                        <Col sm="8">
                          {languages.map((item, i) => (
                            <AvGroup
                              key={i}
                              className={classnames({
                                'd-none': item.language !== activeLang
                              })}>
                              <AvInput
                                autoComplete="off"
                                name={`name.${item.language}`}
                                required={item.language !== 'ru'}
                              />
                              <Label className="form-control-label">{t('name')}*</Label>
                            </AvGroup>
                          ))}
                        </Col>
                        <Col sm="4">
                          <AvGroup>
                            <AvInput
                              maxLength="10"
                              name={`code`}
                              type="number"
                              autoComplete="off"
                              validate={{ number: true }}
                            />
                            <Label className="form-control-label">{t('CODE')}*</Label>
                          </AvGroup>
                        </Col>
                      </Row>
                      <PlaceFields
                        data={{
                          id_state: current.id_state,
                          id_district: current.id_district
                        }}
                        t={t}
                      />
                      <Row>
                        <Col>
                          <AvGroup>
                            <AvInput
                              name={`coordinates.lat`}
                              type="number"
                              validate={{ number: true, pattern: { value: /^([-+]?)([\d]{1,2})(((\.)(\d+)))$/ } }}
                              autoComplete="off"
                            />
                            <Label className="form-control-label">{t('LATITUDE')}*</Label>
                          </AvGroup>
                        </Col>
                        <Col>
                          <AvGroup>
                            <AvInput
                              name={`coordinates.lng`}
                              type="number"
                              validate={{ number: true, pattern: { value: /^([-+]?)([\d]{1,2})(((\.)(\d+)))$/ } }}
                              autoComplete="off"
                            />
                            <Label className="form-control-label">{t('LONGITUDE')}*</Label>
                          </AvGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <div className="mt-4 d-flex align-items-center justify-content-between">
                  <h3 className="m-0">{i18n.t('ATTACHED_FILES')}</h3>
                  <Label className={`btn btn-success btn-sm ${fileLoading ? 'btn-loading' : ''}`}>
                    <i className="material-icons">attach_file</i>
                    {i18n.t(isViewer() ? 'ATTACH_FILES_VIEWER' : 'ATTACH_FILES')}
                    <Input
                      // multiple
                      name="file"
                      type="file"
                      onChange={this.handleFileUpload}
                      className="d-none"
                    />
                  </Label>
                </div>
                {!!current.files && <Files data={current.files} deleteFile={this.handleDeleteFile} />}

                <Nav className="nav-tab">
                  {current.columns.map((cat, i) => (
                    <NavItem key={i} className={invalidGroups.has(cat.id_category) ? 'has-error' : ''}>
                      <NavLink
                        active={activeCategory === cat.id_category}
                        onClick={() => this.setActiveCategory(cat.id_category)}>
                        {cat.name[activeLang]}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <div className="content-box mb-5">
                  <Table className="table-simple secondary village">
                    <thead>
                      <tr>
                        <th>{t('CATEGORY')}</th>
                        <th>{t('data')}</th>
                        <th className="text-center">{t('sources')}</th>
                        <th className="text-center">{t('archive_year')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(data)
                        .filter(i => data[i].category.id_root === activeCategory)
                        .map((key, i) => (
                          <tr key={i}>
                            <td className="category-col">
                              {data[key].category.id_root !== data[key].category.id_parent && (
                                <span className="parent">{data[key].category.parent.name[activeLang]}</span>
                              )}
                              <strong>{data[key].category.name[activeLang]}</strong>
                            </td>
                            <td
                              className={`input-col ${data[key].data[activeLang].invalid_content ? 'has-error' : ''}`}>
                              <Input
                                autoComplete="off"
                                name={`content.${[key]}`}
                                placeholder={t('ADD_DATA')}
                                className={`textarea-autosize lg`}
                                required={this.isRequired(data[key])}
                                value={data[key].data[activeLang].content}
                                onChange={e => this.handleChageData(e, 'content', key)}
                                pattern={updateRegexp(data[key].category.content_type)}
                                type={data[key].category.content_type.type === 'string' ? 'textarea' : 'text'}
                              />
                              {data[key].category.content_type && (
                                <p className="error-message">{data[key].category.content_type.name[activeLang]}</p>
                              )}
                            </td>
                            <td className="text-center c-pointer" onClick={() => this.openSourceModal(data[key])}>
                              {data[key].data[activeLang].sources && data[key].data[activeLang].sources.length ? (
                                data[key].data[activeLang].sources.map((source, i) => (
                                  <b className="source-code" key={i}>
                                    {source.code}
                                  </b>
                                ))
                              ) : (
                                <i className="text-muted">{t('ADD_DATA')}</i>
                              )}
                            </td>

                            <td className={`p-0 ${data[key].data[activeLang].invalid_archive_year ? 'has-error' : ''}`}>
                              <Input
                                type="number"
                                min="1800"
                                max={currentYear}
                                autoComplete="off"
                                placeholder={t('ADD_DATA')}
                                name={`archive_year::${[key]}`}
                                onChange={e => this.handleChageData(e, 'archive_year', key)}
                                value={data[key].data[activeLang].archive_year}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                <div className="form-actions">
                  <Link className="btn btn-secondary btn-sm" to={`/village/${current.id_village}`}>
                    {i18n.t('CANCEL')}
                  </Link>

                  <Button color={`primary`} size="sm">
                    {i18n.t(isViewer() ? 'SUBMIT' : 'SAVE')}
                  </Button>
                </div>
              </AvForm>
            </div>
          ) : (
            <div className="text-muted text-center p-5">not found</div>
          )}
        </div>

        <Modal isOpen={sourceModal} toggle={this.toggleSourceModal} size="lg">
          <ModalHeader toggle={this.toggleSourceModal}>{i18n.t('ATTACH_SOURCES')}</ModalHeader>
          <ModalBody>
            <Row className="attach-data-row">
              <Col md="8" className="data-col">
                <div className="form-group w-50 p-0">
                  <Input
                    name="keyword"
                    value={sourceQuery}
                    onChange={e => this.setState({ sourceQuery: e.target.value })}
                    placeholder={i18n.t('SEARCH_KEYWORD')}
                  />
                </div>
                <ul className="data-list">
                  {Sources(sourceQuery).map((item, index) => (
                    <li key={index}>
                      <label>
                        <Input
                          type="checkbox"
                          checked={Boolean(attachedSources.find(i => i.id_source === item.id_source))}
                          onChange={e => this.attachSource(item, e.target.checked)}
                        />
                        <div className="list-check">
                          <i className="material-icons">check</i>
                          <span className="item-code">{item.code}</span>
                          <span className="item-desc">{item.description[activeLang]}</span>
                        </div>
                      </label>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col md="4" className="attached-col">
                <h3>{i18n.t('sources')}</h3>
                <ul className="attached-list">
                  {attachedSources.map((item, index) => (
                    <li key={index}>
                      <span>{item.code}</span>
                      <button className="no-style" onClick={() => this.attachSource(item, false)}>
                        <i className="material-icons">close</i>
                      </button>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="text-dark" onClick={this.toggleSourceModal}>
              {i18n.t('CANCEL')}
            </Button>
            <Button color="link" onClick={this.saveSources}>
              {i18n.t('SAVE')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default VillageEdit;
