import { limits } from '../constants/';

const initialState = {
  data: [],
  columns: [],
  advancedSearch: {
    list: [],
    data: [],
    total: 0,
    loading: false,
    params: { page: 1, limit: limits.advancedSearch, queries: [] }
  },
  params: {
    hiddenColums: [],
    recent_state: true,
    id_user_state: null,
    order_column: null,
    archive_year: '',
    order_by: 'asc',
    categories: [],
    keywords: [],
    id_district: 0,
    id_state: 0,
    limit: limits.dataFlat,
    page: 1,
    lang: ''
  },
  total: 0,
  loading: false
};

const Data = (state = initialState, action) => {
  switch (action.type) {
    case 'DATA_RES_ERR':
      return {
        ...state,
        loading: false
      };
    case 'DATA_REQ':
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload
        },
        loading: true
      };
    case 'DATA_RES':
      const { total, data, columns } = action.payload;
      const { hiddenColums } = state.params;
      return {
        ...state,
        total,
        data: data || [],
        columns: columns ? columns.map(col => ({ ...col, is_visible: !hiddenColums.includes(col.key) })) : [],
        loading: false
      };
    case 'RECENTSTATE_RES':
      const recent = action.payload.state;
      return {
        ...state,
        params: {
          ...state.params,
          ...recent
        },
        columns: state.columns.map(col => ({
          ...col,
          is_visible: recent.hiddenColums ? !recent.hiddenColums.includes(col.key) : true
        }))
      };
    case 'DATA_ADV_SEARCH_REQ':
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          loading: true,
          params: action.payload.params
        }
      };
    case 'DATA_ADV_SEARCH_RES':
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          ...action.payload,
          loading: false
        }
      };
    case 'DATA_ADV_SEARCH_LIST_RES':
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          list: action.payload
        }
      };
    case 'DATA_ADV_SEARCH_SAVE_RES':
      return {
        ...state,
        advancedSearch: {
          ...state.advancedSearch,
          list: [...state.advancedSearch.list, action.payload]
        }
      };
    case 'USER_LOGOUT':
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default Data;
