import instance from "../services/api"
import errorHandler from "../services/errorHandler"

export const getActivityLogs = params => {
	return dispatch => {
		dispatch({ type: "ACTIVITYLOGS_REQ", payload: params })
		return instance
			.get(`/action`, { params })
			.then(({ data }) => {
				dispatch({ type: "ACTIVITYLOGS_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const getActivityLog = id => {
	return dispatch => {
		dispatch({ type: "ACTIVITYLOGS_REQ" })
		return instance
			.get(`/action/${id}`)
			.then(({ data }) => {
				dispatch({ type: "ACTION_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const deleteActivityLog = id => {
	return dispatch => {
		dispatch({ type: "ACTIVITYLOGS_REQ" })
		return instance
			.delete(`/action/${id}`)
			.then(({ data }) => {
				dispatch({ type: "ACTIVITYLOG_DELETED", payload: id })
			})
			.catch(errorHandler)
	}
}
