import { connect } from "react-redux";
import Groups from "../../../pages/Account/Groups/Groups";
import { getGroups, addGroup } from "../../../actions/Groups";

const mapStateToProps = state => {
	return {
		Groups: state.Groups
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getGroups() {
			dispatch(getGroups());
		},
		addGroup(body) {
			return dispatch(addGroup(body));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
