import { connect } from "react-redux"
import GroupMembers from "../../../pages/Account/Groups/GroupMembers"
import { getGroups } from "../../../actions/Groups"
import { editUser, getUsers } from "../../../actions/Users"

const mapStateToProps = (state, props) => {
	// const filtered = state.Users.data.filter(i => i.id_group !== props.match.params.groupId)
	const filtered = state.Users.data

	return {
		Groups: state.Groups,
		Users: state.Users,
		GroupMembers: state.Users.data.filter(member => member.id_group === state.Groups.currentGroup.id_group),
		User: state.User,
		FilteredUsers: (query = "") =>
			query.length
				? filtered.filter(
						({ first_name, last_name }) =>
							first_name.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
							last_name.toLowerCase().indexOf(query.toLowerCase()) > -1
					)
				: filtered.slice(0, 100)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getGroups() {
			dispatch(getGroups())
		},
		getUsers() {
			dispatch(getUsers())
		},
		editUser(body, id) {
			return dispatch(editUser(body, id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupMembers)
