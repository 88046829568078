import { connect } from 'react-redux';
import AdvancedSearch from '../pages/AdvancedSearch';

import { advanceSearch, advanceSearchSave, getAdvanceSearchList } from '../actions/Data';

const mapStateToProps = (state) => {
  return {
    User: state.User,
    Data: state.Data,
    Categories: state.Categories
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    advanceSearch(params) {
      return dispatch(advanceSearch(params));
    },
    advanceSearchSave(params) {
      return dispatch(advanceSearchSave(params));
    },
    getAdvanceSearchList() {
      dispatch(getAdvanceSearchList());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearch);
