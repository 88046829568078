import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"

import LibrariesSidebar from "../components/LibrariesSidebar"

import Library from "../containers/Libraries/Library"
import Sources from "../containers/Libraries/Sources"

class Libraries extends Component {
	render() {
		return (
			<div className="app-content-wrapper">
				<LibrariesSidebar {...this.props} />
				<div className="app-content">
					<Switch>
						<Route exact path="/libraries/library" component={Library} />
						<Route exact path="/libraries/sources" component={Sources} />
						<Redirect to="/libraries/library" />
					</Switch>
				</div>
			</div>
		)
	}
}

export default Libraries
