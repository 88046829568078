import instance from '../services/api';
import errorHandler from '../services/errorHandler';

export const getInvitations = () => {
  return (dispatch) => {
    dispatch({ type: 'INVITATIONS_REQ' });
    return instance
      .get(`/invitation`)
      .then(({ data }) => {
        dispatch({ type: 'INVITATIONS_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

export const validateInvitation = () => {
  return instance.get(`/invitation/validate`).catch(errorHandler);
};

export const addInvitation = (body) => {
  return (dispatch) => {
    dispatch({ type: 'INVITATIONS_REQ' });
    return instance
      .post(`/invitation`, body)
      .then(({ data }) => {
        dispatch({ type: 'INVITATION_ADDED', payload: data });
      })
      .catch(errorHandler);
  };
};

export const editInvitation = (body, id) => {
  return (dispatch) => {
    dispatch({ type: 'INVITATIONS_REQ' });
    return instance
      .put(`/invitation/${id}`, body)
      .then(({ data }) => {
        dispatch({ type: 'INVITATION_EDITED', payload: data });
      })
      .catch(errorHandler);
  };
};

export const deleteInvitation = (id) => {
  return (dispatch) => {
    dispatch({ type: 'INVITATIONS_REQ' });
    return instance
      .delete(`/invitation/${id}`)
      .then(({ data }) => {
        dispatch({ type: 'INVITATION_DELETED', payload: id });
      })
      .catch(errorHandler);
  };
};
