import { connect } from "react-redux"
import Compare from "../pages/Compare"
import {
	getCompareNumericData,
	exportCompareData,
	getCompareData,
	getCompareList,
	clearCompare,
	saveCompare,
	getVillages
} from "../actions/Villages"

const mapStateToProps = state => {
	return {
		User: state.User,
		Villages: state.Villages,
		VillageSearch: (query = "", lang = "am") =>
			query.length
				? state.Villages.data.filter(({ name }) => name[lang].toLowerCase().indexOf(query.toLowerCase()) > -1)
				: state.Villages.data
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getCompareData(params) {
			return dispatch(getCompareData(params))
		},
		getCompareNumericData(params) {
			return dispatch(getCompareNumericData(params))
		},
		exportCompareData(params) {
			return dispatch(exportCompareData(params))
		},
		clearCompare() {
			dispatch(clearCompare())
		},
		getCompareList() {
			dispatch(getCompareList())
		},
		getVillages() {
			dispatch(getVillages())
		},
		saveCompare(body) {
			return dispatch(saveCompare(body))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Compare)
