import React from 'react';

const FormsAlert = ({ children }) => {
  if (!children) return null;

  return (
    <div className="forms-alert mb-2">
      <i className="alert-icon material-icons">warning</i>
      <div dangerouslySetInnerHTML={{ __html: children }} />
    </div>
  );
};

export default FormsAlert;
