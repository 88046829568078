const initialState = {
  data: [],
  currentGroup: null,
  loading: false
};

const Groups = (state = initialState, action) => {
  switch (action.type) {
    case 'GROUPS_REQ':
      return {
        ...state,
        loading: true
      };
    case 'GROUPS_REQ_ERR':
      return {
        ...state,
        loading: false
      };
    case 'GROUPS_RES':
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case 'GROUP_ADDED':
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      };
    case 'GROUP_DELETED':
      return {
        ...state,
        data: state.data.filter(item => item.id_group !== action.payload),
        loading: false
      };
    case 'GROUP_EDITED':
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id_group === action.payload.id_group) {
            return action.payload;
          } else {
            return item;
          }
        }),
        currentGroup: action.payload,
        loading: false
      };
    case 'GROUP_RES':
      return {
        ...state,
        currentGroup: { ...state.currentGroup, ...action.payload },
        loading: false
      };
    case 'GROUP_SELECT':
      return {
        ...state,
        currentGroup: state.data.find(item => item.id_group === action.payload) || null
      };
    case 'GROUP_CLEAR':
      return {
        ...state,
        currentGroup: null
      };
    case 'GROUP_PLACES_RES':
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          places: action.payload
        },
        loading: false
      };
    case 'GROUP_CAT_RES':
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          categories: action.payload
        },
        loading: false
      };
    case 'GROUP_CAT_ADDED':
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          categories: [...state.currentGroup.categories, action.payload]
        },
        loading: false
      };
    case 'GROUP_CAT_EDITED':
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          categories: action.payload.categories || action.payload
        },
        loading: false
      };
    case 'GROUP_CAT_DELETED':
      return {
        ...state,
        currentGroup: {
          ...state.currentGroup,
          categories: action.payload
        },
        loading: false
      };
    case 'USER_LOGOUT':
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default Groups;
