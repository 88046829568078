import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import ContentNav from "../components/ContentNav"
import { approvalNavItems } from "../constants/"
import i18n from "../locale/i18n"

import ApprovalList from "../containers/Approvals/ApprovalList"

class Approval extends Component {
	render() {
		return (
			<div className="app-content-wrapper">
				<div className="app-content content-fluid">
					<div className="app-content-header">
						<h1>{i18n.t("APPROVAL_LIST")}</h1>
					</div>
					<div className="app-content-inner">
						<ContentNav navItems={approvalNavItems} />
						<Switch>
							<Route exact path="/approvals/:type" component={ApprovalList} />
							<Redirect to={approvalNavItems[0].url} />
						</Switch>
					</div>
				</div>
			</div>
		)
	}
}

export default Approval
