import React, { Component } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from "reactstrap"
import { AvForm, AvGroup, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import Loading from "../../../components/Loading"
import i18n from "../../../locale/i18n"
import { toast } from "react-toastify"

class GroupMembers extends Component {
	constructor(props) {
		super(props)

		this.state = {
			userQuery: "",
			targetUser: null,
			stateLoading: false,
			editModal: false,
			addModal: false
		}
	}

	componentDidMount() {
		const { Groups, Users, getUsers, getGroups } = this.props

		if (!Groups.data.length) getGroups()
		if (!Users.data.length) getUsers()
	}

	handleChangeGroup = user => {
		this.setState({
			targetUser: user,
			editModal: true
		})
	}

	toggleEditModal = () => {
		this.setState({
			editModal: !this.state.editModal,
			targetUser: null
		})
	}

	toggleAddModal = () => {
		this.setState({
			addModal: !this.state.addModal
		})
	}

	handleSubmitEdit = (event, values) => {
		const { targetUser: { id_user } } = this.state
		this.setState({ editModal: false })

		this.props
			.editUser(values, id_user)
			.then(() => {
				this.setState({ targetUser: null, expanded: "" })
				toast.success(i18n.t("SUCCESS_EDIT_GROUP_MEMBER"))
			})
			.catch(() => {
				this.setState({ stateLoading: false })
			})
	}

	handleSubmitAdd = (event, values) => {
		const { match: { params: { groupId } } } = this.props
		this.setState({ addModal: false })
		this.props
			.editUser({ id_group: groupId }, values.id_user)
			.then(() => {
				this.setState({ stateLoading: false })
				toast.success(i18n.t("SUCCESS_ADD_GROUP_MEMBER"))
			})
			.catch(() => {
				this.setState({ stateLoading: false })
			})
	}

	render() {
		const { User: { data }, Users, GroupMembers, FilteredUsers, Groups } = this.props
		const { editModal, targetUser, addModal, userQuery } = this.state
		const usersList = addModal ? FilteredUsers(userQuery) : []

		return (
			<div>
				<div className="app-content-inner content-sm">
					{Users.loading ? (
						<Loading />
					) : (
						<ul className="panel-list">
							{GroupMembers.filter(i => i.id_user !== data.id_user).map((item, index) => (
								<li className="list-item" key={index}>
									<div className="item-header">
										<div className="item-title">{`${item.first_name} ${item.last_name}`}</div>
										<div className="item-actions">
											<Button color="link" className="p-2" onClick={() => this.handleChangeGroup(item)}>
												<i className="icon icon-arrow-right" />
											</Button>
											<Link className="btn btn-link p-2" to={`/account/user/${item.id_user}`}>
												<i className="icon icon-view-profile" />
											</Link>
										</div>
									</div>
								</li>
							))}
						</ul>
					)}
				</div>

				<div className="add-action">
					<Button color="success" onClick={this.toggleAddModal}>
						<i className="material-icons">&#xE145;</i>
					</Button>
				</div>

				<Modal isOpen={editModal} toggle={this.toggleEditModal} size="sm">
					<ModalHeader toggle={this.toggleEditModal}>{i18n.t("CHANGE_GROUP")}</ModalHeader>
					<AvForm onValidSubmit={this.handleSubmitEdit} model={targetUser}>
						<ModalBody>
							<AvGroup>
								<AvInput name="id_group" type="select" required>
									<option value="" disabled>
										{i18n.t("CHOOSE")}
									</option>
									{Groups.data.map(group => (
										<option key={group.id_group} value={group.id_group}>
											{group.title}
										</option>
									))}
								</AvInput>
								<Label className="form-control-label">{i18n.t("GROUP_NAME")}</Label>
							</AvGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="link" className="text-dark" onClick={this.toggleEditModal}>
								{i18n.t("CANCEL")}
							</Button>
							<Button color="link">{i18n.t("SAVE")}</Button>
						</ModalFooter>
					</AvForm>
				</Modal>

				<Modal isOpen={addModal} toggle={this.toggleAddModal}>
					<ModalHeader toggle={this.toggleAddModal}>{i18n.t("ADD_GROUP_MEMBER")}</ModalHeader>
					<AvForm onValidSubmit={this.handleSubmitAdd}>
						<ModalBody>
							<div className="attach-data-row">
								<div className="data-col">
									<div className="form-group w-50 p-0">
										<Input
											name="keyword"
											value={userQuery}
											onChange={e => this.setState({ userQuery: e.target.value })}
											placeholder={i18n.t("SEARCH_KEYWORD")}
										/>
									</div>
									{usersList.length ? (
										<AvRadioGroup tag="ul" name="id_user" className="data-list">
											{usersList.map((item, index) => (
												<li key={index}>
													<AvRadio
														type="radio"
														value={item.id_user}
														label={
															<div className="list-check">
																<i className="material-icons">check</i>
																<span className="item-desc">
																	{item.first_name} {item.last_name}
																</span>
															</div>
														}
													/>
												</li>
											))}
										</AvRadioGroup>
									) : (
										<div className="p-4 text-center">
											<h4>{i18n.t("USER_NOT_FOUND")}</h4>
											<Link to={`/account/users/add`}>{i18n.t("ADD_USER")}</Link>
										</div>
									)}
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button color="link" className="text-dark" onClick={this.toggleAddModal}>
								{i18n.t("CANCEL")}
							</Button>
							<Button color="link">{i18n.t("SAVE")}</Button>
						</ModalFooter>
					</AvForm>
				</Modal>
			</div>
		)
	}
}

export default GroupMembers
