import { connect } from "react-redux";
import ResetPassword from "../pages/ResetPassword";
import { resetPassword } from "../actions/Auth";

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		resetPassword(body, props) {
			return dispatch(resetPassword(body, props));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
