import instance from '../services/api';

import { toast } from 'react-toastify';
import errorHandler from '../services/errorHandler';
import { getUsers } from './Users';
import { logout } from './Auth';
import { updateCategoriesBySlug, getCategories } from './Categories';

const getLanguages = () => {
  return dispatch => {
    return instance
      .get(`/language`)
      .then(({ data }) => {
        dispatch({ type: 'LANGUAGES_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

const getProfile = id => {
  return dispatch => {
    dispatch({ type: 'USER_REQ' });
    return instance
      .get(`/user/${id}`)
      .then(({ data }) => {
        return dispatch({ type: 'USER_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

const editProfile = (body, id) => {
  return dispatch => {
    dispatch({ type: `USER_EDIT_REQ` });
    return instance
      .put(`/user/${id}`, body)
      .then(({ data }) => {
        toast.success('Profile successfuly updated!');
        // setInstance(data.token)
        dispatch(getUsers());
        return dispatch({ type: `USER_EDIT_RES`, payload: data.user });
      })
      .catch(errorHandler);
  };
};

const deleteProfile = id => {
  return dispatch => {
    dispatch({ type: `USER_DELETE_REQ` });
    return instance
      .delete(`/user/${id}`)
      .then(() => {
        dispatch(logout());
      })
      .catch(errorHandler);
  };
};

const changeLanguage = lng => {
  localStorage.setItem('locale', lng);
  return { type: 'CHANEGE_LANGUAGE', payload: lng };
};

const getUserStates = id => {
  return dispatch => {
    return instance
      .get(`/user/${id}/state`)
      .then(({ data }) => {
        return dispatch({ type: 'USER_STATE_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

const deleteState = (id, state) => {
  return dispatch => {
    return instance
      .delete(`/user/${id}/state`, { params: { id_user_state: state } })
      .then(({ data }) => {
        dispatch({ type: 'USER_STATE_DELETE', payload: state });
      })
      .catch(errorHandler);
  };
};

const editState = (id, state_id, name) => {
  return dispatch => {
    return instance
      .put(`/user/${id}/state`, { name }, { params: { id_user_state: state_id } })
      .then(({ data }) => {
        return dispatch(getUserStates(id));
      })
      .catch(errorHandler);
  };
};

const saveUserState = (body, id) => {
  if (body.state.id_state === '0') delete body.state.id_state;
  if (!body.state.order_column) delete body.state.order_column;
  delete body.state.id_user_state;
  delete body.state.recent_state;
  return dispatch => {
    return instance
      .post(`/user/${id}/state`, body)
      .then(({ data }) => {
        return dispatch({ type: 'USER_STATE_RES', payload: data });
      })
      .catch(errorHandler);
  };
};
const getUserRecentState = id => {
  return dispatch => {
    return instance
      .get(`/user/${id}/recentState`)
      .then(({ data }) => {
        dispatch({ type: 'RECENTSTATE_RES', payload: data });
        dispatch(getCategories()).then(() => {
          if (data.id_user_state && data.state) dispatch(updateCategoriesBySlug(data.state.categories || []));
        });
      })
      .catch(errorHandler);
  };
};

const getUserSettings = id => {
  return dispatch => {
    return instance
      .get(`/user/${id}/settings`)
      .then(({ data }) => {
        dispatch({ type: 'USER_SETTINGS_RES', payload: data });
      })
      .catch(errorHandler);
  };
};
const getUserData = id => {
  return dispatch => {
    dispatch(getUserSettings(id));
    dispatch(getUserStates(id));
    dispatch(getUserRecentState(id));
  };
};

export {
  getLanguages,
  getProfile,
  editProfile,
  deleteProfile,
  changeLanguage,
  saveUserState,
  deleteState,
  getUserData,
  editState
};
