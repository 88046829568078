import instance from "../services/api";
import errorHandler from "../services/errorHandler";

export const getSources = params => {
	return dispatch => {
		dispatch({ type: "SOURCES_REQ" });
		return instance
			.get(`/source`, { params: params })
			.then(({ data }) => {
				dispatch({ type: "SOURCES_RES", payload: { ...data, ...params } });
			})
			.catch(errorHandler);
	};
};

export const searchSources = params => {
	return dispatch => {
		dispatch({ type: "SOURCES_SEARCH_REQ", payload: params });
		return instance
			.get(`/source/search`, { params: params })
			.then(({ data }) => {
				dispatch({ type: "SOURCES_RES", payload: { ...data } });
			})
			.catch(errorHandler);
	};
};

export const getSource = id => {
	return dispatch => {
		dispatch({ type: "SOURCES_REQ" });
		return instance
			.get(`/source/${id}`)
			.then(({ data }) => {
				dispatch({ type: "SOURCE_RES", payload: data });
			})
			.catch(errorHandler);
	};
};

export const addSource = body => {
	return dispatch => {
		dispatch({ type: "SOURCES_REQ" });
		return instance
			.post(`/source`, body)
			.then(({ data }) => {
				dispatch({ type: "SOURCE_ADDED", payload: data });
			})
			.catch(errorHandler);
	};
};

export const editSource = (body, id) => {
	return dispatch => {
		dispatch({ type: "SOURCES_REQ" });
		return instance
			.put(`/source/${id}`, body)
			.then(({ data }) => {
				dispatch({ type: "SOURCE_EDITED", payload: data });
			})
			.catch(errorHandler);
	};
};

export const deleteSource = id => {
	return dispatch => {
		dispatch({ type: "SOURCES_REQ" });
		return instance
			.delete(`/source/${id}`)
			.then(({ data }) => {
				dispatch({ type: "SOURCE_DELETED", payload: id });
			})
			.catch(errorHandler);
	};
};
