import instance from "../services/api"
import errorHandler from "../services/errorHandler"

export const getUsers = () => {
	return dispatch => {
		dispatch({ type: "USERS_LIST_REQ" })
		return instance
			.get(`/user`)
			.then(({ data }) => {
				dispatch({ type: "USERS_LIST_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const addUser = (body, history) => {
	return dispatch => {
		dispatch({ type: `USERS_ADD_REQ` })
		return instance
			.post(`/user`, body)
			.then(({ data }) => {
				dispatch({ type: `USERS_ADD_RES`, payload: data })
				history.push(`/account/user/${data.id_user}`)
			})
			.catch(errorHandler)
	}
}

export const getUser = id => {
	return dispatch => {
		dispatch({ type: "USERS_PROFILE_REQ" })
		return instance
			.get(`/user/${id}`)
			.then(({ data }) => {
				dispatch({ type: "USERS_PROFILE_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const selectUser = id => {
	return dispatch => {
		dispatch({ type: "USERS_SELECT", payload: id })
	}
}

export const editUser = (body, id) => {
	return dispatch => {
		dispatch({ type: `USERS_PROFILE_EDIT_REQ` })
		return instance
			.put(`/user/${id}`, body)
			.then(({ data }) => {
				return dispatch({ type: `USERS_PROFILE_EDIT_RES`, payload: data })
			})
			.catch(err => {
				errorHandler(err)
				dispatch({ type: `USERS_PROFILE_EDIT_RES`, payload: {} })
			})
	}
}

export const deleteUser = (id, props) => {
	const { history } = props
	return dispatch => {
		dispatch({ type: `USERS_DELETE_REQ` })
		return instance
			.delete(`/user/${id}`)
			.then(({ data }) => {
				history.goBack()
				dispatch({ type: `USERS_DELETE_RES`, payload: id })
			})
			.catch(errorHandler)
	}
}
