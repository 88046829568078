import { connect } from "react-redux";
import GroupManagement from "../../pages/Account/GroupManagement";

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupManagement);
