import React, { Component, Fragment } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Row, Col, Table } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import i18n from '../../locale/i18n';
import { isAdmin } from '../../services/permissions';
import Loading from '../../components/Loading';
import Linkify from 'react-linkify';

class Sources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      source: null,
      language: i18n.language
    };
  }

  componentDidMount() {
    const { Sources, getSources } = this.props;
    const params = { limit: Sources.limit, page: Sources.page };

    if (!Sources.data.length) getSources(params);
  }

  handleChangeKeyword = e => {
    const {
      Sources: { page, limit },
      searchSources
    } = this.props;
    const params = { limit: limit, page: page, keyword: e.target.value };

    searchSources(params);
  };

  handleSubmit = (event, values) => {
    const { source } = this.state;
    const { editSource, addSource } = this.props;
    if (source) {
      editSource(values, source.id_source).then(() => this.setState({ source: null, modal: false }));
    } else {
      addSource(values).then(() => this.setState({ modal: false }));
    }
  };

  handleEdit = source => {
    if (isAdmin()) this.setState({ source: source, modal: true });
  };

  handleDelete = source => {
    const confirmation = window.confirm(`${i18n.t('DELETE_SOURCE_QUESTION')} "${source.code}"?`);

    if (confirmation) {
      this.props.deleteSource(source.id_source);
    }
  };

  toggleModal = () => {
    this.setState(state => {
      return {
        source: null,
        modal: !state.modal
      };
    });
  };

  render() {
    const {
      User: { languages },
      Sources: { data, keyword, loading }
    } = this.props;
    const { language, source, modal } = this.state;

    return (
      <Fragment>
        <div className="app-content-header">
          <h1>Աղբյուրներ և Բառարան</h1>
        </div>
        <div className="app-content-inner ">
          <div className="app-content-filter">
            <Row>
              <Col md={5}>
                <div className="form-group p-0">
                  <Input
                    name="keyword"
                    value={keyword}
                    onChange={this.handleChangeKeyword}
                    placeholder="Որոնում բանալի բառով"
                  />
                </div>
              </Col>
              <Col md={7} className="text-right">
                <fieldset name="lang" className="filter-lang form-group">
                  {languages.map((item, i) => (
                    <div className="form-check" key={i}>
                      <label className="form-check-label ">
                        <Input
                          name="lang"
                          type="radio"
                          value={item.language}
                          onChange={e => this.setState({ language: e.target.value })}
                          checked={item.language === language}
                          className="form-check-input"
                        />
                        <span>{item.short_title}</span>
                      </label>
                    </div>
                  ))}
                </fieldset>
              </Col>
            </Row>
          </div>
          <div className="source-page-content mb-5">
            <div className="content-box">
              <Table className="table-simple">
                <thead>
                  <tr>
                    <th>{i18n.t('CODE')}</th>
                    <th>{i18n.t('source')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={3}>
                        <Loading />
                      </td>
                    </tr>
                  ) : data.length ? (
                    data.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <b className="source-code">{item.code}</b>
                        </td>
                        <td>
                          <Linkify properties={{ target: '_blank' }}>{item.description[language]}</Linkify>
                        </td>
                        <td className="actions-col">
                          <Button color="link" onClick={() => this.handleEdit(item)}>
                            <i className="material-icons">mode_edit</i>
                          </Button>
                          <Button color="link" onClick={() => this.handleDelete(item)}>
                            <i className="material-icons">delete</i>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-muted">
                        <b>Empty</b>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          <Modal isOpen={modal} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>
              {source ? 'Խմբագրել Աղբյուրը' : 'Ավելացնել Նոր Աղբյուր'}
            </ModalHeader>
            <AvForm onValidSubmit={this.handleSubmit} model={source}>
              <ModalBody>
                <AvGroup>
                  <AvInput name="code" required maxLength={5} />
                  <Label className="form-control-label">Կոդ</Label>
                </AvGroup>
                {languages.map((item, i) => (
                  <AvGroup key={item.short_title}>
                    <AvInput
                      className="textarea-autosize"
                      type="textarea"
                      rows="6"
                      name={`description.${item.language}`}
                      required
                    />
                    <Label className="form-control-label">
                      {i18n.t('source')} <b>({item.title})</b>
                    </Label>
                  </AvGroup>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button color="link" className="text-dark" onClick={this.toggleModal}>
                  {i18n.t('CANCEL')}
                </Button>
                <Button color="link">{source ? i18n.t('SAVE') : i18n.t('CREATE')}</Button>
              </ModalFooter>
            </AvForm>
          </Modal>

          {isAdmin() && (
            <div className="add-action">
              <Button color="success" onClick={this.toggleModal}>
                <i className="material-icons">&#xE145;</i>
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default Sources;
