import { connect } from 'react-redux';
import FilteredSections from '../../pages/MyListings/FilteredSections';
import { deleteState, editState } from '../../actions/User';
import { getDataFlat } from '../../actions/Data';

const mapStateToProps = state => {
  return {
    User: state.User
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteState(id, state) {
      dispatch(deleteState(id, state));
    },
    editState(id, state_id, name) {
      return dispatch(editState(id, state_id, name));
    },
    getDataFlat(params) {
      return dispatch(getDataFlat(params));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilteredSections);
