import React from 'react';
import { Button } from 'reactstrap';

const ViewModeToggler = ({ mode, toggle }) => {
  return (
    <div className="layout-mode-actions">
      <Button color="link p-2" className={mode !== 'list' ? 'text-muted' : ''} onClick={() => toggle('list')}>
        <i className="material-icons">view_list</i>
      </Button>
      <Button color="link p-2" className={mode !== 'grid' ? 'text-muted' : ''} onClick={() => toggle('grid')}>
        <i className="material-icons">view_module</i>
      </Button>
    </div>
  );
};

export default ViewModeToggler;
