import React, { Component } from "react"
import { Link } from "react-router-dom"
import { ListGroup, ListGroupItem } from "reactstrap"
import Loading from "../../components/Loading"
import i18n from "../../locale/i18n"

class UserManagement extends Component {
	state = { query: "" }
	componentDidMount() {
		const { Users, getUsers } = this.props
		if (!Users.data.length) getUsers()
	}

	searchUser = e => {
		this.setState({ query: e.target.value })
	}

	render() {
		const { User, Users } = this.props
		const { query } = this.state
		const q = query.toLowerCase()

		return (
			<div>
				<div className="app-content-header">
					<h1>{i18n.t("USER_MANAGEMENT")}</h1>
				</div>
				<div className="app-content-inner content-sm">
					{Users.loading ? (
						<Loading />
					) : (
						<div>
							<div className="form-group w-50">
								<input
									type="text"
									value={query}
									className="form-control"
									placeholder={i18n.t("SEARCH_KEYWORD")}
									onChange={this.searchUser}
								/>
							</div>
							<ListGroup>
								{Users.data
									.filter(u => (u.first_name + " " + u.last_name).toLowerCase().includes(q))
									.map((item, index) => {
										if (item.id_user !== User.data.id_user) {
											return (
												<ListGroupItem key={index}>
													<Link to={`/account/user/${item.id_user}`}>
														<div className="item-value">{`${item.first_name} ${item.last_name}`}</div>
														<i className="material-icons">&#xE315;</i>
													</Link>
												</ListGroupItem>
											)
										} else {
											return null
										}
									})}
							</ListGroup>
						</div>
					)}
				</div>
				<div className="add-action">
					<Link className="btn btn-success" to={`/account/users/add`}>
						<i className="material-icons">&#xE145;</i>
					</Link>
				</div>
			</div>
		)
	}
}

export default UserManagement
