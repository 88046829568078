import React, { Component } from "react"
import { Link } from "react-router-dom"
import DatePicker from "react-datepicker"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import { ListGroup, ListGroupItem, Button, Label, Col } from "reactstrap"
import i18n from "../../locale/i18n"
import classnames from "classnames"

class AddMember extends Component {
	state = {
		bday: new Date()
	}
	componentDidMount() {
		const { Groups: { data }, getGroups } = this.props
		if (!data.length) getGroups()
	}
	handleSubmit = (event, values) => {
		const { addUser } = this.props
		addUser(values)
	}

	handleChange = date => {
		this.setState({ bday: date })
	}

	render() {
		const { Users: { loading }, Groups } = this.props
		return (
			<div>
				<div className="app-content-header">
					<h1>{i18n.t("ADD_USER")}</h1>
				</div>
				<div className="app-content-inner">
					<Link to={`/account/users`} className="btn btn-link btn-back">
						<i className="material-icons">&#xE317;</i>
						{i18n.t("BACK")}
					</Link>
					<div className="content-container content-sm">
						<h3 className="text-center"> </h3>
						<AvForm onValidSubmit={this.handleSubmit} autoComplete="off">
							<ListGroup>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="first_name" className="item-label">
											{i18n.t("first_name")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput
												name="first_name"
												id="first_name"
												type="text"
												placeholder={i18n.t("ADD_DATA")}
												required
											/>
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="last_name" className="item-label">
											{i18n.t("last_name")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="last_name" id="last_name" type="text" placeholder={i18n.t("ADD_DATA")} required />
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="id_group" className="item-label">
											{i18n.t("group")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="id_group" id="id_group" type="select" dir="rtl" required>
												<option value="" disabled>
													{i18n.t("CHOOSE")}
												</option>
												{Groups.data.map(group => (
													<option key={group.id_group} value={group.id_group}>
														{group.title}
													</option>
												))}
											</AvInput>
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="email" className="item-label">
											{i18n.t("email")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="email" id="email" type="email" placeholder={i18n.t("ADD_DATA")} required />
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="password" className="item-label">
											{i18n.t("password")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput
												name="password"
												id="password"
												type="password"
												placeholder={i18n.t("ADD_DATA")}
												required
											/>
										</Col>
									</AvGroup>
								</ListGroupItem>

								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="phone" className="item-label">
											{i18n.t("phone")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="phone" id="phone" type="text" placeholder={i18n.t("ADD_DATA")} required />
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="address" className="item-label">
											{i18n.t("address")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="address" id="address" type="text" placeholder={i18n.t("ADD_DATA")} required />
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="bday" className="item-label">
											{i18n.t("bday")}
										</Label>
										<Col sm={6} className="item-value">
											<DatePicker
												name="bday"
												id="bday"
												selected={this.state.bday}
												onChange={this.handleChange}
												dateFormat="yyyy-MM-dd"

												customInput={<AvInput name="bday" />}
												required
											/>

										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="sex" className="item-label">
											{i18n.t("sex")} *
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="sex" id="sex" type="select" dir="rtl" required>
												<option value="" disabled>
													{i18n.t("CHOOSE")}
												</option>
												<option value="male">{i18n.t("male")}</option>
												<option value="female">{i18n.t("female")}</option>
												<option value="other">{i18n.t("other")}</option>
											</AvInput>
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="position" className="item-label">
											{i18n.t("position")}
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="position" id="position" type="text" placeholder={i18n.t("ADD_DATA")} />
										</Col>
									</AvGroup>
								</ListGroupItem>
								<ListGroupItem>
									<AvGroup row>
										<Label sm={6} for="department" className="item-label">
											{i18n.t("department")}
										</Label>
										<Col sm={6} className="item-value">
											<AvInput name="department" id="department" type="text" placeholder={i18n.t("ADD_DATA")} />
										</Col>
									</AvGroup>
								</ListGroupItem>
							</ListGroup>

							<div className="form-actions">
								<Link className="btn btn-secondary btn-sm" to={`/account/users`}>
									{i18n.t("CANCEL")}
								</Link>
								<Button color="primary" size="sm" className={classnames({ "btn-loading": loading })}>
									{i18n.t("CREATE")}
								</Button>
							</div>
						</AvForm>
					</div>
				</div>
			</div>
		)
	}
}

export default AddMember
