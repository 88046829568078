import React, { Component } from "react"

class Loading extends Component {
	render() {
		return (
			<div className="loading-bar">
				<svg className="loading-bar-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1364 4">
					<title>loading-bar</title>
					<line className="line" x1="0" y1="2.5" x2="1364" y2="2.5" />
				</svg>
			</div>
		)
	}
}

export default Loading
