import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { Button } from "reactstrap"
import ContentNav from "../components/ContentNav"
import { approvalInnerNavItems } from "../constants/"
import i18n from "../locale/i18n"
import instance from "../services/api"
import errorHandler from "../services/errorHandler"

import ApprovalSimple from "../containers/Approvals/ApprovalSimple"
import ApprovaMerged from "../containers/Approvals/ApprovaMerged"

class Approval extends Component {
	state = {
		current: null
	}
	componentDidMount() {
		const { match: { params: { type, id } }, history } = this.props
		instance
			.get(`/${type}/${id}`)
			.then(({ data }) => {
				this.setState({
					current: data
				})
			})
			.catch(err => {
				history.replace("/approvals")
				errorHandler(err)
			})
	}

	render() {
		const { match: { path, params: { type, id } }, history } = this.props
		const { current } = this.state
		const navItems = approvalInnerNavItems(type, id)

		return (
			<div className="app-content-wrapper">
				<div className="app-content content-fluid">
					<div className="app-content-header">
						<h1>{i18n.t("APPROVAL_LIST")}</h1>
					</div>
					<div className="app-content-inner">
						<div className="content-inner-header">
							<h1 className="content-header-title">{current ? current.name[i18n.language] : "----"}</h1>
							<Button onClick={() => history.push(`/approvals/${type}`)} color="link btn-back">
								<i className="material-icons">&#xE317;</i>
								{i18n.t("BACK")}
							</Button>
						</div>
						<ContentNav navItems={navItems} backUrl={"/"} />
						<Switch>
							<Route exact path={`${path}/simple`} component={ApprovalSimple} />
							<Route exact path={`${path}/merged`} component={ApprovaMerged} />
							<Redirect to={navItems[0].url} />
						</Switch>
					</div>
				</div>
			</div>
		)
	}
}

export default Approval
