import { connect } from 'react-redux';
import Village from '../pages/Village';
import {
  getVillage,
  addBookmark,
  getBookmarks,
  deleteBookmark,
  deleteVillage,
  exportVillage
} from '../actions/Villages';
import { getDataFlat } from '../actions/Data';

const mapStateToProps = state => {
  return {
    Data: state.Data,
    User: state.User,
    Villages: state.Villages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVillage(id, year) {
      return dispatch(getVillage(id, year));
    },
    getDataFlat(params) {
      return dispatch(getDataFlat(params));
    },
    addBookmark(body) {
      return dispatch(addBookmark(body));
    },
    getBookmarks() {
      dispatch(getBookmarks());
    },
    deleteBookmark(id) {
      return dispatch(deleteBookmark(id));
    },
    deleteVillage(id) {
      return dispatch(deleteVillage(id));
    },
    exportVillage(id) {
      return dispatch(exportVillage(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Village);
