import { connect } from "react-redux"
import UserManagement from "../../pages/Account/UserManagement"
import { getUsers } from "../../actions/Users"

const mapStateToProps = state => {
	return {
		User: state.User,
		Users: state.Users
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getUsers() {
			dispatch(getUsers())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement)
