import React, { Component } from "react"
import { Link } from "react-router-dom"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import { Button, Label } from "reactstrap"
import { baseUrl } from "../services/helpers"
import Logos from "../components/Logos"
import qs from "qs"

class ForgotPassword extends Component {
	handleValidSubmit = (event, values) => {
		const search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
		if (search && search.token) {
			const params = Object.assign({}, values, search)
			this.props.resetPassword(params, this.props)
		}
	}

	render() {
		return (
			<div className="auth-container">
				<div className="auth-content">
					<Logos />
					<div className="auth-form-wrapper">
						<div className="auth-form-header">
							<img src={baseUrl("img/shield.png")} width="50" alt="shield" />
							<div>
								<h5>Գաղտանաբառի վերականգնում</h5>
							</div>
						</div>
						<AvForm onValidSubmit={this.handleValidSubmit}>
							<AvGroup>
								<AvInput name="password" type="password" errorMessage="Տեքսը բացակայում է կամ ունի սխալ" required />
								<Label className="form-control-label">Գաղտանաբառ</Label>
							</AvGroup>
							<AvGroup>
								<AvInput
									name="confirm_password"
									type="password"
									errorMessage="Տեքսը բացակայում է կամ ունի սխալ"
									validate={{ match: { value: "password" } }}
									required
								/>
								<Label className="form-control-label">Հաստատել Գաղտանաբառը</Label>
							</AvGroup>
							<Link to="/login" className="form-link">
								Մուտք
							</Link>
							<div className="form-actions">
								<Button color="primary" size="lg">
									Ուղարկել
								</Button>
							</div>
						</AvForm>
					</div>
				</div>
			</div>
		)
	}
}

export default ForgotPassword
