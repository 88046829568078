import { connect } from "react-redux"
import VillageAdd from "../pages/VillageAdd"
import { addVillage } from "../actions/Villages"
import { getDataFlat } from "../actions/Data"

const mapStateToProps = state => {
	return {
		User: state.User
	}
}

const mapDispatchToProps = dispatch => {
	return {
		addVillage(body) {
			return dispatch(addVillage(body))
		},
		getDataFlat(params) {
			dispatch(getDataFlat(params))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VillageAdd)
