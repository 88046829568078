const Districts = (
  state = {
    data: [],
    current: null,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case "DISTRICTS_REQ":
      return {
        ...state,
        loading: true
      };
    case "DISTRICTS_RES":
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case "DISTRICT_RES":
      return {
        ...state,
        current: action.payload,
        loading: false
      };
    case "DISTRICT_ADDED":
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      };
    case "DISTRICT_EDITED":
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id_district === action.payload.id_district) {
            return action.payload;
          } else {
            return item;
          }
        }),
        loading: false
      };

    case "DISTRICT_DELETED":
      return {
        ...state,
        data: state.data.filter(i => i.id_district !== action.payload),
        loading: false
      };
    default:
      return state;
  }
};

export default Districts;
