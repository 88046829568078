import React, { Component } from "react"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import { Button, Modal, Label, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import i18n from "../../locale/i18n"

class Bookmarks extends Component {
	state = {
		bookmarkModal: false,
		bookmark: null
	}

	componentDidMount() {
		const { Villages: { bookmarks }, getBookmarks } = this.props
		if (!bookmarks.length) getBookmarks()
	}

	editBookmark = bookmark => {
		this.setState({
			bookmarkModal: true,
			bookmark: bookmark
		})
	}

	deleteBookmark = bookmark => {
		const confirmation = window.confirm(`Do you want to delete bookmark?`)
		if (confirmation) {
			this.props.deleteBookmark(bookmark.id_bookmark)
		}
	}

	toggleBookmarkModal = () => {
		this.setState(state => ({ bookmarkModal: !state.bookmarkModal }))
	}

	handleSubmitSave = (e, { name }) => {
		const { editBookmark } = this.props
		editBookmark({ name }, this.state.bookmark.id_bookmark).then(({ payload }) => {
			this.setState({
				bookmarkModal: false,
				bookmark: null
			})
		})
	}
	render() {
		const { bookmarkModal, bookmark } = this.state
		const { Villages: { bookmarks } } = this.props

		return (
			<div>
				<div className="app-content-header">
					<h1>{i18n.t("BOOKMARKS")}</h1>
				</div>
				<div className="app-content-inner">
					<div className="content-container content-sm">
						<ul className="panel-list">
							{bookmarks.map((bookmark, index) => (
								<li className="list-item" key={index}>
									<div className="item-header">
										<div className="item-title">{bookmark.name}</div>
										<div className="item-actions">
											<Button
												color="link px-2"
												onClick={e => this.editBookmark(bookmark)}
												data-tooltip={i18n.t("EDIT")}>
												<i className="material-icons">mode_edit</i>
											</Button>
											<Button
												color="link px-2"
												onClick={e => this.deleteBookmark(bookmark)}
												data-tooltip={i18n.t("DELETE")}>
												<i className="material-icons">delete</i>
											</Button>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
				<Modal isOpen={bookmarkModal} toggle={this.toggleBookmarkModal} size="sm">
					<ModalHeader toggle={this.toggleBookmarkModal}>{i18n.t("EDIT_BOOKMARK")}</ModalHeader>
					<AvForm onValidSubmit={this.handleSubmitSave} model={bookmark}>
						<ModalBody>
							<AvGroup>
								<AvInput name="name" type="text" required />
								<Label className="form-control-label">{i18n.t("name")}</Label>
							</AvGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="link" className="text-dark" onClick={this.toggleBookmarkModal}>
								{i18n.t("CANCEL")}
							</Button>
							<Button color="link">{i18n.t("SAVE")}</Button>
						</ModalFooter>
					</AvForm>
				</Modal>
			</div>
		)
	}
}

export default Bookmarks
