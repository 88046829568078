import React, { Component, Fragment } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import Loading from '../Loading';
import { summaryTabs } from '../../constants';
import FormsNavItem from './FormsNavItem';

class FormsSidebar extends Component {
  state = {
    showForms: true,
    showSummary: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.showSummary !== state.showSummary) {
      return {
        showSummary: props.showSummary
      };
    }
    return null;
  }

  componentDidUpdate(pp, ps) {
    if (!pp.showSummary && this.props.showSummary) {
      this.setState({ showForms: false });
      setTimeout(() => {
        const el = document.getElementById('nav_collapse_' + this.props.showSummary);
        if (el && !el.parentElement.classList.contains('active')) {
          el.click();
        }
      }, 500);
    }
  }

  toggleForms = () => {
    this.setState((prev) => ({ showForms: !prev.showForms }));
  };

  goToSummary = (e) => {
    e.stopPropagation();
    this.props.history.push('/forms');
  };
  render() {
    const { summary, data } = this.props;
    const { showForms } = this.state;

    return (
      <div className="app-sidebar">
        <div className="forms-sidebar">
          <div className="nav-item">
            <div className="nav-item-header" onClick={this.toggleForms}>
              <strong className="nav-item-title">
                Հարցաշարերի Ցանկ
                {!!summary && (
                  <span className="summary-counter" onClick={this.goToSummary}>
                    {summary.submitted.total} / {summary.formsTotal}
                  </span>
                )}
                <i className="item-icon material-icons">{showForms ? 'arrow_drop_up' : 'arrow_drop_down'}</i>
              </strong>
            </div>
          </div>
          {data ? (
            <Fragment>
              <Nav vertical className={!showForms ? 'd-none' : ''}>
                {data.map((form) => (
                  <NavItem key={form.code}>
                    <NavLink className="nav-item-header" to={`/forms/${form.id_form}`}>
                      <span className="nav-item-title">
                        {form.name}
                        {!!form.drafts && (
                          <i className="material-icons item-icon">{`filter_${
                            form.drafts <= 9 ? form.drafts : '9_plus'
                          }`}</i>
                        )}
                      </span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <Nav vertical>
                {summary && summaryTabs.map((tab) => <FormsNavItem key={tab.key} summary={summary} tab={tab} />)}
              </Nav>
            </Fragment>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

export default FormsSidebar;
