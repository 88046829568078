import instance from '../services/api';
import errorHandler from '../services/errorHandler';
import { saveData } from '../services/helpers';

export const getDataFlat = params => {
  const reqParams = { ...params, coordinates: false };
  return dispatch => {
    dispatch({ type: 'DATA_REQ', payload: reqParams });
    return instance
      .post(`/data/flat`, reqParams)
      .then(({ data }) => {
        dispatch({ type: 'DATA_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'DATA_RES_ERR' });
        return errorHandler(err);
      });
  };
};

export const getDataFlatForMap = params => {
  return dispatch => {
    return instance.post(`/data/flat`, params).catch(errorHandler);
  };
};

export const exportData = params => {
  document.body.classList.add('loading');
  return dispatch => {
    return instance
      .get(`/data/export`, { params, responseType: 'arraybuffer' })
      .then(res => {
        document.body.classList.remove('loading');
        saveData(res);
      })
      .catch(err => {
        document.body.classList.remove('loading');
        return errorHandler(err);
      });
  };
};

export const advanceSearchExport = params => {
  return dispatch => {
    return instance
      .post(`/data/advanceSearchExport`, params, { responseType: 'arraybuffer' })
      .then(res => {
        return saveData(res);
      })
      .catch(errorHandler);
  };
};

export const importData = body => {
  document.body.classList.add('loading');
  return dispatch => {
    return instance
      .post(`/data/import`, body)
      .then(({ data }) => {
        return Promise.resolve(data);
      })
      .catch(err => {
        return errorHandler(err);
      });
  };
};

export const dataQuickSearch = params => {
  return dispatch => {
    dispatch({ type: 'DATA_REQ', payload: params });
    return instance
      .post(`/data/quickSearch`, params)
      .then(({ data }) => {
        dispatch({ type: 'DATA_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'DATA_RES_ERR' });
        return errorHandler(err);
      });
  };
};

export const dataQuickSearchForMap = params => {
  return dispatch => {
    return instance.post(`/data/quickSearch`, params).catch(errorHandler);
  };
};

export const advanceSearch = params => {
  return dispatch => {
    dispatch({ type: 'DATA_ADV_SEARCH_REQ', payload: params });
    localStorage.setItem('searchParams', JSON.stringify(params.params));
    if (params.keyword_source !== 'monument') delete params.keyword_source;
    return instance
      .post(`/data/advanceSearch`, params)
      .then(({ data }) => {
        dispatch({ type: 'DATA_ADV_SEARCH_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'DATA_RES_ERR' });
        return errorHandler(err);
      });
  };
};

export const advanceSearchForMap = params => {
  return dispatch => {
    if (params.keyword_source !== 'monument') delete params.keyword_source;
    return instance.post(`/data/advanceSearch`, params).catch(errorHandler);
  };
};

export const advanceSearchSave = params => {
  return dispatch => {
    return instance
      .post(`/data/advanceSearchSave`, params)
      .then(({ data }) => {
        dispatch({ type: 'DATA_ADV_SEARCH_SAVE_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'DATA_RES_ERR' });
        return errorHandler(err);
      });
  };
};

export const getAdvanceSearchList = () => {
  return dispatch => {
    return instance
      .get(`/data/advanceSearchSaveList`)
      .then(({ data }) => {
        dispatch({ type: 'DATA_ADV_SEARCH_LIST_RES', payload: data });
      })
      .catch(err => {
        dispatch({ type: 'DATA_RES_ERR' });
        return errorHandler(err);
      });
  };
};
