import React from 'react';
import moment from 'moment';

const FormsDate = ({ date, preffix }) => {
  if (!date) return null;
  return (
    <div className="text-capitalize content-header-date">
      {preffix} {moment(date).format('DD MMMM YYYY')}
    </div>
  );
};

export default FormsDate;
