import React, { Component, Fragment } from "react";
import { FormGroup, Input } from "reactstrap";
import i18n from "../locale/i18n";
import { filterList } from "../services/helpers";

class VillagesTopFilter extends Component {
  constructor(props) {
    super(props);
    this.filterDistricts = filterList("id_state");
  }

  handleChangeParam = ({ target: { name, value } }) => {
    const { Data, getDataFlat } = this.props;
    let newParam = { [name]: +value };
    if (name === "id_state") {
      newParam = { ...newParam, id_district: 0 };
    }
    getDataFlat({ ...Data.params, ...newParam, page: 1, recent_state: false, id_user_state: null });
  };

  render() {
    const {
      User: {
        regions: { states, districts }
      },
      Data: {
        params: { id_state, id_district }
      }
    } = this.props;
    const stateVal = id_state ? id_state : states.length === 1 ? states[0].id : "";
    const districtsArr = +stateVal ? this.filterDistricts(+stateVal, districts) : [];

    return (
      <Fragment>
        <FormGroup>
          <Input type="select" name="id_state" onChange={this.handleChangeParam} value={stateVal}>
            {states.length > 1 ? <option value={0}>{i18n.t("state")}</option> : null}
            {states.map((s) => (
              <option value={s.id} key={s.id}>
                {s.name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Input
            type="select"
            name="id_district"
            onChange={this.handleChangeParam}
            value={id_district}
            disabled={Boolean(!stateVal || stateVal === "0")}>
            {districtsArr.length > 1 || +stateVal ? <option value="">{i18n.t("district")}</option> : null}
            {districtsArr.map((d) => (
              <option value={d.id} key={d.id}>
                {d.name}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Fragment>
    );
  }
}
export default VillagesTopFilter;
