import React, { Component, Fragment } from 'react';

import { Link } from 'react-router-dom';
import { Button, UncontrolledDropdown as Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import i18n from '../locale/i18n';
import Pagination from '../components/Pagination';
import AppSidebar from '../components/AppSidebar';

import Loading from '../components/Loading';
import LoadingBar from '../components/LoadingBar';
import QuickSearchTag from '../components/QuickSearchTag';
import Total from '../components/Total';
import VillagesTopFilter from '../components/VillagesTopFilter';
import VillagesActions from '../components/VillagesActions';
import { isAdmin } from '../services/permissions';

import { toast } from 'react-toastify';
import DataTableScroll from '../components/DataTable/DataTableScroll';
import DataTableFixed from '../components/DataTable/DataTableFixed';

class Villages extends Component {
  state = {
    hovered: '',
    filterdsVisible: false,
    opened: false,
    checked: []
  };

  componentDidMount() {
    const {
      Data: { data, params },
      getDataFlat
    } = this.props;
    let reqParams = { lang: i18n.language, recent_state: true };
    if (!params.recent_state) {
      reqParams = { ...reqParams, ...params, lang: i18n.language, recent_state: false };
    }
    if (!data.length) getDataFlat(reqParams);
  }

  getSelectedCategories = (tree, arr = [], key) => {
    tree.forEach(item => {
      if (item.is_checked === 2) {
        arr.push(item[key]);
      }
      if (item.children) {
        this.getSelectedCategories(item.children, arr, key);
      }
    });
    return arr;
  };

  handleCompare = () => {
    const { Categories, history } = this.props;
    const categories = this.getSelectedCategories(Categories.data, [], 'id_category');
    const { checked } = this.state;

    const params = {
      villages: checked.map(i => ({ id_village: i })),
      categories: categories
    };
    localStorage.setItem('compare', JSON.stringify(params));
    history.push({ pathname: '/compare' });
  };

  isAllChecked = arr => {
    const {
      Data: { data }
    } = this.props;
    let n = 0;
    data.forEach(d => {
      if (arr.includes(d.id_village)) {
        n++;
      }
    });
    return !n ? (arr.length ? 1 : 0) : n === data.length ? 2 : 1;
  };

  toggleVillage = (isChecked, id) => {
    this.setState(state => {
      const arr = isChecked ? [...state.checked, id] : state.checked.filter(i => i !== id);
      return {
        checked: arr
      };
    });
  };

  toggleAllVillages = isChecked => {
    const {
      Data: { data }
    } = this.props;
    this.setState(state => ({ checked: isChecked ? data.map(i => i.id_village) : [] }));
  };

  handlePageChange = pageNumber => {
    const {
      Data: { params },
      getDataFlat
    } = this.props;
    getDataFlat({ ...params, page: pageNumber.selected + 1, recent_state: false, id_user_state: null });
  };

  updateData = categories => {
    const {
      Data: { params },
      getDataFlat
    } = this.props;
    getDataFlat({ ...params, categories, recent_state: false, id_user_state: null });
  };

  onCategoryToggle = (category, checked) => {
    this.props.toggleCategory(category, checked).then(() => {
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        const slugs = this.getSelectedCategories(this.props.Categories.data, [], 'slug');
        this.updateData(slugs);
      }, 1500);
    });
  };

  toggleFilters = () => this.setState(state => ({ filterdsVisible: !state.filterdsVisible }));

  handleSort = (order_column, order_by) => {
    const {
      getDataFlat,
      Data: { params }
    } = this.props;
    if (params.order_column === order_column && params.order_by === order_by) {
      order_column = '';
    }
    getDataFlat({ ...params, order_column, order_by });
  };

  toggleColumn = key => {
    const {
      getDataFlat,
      Data: { params }
    } = this.props;
    const cols = params.hiddenColums;
    const hiddenColums = cols.includes(key) ? cols.filter(c => c !== key) : [...cols, key];

    getDataFlat({ ...params, hiddenColums });
  };

  goToMouments = (e, id) => {
    e.stopPropagation();
    this.props.history.push(`/village/${id}/monuments`);
  };

  handleImportFile = ({ target: { files } }) => {
    this.setState({ loadingOvelay: true });
    document.body.classList.remove('loading');
    if (files.length) {
      const formData = new FormData();
      formData.append('import', files[0]);
      this.props
        .importData(formData)
        .then(data => {
          this.setState({ loadingOvelay: false });
          document.body.classList.remove('loading');
          toast.success('Import successfuly completed!');
          this.props.getDataFlat({ recent_state: true });
        })
        .catch(() => {
          toast.error('Import faild!');
          this.setState({ loadingOvelay: false });
          document.body.classList.remove('loading');
        });
    }
  };

  toggleSidebar = () => {
    this.setState({ opened: !this.state.opened });
  };
  setHovered = index => {
    this.setState({ hovered: index });
  };
  render() {
    const { Data, Columns, filterdCategories, history } = this.props;
    const { checked, filterdsVisible, loadingOvelay, opened, hovered } = this.state;

    return (
      <div className={`app-content-wrapper`}>
        <AppSidebar {...this.props} opened={opened} onCategoryToggle={this.onCategoryToggle} />
        {opened && <div className="sidebar-backdrop animated fadeIn" onClick={this.toggleSidebar} />}
        <div className="app-content content-fluid">
          <div className="app-content-header">
            <Button color="primary" className="sidebar-toggler" onClick={this.toggleSidebar}>
              <i className="material-icons">{opened ? 'format_indent_decrease' : 'format_indent_increase'}</i>
            </Button>
            <VillagesTopFilter {...this.props} />
          </div>
          <div className="app-content-inner pb-5 mb-5">
            <div className="data-filters-container">
              <div className="filters-secodary">
                <div className="filter-search fluid">
                  <QuickSearchTag {...this.props} />
                </div>
                <VillagesActions
                  {...this.props}
                  checked={checked}
                  toggleFilters={this.toggleFilters}
                  filterdsVisible={filterdsVisible}
                  handleImportFile={this.handleImportFile}
                  handleCompare={this.handleCompare}
                />
              </div>
              {Boolean(filterdCategories.length && filterdsVisible) && (
                <div className="primary-filters">
                  <ul className="filtered-categories">
                    {filterdCategories.map((item, i) => (
                      <Dropdown tag="li" key={i}>
                        <DropdownToggle color="light" caret>
                          {item.name[i18n.language]}
                        </DropdownToggle>
                        <DropdownMenu>
                          <div className="dropdown-menu-scroll">
                            {item.children.map((child, j) => (
                              <div key={j} className="dropdown-item pl-0">
                                <Button color="link text-dark" onClick={e => this.onCategoryToggle(child, false)}>
                                  <i className="material-icons m-0">close</i>
                                </Button>
                                <span className="dropdown-item-title">{child.name[i18n.language]}</span>
                              </div>
                            ))}
                          </div>
                        </DropdownMenu>
                      </Dropdown>
                    ))}
                  </ul>
                  <Button color="link text-dark" onClick={this.toggleFilters}>
                    <i className="material-icons">close</i>
                  </Button>
                </div>
              )}
            </div>

            {Data.data.length ? (
              <Fragment>
                <Pagination
                  total={Data.total}
                  limit={Data.params.limit}
                  page={Data.params.page}
                  onPageChange={this.handlePageChange}
                />
                <div className="data-table-wrapper" onMouseLeave={() => this.setState({ hovered: '' })}>
                  <div className="table-fixed">
                    <Total
                      {...{
                        total: Data.total,
                        page: Data.params.page,
                        limit: Data.params.limit
                      }}
                    />
                    <DataTableFixed
                      data={Data.data}
                      params={Data.params}
                      columns={Columns.fixed}
                      isAllChecked={this.isAllChecked}
                      toggleAllVillages={this.toggleAllVillages}
                      toggleColumn={this.toggleColumn}
                      handleSort={this.handleSort}
                      toggleVillage={this.toggleVillage}
                      goToMouments={this.goToMouments}
                      setHovered={this.setHovered}
                      checked={checked}
                      hovered={hovered}
                      history={history}
                    />
                  </div>
                  <div className="table-scroll">
                    <DataTableScroll
                      data={Data.data}
                      params={Data.params}
                      columns={Columns.others}
                      toggleColumn={this.toggleColumn}
                      handleSort={this.handleSort}
                      toggleVillage={this.toggleVillage}
                      goToMouments={this.goToMouments}
                      setHovered={this.setHovered}
                      checked={checked}
                      hovered={hovered}
                      history={history}
                    />
                  </div>
                </div>
                <Pagination
                  total={Data.total}
                  limit={Data.params.limit}
                  page={Data.params.page}
                  onPageChange={this.handlePageChange}
                />
              </Fragment>
            ) : Data.loading ? (
              <Loading />
            ) : null}
          </div>
        </div>
        {Boolean(Data.data.length && Data.loading) && <LoadingBar />}
        {isAdmin() && (
          <div className="add-action">
            <Link className="btn btn-success" to={'villages/add'}>
              <i className="material-icons">&#xE145;</i>
            </Link>
          </div>
        )}
        {loadingOvelay && <Loading overlay />}
      </div>
    );
  }
}

export default Villages;
