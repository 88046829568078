import { toast } from "react-toastify";

const errorHandler = err => {
	if (err.response && err.response.data) {
		const { errors } = err.response.data;
		if (errors) {
			for (let i = 0; i < errors.length; i++) {
				toast.error(errors[i].message);
			}
		} else {
			toast.error(err.response.data.message || err.response.statusText);
		}
	}
	return Promise.reject(err);
};

export default errorHandler;
