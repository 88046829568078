import React, { Component } from "react";
import HeaderCol from "./HeaderCol";
import RowRenderer from "./RowRenderer";

class DataTableScroll extends Component {
  colWidth = 310;
  itemBuffer = 5;
  scroller = null;
  old = {
    first: null,
    last: null
  };
  state = {
    minWidth: 0,
    items: [],
    paddingLeft: 0
  };

  componentDidMount() {
    const container = this.refs.container;
    this.scroller = container.parentElement;
    this.setState({ minWidth: container.offsetWidth });
    this.updateState(this.scroller);
    this.scroller.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this.scroller.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.columns) !== JSON.stringify(this.props.columns)) {
      this.scroller.scrollTo(0, 0);
      this.updateState(this.scroller);
    }
  }

  handleScroll = (e) => {
    this.updateState(e.target);
  };

  needUpdate = (f, l) => {
    return this.old.first !== f && this.old.last !== l;
  };

  updateState = (scroller) => {
    const { columns } = this.props;
    const { scrollLeft, offsetWidth } = scroller;
    const firstIndex = Math.max(0, Math.floor(scrollLeft / this.colWidth) - this.itemBuffer);
    const lastIndex =
      Math.min(columns.length, Math.ceil((scrollLeft + offsetWidth) / this.colWidth) + this.itemBuffer) - 1;
    const visibleItems = offsetWidth > -1 ? columns.slice(firstIndex, lastIndex + 1) : [];

    if (this.needUpdate(firstIndex, lastIndex)) {
      this.setState({
        items: visibleItems,
        style: { paddingLeft: firstIndex * this.colWidth + "px", width: columns.length * this.colWidth + "px" }
      });
      this.old = { first: firstIndex, last: lastIndex };
    }
  };

  render() {
    const {
      toggleColumn,
      handleSort,
      params,
      data,
      toggleVillage,
      goToMouments,
      setHovered,
      checked,
      hovered,
      history
    } = this.props;
    const { items, style, minWidth } = this.state;
    return (
      <div className="data-table col-width" ref="container" style={style}>
        <table style={{ minWidth: minWidth + "px" }}>
          <thead>
            <tr>
              {items.map((col) => (
                <HeaderCol
                  col={col}
                  key={col.key}
                  toggleColumn={toggleColumn}
                  handleSort={handleSort}
                  params={params}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            <RowRenderer
              items={data}
              columns={items}
              isfixed={false}
              checked={checked}
              hovered={hovered}
              history={history}
              toggleVillage={toggleVillage}
              goToMouments={goToMouments}
              setHovered={setHovered}
            />
          </tbody>
        </table>
      </div>
    );
  }
}

export default DataTableScroll;
