import React from 'react';
import { Button } from 'reactstrap';

const FormDrafts = ({ drafts, onSlect, place_id, handleReset, params }) => {
  if (!drafts.length) return null;

  return (
    <div className="forms-alert mb-2">
      <i className="alert-icon material-icons">warning</i>
      <div>
        Դուք ունեք պահպանված նախագծեր`
        <ul>
          {drafts.map((draft) => (
            <li className="list-item" key={draft.id_place}>
              <Button
                color="link"
                className={+place_id === +draft.id_place && params.id_place ? 'active' : ''}
                onClick={() => onSlect(draft)}>
                {draft.place_name}
              </Button>
            </li>
          ))}
        </ul>
      </div>
      <Button color="primary" onClick={handleReset}>
        Սկսել նորը{' '}
      </Button>
    </div>
  );
};

export default FormDrafts;
