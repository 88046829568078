import React, { Component } from "react"
import { Button, FormGroup, Input } from "reactstrap"
import i18n from "../locale/i18n"

class CompareTable extends Component {
	render() {
		const {
			rows,
			category,
			villages,
			columns,
			handleChangeYear,
			handleChangeCategory,
			duplicateVillage,
			excludeVillage
		} = this.props

		return (
			<table className="table table-simple village compare">
				<thead>
					<tr>
						<th>
							<FormGroup>
								<Input type="select" value={category} onChange={handleChangeCategory}>
									{columns.map((column, i) => (
										<option key={i} value={column.id_category}>
											{column.name[i18n.language]}
										</option>
									))}
								</Input>
							</FormGroup>
							{i18n.t("CATEGORY")}
						</th>
						{villages.map((village, i) => (
							<th key={i}>
								<FormGroup>
									<Input type="select" value={village.archive_year || ""} onChange={e => handleChangeYear(e, i)}>
										<option value="">----</option>
										{village.archive_years.map((year, i) => (
											<option key={i} value={year}>
												{year}
											</option>
										))}
									</Input>
								</FormGroup>
								<div className="header-actions">
									{village.name[i18n.language]}
									<div className="actions">
										<Button
											color="link mr-2"
											onClick={() => duplicateVillage(village.id_village, i)}
											data-tooltip={i18n.t("COPY")}>
											<i className="material-icons">filter_none</i>
										</Button>
										{villages.length > 1 && (
											<Button color="link" onClick={() => excludeVillage(i)} data-tooltip={i18n.t("CLOSE")}>
												<i className="material-icons">close</i>
											</Button>
										)}
									</div>
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map(item => {
						return (
							<tr key={`item_${item.id_category}`}>
								<td className="category-col">
									{item.parent && <span className="parent">{item.parent.name[i18n.language]}</span>}
									{item.name[i18n.language]}
								</td>
								{villages.map((village, i) => {
									const data = village.data.find(v => v.category.id_category === item.id_category)
									const content = data && data.data[i18n.language]
									return (
										<td key={i} className="content-col">
											{content && content.content ? (
												<div>
													{content.content}
													<div className="mt-1">
														{content.sources &&
															content.sources.map((source, i) => (
																<b className="source-code" key={i}>
																	{source.code}
																</b>
															))}
														<b className="text-muted">{content.archive_year}</b>
													</div>
												</div>
											) : (
												<div>-</div>
											)}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
		)
	}
}

export default CompareTable
