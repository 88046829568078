import React, { Component, Fragment } from 'react';
import { Button, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import FormsTopActions from '../../components/Forms/FormsTopActions';
import FormsAlert from '../../components/Forms/FormsAlert';
import FormsDate from '../../components/Forms/FormsDate';
import FormField from '../../components/Forms/FormField';
import Loading from '../../components/Loading';

import { findById } from '../../services/helpers';
import instance from '../../services/api';

class FormRejected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      loading: true,
      currentData: null,
      viewMode: window.localStorage.getItem('viewMode') || 'grid'
    };
    this.findDistrict = findById();
    this.findState = findById();
    this.type = props.match.params.type;
  }

  componentDidMount() {
    this.getForm(this.props);
  }

  componentDidUpdate(prevProps, ps) {
    const np = this.props.match.params;
    const pp = prevProps.match.params;
    if (ps.currentData) {
      if (np.id_form !== pp.id_form) {
        this.setState({ loading: true });
        this.getForm(this.props);
      } else if (np.id_place && np.id_place !== pp.id_place) {
        this.setFormData(this.props.Forms.current, np.id_place);
      }
    }
  }

  setFormData = (data, id_place) => {
    const currentData = data.sentback.find((i) => +i.id_place === +id_place);
    if (currentData) {
      const { current } = this.props.Forms;
      const initialData = current.fields.reduce(
        (acc, i) => ({
          ...acc,
          [i.name]: { content: '', id_category: i.name, isValid: true, rejected: false }
        }),
        {}
      );
      const formData = currentData.data_json.reduce(
        (acc, i) => ({
          ...acc,
          [i.id_category]: { ...i, isValid: !i.rejected }
        }),
        initialData
      );
      this.setState({ formData, currentData, loading: false });
    } else {
      this.props.history.push('/forms');
    }
  };

  getForm(props) {
    const {
      match,
      history,
      Forms: { current }
    } = props;
    const { id_form, id_place } = match.params;
    if (current && +current.id_form === +id_form) {
      this.setFormData(current, id_place);
    } else {
      this.props
        .getForm(id_form)
        .then(({ payload }) => {
          this.setFormData(payload, id_place);
        })
        .catch(() => history.push('/forms'));
    }
  }

  handleSubmit = (e, values) => {
    const { formData, currentData } = this.state;
    const data_json = Object.values(formData);
    if (!!data_json.find((i) => !i.isValid)) {
      window.scrollTo(0, 0);
      return toast.error('Որոշ տվյալներ բացակայում են կամ սխալ են։');
    } else {
      this.setState({ loading: true, rejetModal: false });
      instance
        .post('/forms/submit', {
          ...values,
          ...currentData,
          data_json: data_json.map((i) => {
            delete i.rejected;
            return i;
          })
        })
        .then(() => {
          toast.success('Նախագիծը հաջողությամբ ուղարկված է');
          this.props.updateForms();
          this.props.history.push('/forms');
        });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const { name, value, validity } = e.target;
    this.changed = true;
    this.setState(({ formData }) => {
      return {
        formData: { ...formData, [name]: { ...formData[name], isValid: validity.valid, content: value } }
      };
    });
  };

  render() {
    const { formData, viewMode, loading, currentData } = this.state;
    const {
      history,

      Forms: { current },
      Regions: { states, districts }
    } = this.props;
    const isGrid = viewMode === 'grid';
    const district = currentData && districts.length ? this.findDistrict(currentData.id_district, districts) : null;
    const state = currentData && states.length ? this.findState(currentData.id_state, states) : null;

    return (
      <div className="app-content forms-page-content">
        <div className="app-content-header">
          <div className="form-group">
            <div className="form-control">{state && state.name}</div>
          </div>
          <div className="form-group">
            <div className="form-control">{district && district.name}</div>
          </div>
        </div>
        <div className="app-content-inner">
          <h1 className="forms-page-title">Հետ ուղարկված հարցաշարեր</h1>
          {!loading ? (
            <Fragment>
              <FormsAlert>{currentData.reject_message}</FormsAlert>
              <div className="content-box mt-3">
                <div className="content-inner-header">
                  <FormsTopActions history={history} mode={viewMode} toggle={this.toggleMode} />
                  <h1 className="content-header-title">{current.name}</h1>
                  <div className="content-header-input-wrapper">
                    <div className="form-control content-header-input">{currentData.place_name}</div>
                    <FormsDate date={currentData.submitted_date} preffix="Ուղարկվել է՝" />
                  </div>
                </div>
                <Row>
                  {current.fields.map((field) => (
                    <FormField
                      field={field}
                      key={field.name}
                      formData={formData}
                      onChange={this.handleChange}
                      showInputs={true}
                      isGrid={isGrid}
                    />
                  ))}
                </Row>
                <div className="form-actions mb-2">
                  <Button color="success" id="formSubmitBtn" onClick={this.handleSubmit}>
                    Ուղարկել
                  </Button>
                </div>
              </div>
            </Fragment>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

export default FormRejected;
