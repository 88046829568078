import React, { Component } from 'react';
import moment from 'moment';
import { ListGroup, ListGroupItem, Button, Label, Col } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { clone } from '../services/helpers';
import DatePicker from 'react-datepicker';
import i18n from '../locale/i18n';
import classnames from 'classnames';

class ProfileEdit extends Component {
  state = {
    data: null,
    isExpanded: ''
  };

  componentDidMount() {
    const data = clone(this.props.data);
    for (let key in data) {
      let val = data[key];
      if (val === null) data[key] = '';
    }
    this.setState({
      data: data,
      isExpanded: data.deactivation_from && data.deactivation_to ? 'deactivate' : ''
    });
  }

  handleChange = (date, key) => {
    this.setState(prev => {
      return {
        data: {
          ...prev.data,
          [key]: date
        }
      };
    });
  };

  handleSubmit = (event, values) => {
    const { handleSubmit } = this.props;
    if (!values.password) delete values.password;
    if (!Boolean(values.deactivation_from)) values.deactivation_from = null;
    if (!Boolean(values.deactivation_to)) values.deactivation_to = null;

    handleSubmit(values, this.state.data.id_user);
  };

  toggleCollapse = item => {
    this.setState(prev => {
      return {
        isExpanded: item === prev.isExpanded ? '' : item
      };
    });
  };

  setActiveDate = () => {
    this.setState(prev => {
      return {
        data: {
          ...prev.data,
          deactivation_from: moment().subtract(1, 'days'),
          deactivation_to: moment().subtract(1, 'days')
        }
      };
    });
  };

  handleDelete = id => {
    const confirmation = window.confirm(`${i18n.t('DELETE_USER_QUESTION')}?`);
    if (confirmation) {
      this.props.handleDelete(id);
    }
  };

  render() {
    const { data, isExpanded } = this.state;
    const { groups, loading, isOwner } = this.props;

    if (!data) return null;

    return (
      <AvForm onValidSubmit={this.handleSubmit} model={data}>
        <ListGroup>
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="first_name" className="item-label">
                {i18n.t('first_name')} *
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="first_name" id="first_name" type="text" placeholder={i18n.t('ADD_DATA')} required />
              </Col>
            </AvGroup>
          </ListGroupItem>
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="last_name" className="item-label">
                {i18n.t('last_name')} *
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="last_name" id="last_name" type="text" placeholder={i18n.t('ADD_DATA')} required />
              </Col>
            </AvGroup>
          </ListGroupItem>
          {!isOwner && (
            <ListGroupItem>
              <AvGroup row>
                <Label sm={6} for="group" className="item-label">
                  {i18n.t('group')} *
                </Label>
                <Col sm={6} className="item-value">
                  <AvInput name="id_group" id="group" type="select" dir="rtl" required>
                    <option value="" disabled>
                      {i18n.t('CHOOSE')}
                    </option>
                    {groups.data.map(group => (
                      <option key={group.id_group} value={group.id_group}>
                        {group.title}
                      </option>
                    ))}
                  </AvInput>
                </Col>
              </AvGroup>
            </ListGroupItem>
          )}
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="email" className="item-label">
                {i18n.t('email')} *
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="email" id="email" type="email" placeholder={i18n.t('ADD_DATA')} required />
              </Col>
            </AvGroup>
          </ListGroupItem>
          {/*{isOwner && (*/}
            <ListGroupItem>
              <AvGroup row>
                <Label sm={6} for="password" className="item-label">
                  {i18n.t('password')} *
                </Label>
                <Col sm={6} className="item-value">
                  <AvInput name="password" id="password" type="password"  />
                </Col>
              </AvGroup>
            </ListGroupItem>
          {/*)}*/}
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="phone" className="item-label">
                {i18n.t('phone')} *
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="phone" id="phone" type="text" placeholder={i18n.t('ADD_DATA')} required />
              </Col>
            </AvGroup>
          </ListGroupItem>
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="address" className="item-label">
                {i18n.t('address')}
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="address" id="address" type="text" placeholder={i18n.t('ADD_DATA')} />
              </Col>
            </AvGroup>
          </ListGroupItem>
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="bday" className="item-label">
                {i18n.t('bday')}
              </Label>
              <Col sm={6} className="item-value">
                <DatePicker
                  id="bday"
                  name="bday"
                  selected={data.bday ? new Date(data.bday) : new Date()}
                  onChange={date => this.handleChange(date, 'bday')}
                  customInput={<AvInput name="bday" />}
                  dateFormat="yyyy-MM-dd"
                  required
                />
              </Col>
            </AvGroup>
          </ListGroupItem>
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="position" className="item-label">
                {i18n.t('position')}
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="position" id="position" type="text" placeholder={i18n.t('ADD_DATA')} />
              </Col>
            </AvGroup>
          </ListGroupItem>
          <ListGroupItem>
            <AvGroup row>
              <Label sm={6} for="department" className="item-label">
                {i18n.t('department')}
              </Label>
              <Col sm={6} className="item-value">
                <AvInput name="department" id="department" type="text" placeholder={i18n.t('ADD_DATA')} />
              </Col>
            </AvGroup>
          </ListGroupItem>
        </ListGroup>

        <ul className="form-collapse">
          {!isOwner && (
            <li className="form-collapse-item">
              <div className="form-collapse-header">
                <label className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="deactivatePage"
                    name="formActionRadio"
                    checked={isExpanded === 'deactivate'}
                    className="custom-control-input"
                    onChange={e => this.toggleCollapse('deactivate')}
                  />
                  <span className="custom-control-label">{i18n.t('TEMPORARY_DEACTIVATION')}</span>
                </label>
              </div>
              {isExpanded === 'deactivate' && (
                <div className="form-collapse-body">
                  <p>{i18n.t('PROFILE_DEACTIVATION_HINT')}</p>
                  <div className="daterange-wrapper">
                    <AvGroup className="d-inline-block mr-3">
                      <DatePicker
                        id="deactivation_from"
                        name="deactivation_from"
                        endDate={data.deactivation_to ? moment(data.deactivation_to) : moment()}
                        startDate={data.deactivation_from ? moment(data.deactivation_from) : moment()}
                        selected={data.deactivation_from ? moment(data.deactivation_from) : null}
                        onChange={date => this.handleChange(date, 'deactivation_from')}
                        customInput={<AvInput name="deactivation_from" />}
                        dateFormat="YYYY-MM-DD"
                        placeholderText={i18n.t('START')}
                        selectsDtart
                        required={Boolean(data.deactivation_to)}
                      />
                    </AvGroup>
                    <AvGroup className="d-inline-block">
                      <DatePicker
                        id="deactivation_to"
                        name="deactivation_to"
                        minDate={data.deactivation_from ? moment(data.deactivation_from) : moment()}
                        endDate={data.deactivation_to ? moment(data.deactivation_to) : moment()}
                        startDate={data.deactivation_from ? moment(data.deactivation_from) : moment()}
                        selected={data.deactivation_to ? moment(data.deactivation_to) : null}
                        onChange={date => this.handleChange(date, 'deactivation_to')}
                        customInput={<AvInput name="deactivation_to" />}
                        dateFormat="YYYY-MM-DD"
                        placeholderText={i18n.t('END')}
                        selectsEnd
                        required={Boolean(data.deactivation_from)}
                      />
                    </AvGroup>
                    {/* <Button color="link" onClick={this.setActiveDate}>
											Մաքրել
										</Button> */}
                  </div>
                </div>
              )}
            </li>
          )}
          <li className="form-collapse-item">
            <div className="form-collapse-header">
              <label className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  id="deletePage"
                  name="formActionRadio"
                  className="custom-control-input"
                  checked={isExpanded === 'delete'}
                  onChange={e => this.toggleCollapse('delete')}
                />
                <span className="custom-control-label">{i18n.t('DELETE_PROFILE')}</span>
              </label>
            </div>
            {isExpanded === 'delete' && (
              <div className="form-collapse-body">
                <p>{i18n.t('PROFILE_DELETE_HINT')}</p>
                <Button color="danger" onClick={() => this.handleDelete(data.id_user)}>
                  {i18n.t('REMOVE')}
                </Button>
              </div>
            )}
          </li>
        </ul>

        <div className="form-actions">
          <Button color="secondary" size="sm" onClick={this.props.handleReject}>
            {i18n.t('CANCEL')}
          </Button>
          <Button color="primary" size="sm" className={classnames({ 'btn-loading': loading })}>
            {i18n.t('SAVE')}
          </Button>
        </div>
      </AvForm>
    );
  }
}

export default ProfileEdit;
