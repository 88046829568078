import React, { Component } from "react"
import { Button } from "reactstrap"
import ProfileInfo from "../../components/ProfileInfo"
import ProfileEdit from "../../components/ProfileEdit"
import Loading from "../../components/Loading"
import i18n from "../../locale/i18n"
import { toast } from "react-toastify"

class UserProfile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			editMode: false,
			invitation: null
		}
	}

	componentDidMount() {
		const {
			Users,
			Groups,
			Invitations,
			getInvitations,
			getUser,
			selectUser,
			getGroups,
			match: { params: { userId } }
		} = this.props

		if (Users.data.find(item => item.id_user === userId)) {
			selectUser(userId)
		} else {
			getUser(userId)
		}

		if (!Groups.data.length) getGroups()
		if (!Invitations.data.length) getInvitations()
	}

	toggleEditMode = () => {
		this.setState({ editMode: !this.state.editMode })
	}

	handleEditProfile = (values, id) => {
		const { editUser } = this.props

		editUser(values, id).then(data => {
			toast.success("Profile successfuly updated!")
			this.setState({ editMode: false })
		})
	}
	hasContainer = () => {
		const path = this.props.location.pathname
		return path.indexOf("/account") !== -1
	}

	render() {
		const { Users: { currentUser, loading }, Groups, Invitations, deleteUser } = this.props
		const { editMode } = this.state

		return (
			<div className={!this.hasContainer() ? "app-content content-fluid" : ""}>
				<div className="app-content-header">
					<h1>{i18n.t("USER_MANAGEMENT")}</h1>
				</div>
				<div className="app-content-inner">
					<Button onClick={() => this.props.history.goBack()} className="btn btn-link btn-back">
						<i className="material-icons">&#xE317;</i>
						{i18n.t("BACK")}
					</Button>
					{currentUser ? (
						<div className="content-container content-sm">
							<h3 className="text-center">{`${currentUser.first_name} ${currentUser.last_name}`}</h3>
							{editMode ? (
								<ProfileEdit
									data={currentUser}
									handleSubmit={this.handleEditProfile}
									handleReject={this.toggleEditMode}
									handleDelete={deleteUser}
									loading={loading}
									groups={Groups}
								/>
							) : (
								<div>
									<div className="edit-action">
										<Button color="link" size="sm" onClick={this.toggleEditMode}>
											{i18n.t("EDIT")}
										</Button>
									</div>
									<ProfileInfo
										data={currentUser}
										invitation={Invitations.data.find(i => i.id_invitation === currentUser.id_invitation)}
									/>
								</div>
							)}
						</div>
					) : loading ? (
						<Loading />
					) : (
						<div>
							<h2 className="text-secondary text-center p-5">Not Found</h2>
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default UserProfile
