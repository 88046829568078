import { connect } from "react-redux"
import AddMember from "../../pages/Account/AddMember"
import { addUser } from "../../actions/Users"
import { getGroups } from "../../actions/Groups"

const mapStateToProps = state => {
	return {
		Users: state.Users,
		Groups: state.Groups
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		addUser(body) {
			dispatch(addUser(body, props.history))
		},
		getGroups() {
			dispatch(getGroups())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMember)
