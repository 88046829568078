import React from 'react';
import classnames from 'classnames';

export default function LanguagesNav({ languages, activeLang, handleChangeLang, errors }) {
  if (languages.length <= 1) return null;
  return (
    <ul className="lang-nav d-print-none">
      {languages.map((item, i) => (
        <li key={i}>
          <button
            className={classnames(
              {
                'text-primary': activeLang === item.language,
                'text-danger': errors ? errors.find(i => i.indexOf(`.${item.language}`) !== -1) : false
              },
              'no-style',
              'p-2'
            )}
            onClick={() => handleChangeLang(item.language)}>
            {item.short_title}
          </button>
        </li>
      ))}
    </ul>
  );
}
