const initialState = {
  data: [],
  loading: false
}

const Invitations = (state = initialState, action) => {
  switch (action.type) {
    case "INVITATIONS_REQ":
      return {
        ...state,
        loading: true
      }
    case "INVITATIONS_RES":
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case "INVITATION_ADDED":
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      }
    case "INVITATION_EDITED":
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id_invitation === action.payload.id_invitation) {
            return action.payload
          } else {
            return item
          }
        }),
        loading: false
      }

    case "INVITATION_DELETED":
      return {
        ...state,
        data: state.data.filter(i => i.id_invitation !== action.payload),
        loading: false
      }
    case "USER_LOGOUT":
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default Invitations
