import axios from 'axios';
import { logout } from '../actions/Auth';
import Fingerprint2 from 'fingerprintjs2';
import store from '../store';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

export const setInstance = (data) => {
  localStorage.setItem('token', data.token);
  localStorage.setItem('refresh_token', data.refresh_token);
  instance.defaults.headers['Authorization'] = data.token;
};

if (localStorage.getItem('token')) {
  setInstance(localStorage);
  instance.defaults.headers['Authorization'] = localStorage.getItem('token');
}

if (localStorage.getItem('fingerprint')) {
  instance.defaults.headers['Session'] = localStorage.getItem('fingerprint');
} else {
  Fingerprint2.getV18((result) => {
    instance.defaults.headers['Session'] = result;
    localStorage.setItem('fingerprint', result);
  });
}

let refreshTokenPromise = undefined;

function getRefreshToken() {
  if (refreshTokenPromise === undefined) {
    refreshTokenPromise = axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/refreshToken`, {
        refresh_token: localStorage.getItem('refresh_token'),
        session: localStorage.getItem('fingerprint')
      })
      .then((response) => {
        refreshTokenPromise = undefined;
        return response.data.token;
      });
  }
  return refreshTokenPromise;
}

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (!error.config._retry) {
        error.config._retry = true;
        if (!localStorage.getItem('refresh_token')) {
          store.dispatch(logout());
          return Promise.reject(error);
        }
        return getRefreshToken()
          .then((token) => {
            instance.defaults.headers['Authorization'] = token;
            error.config.headers['Authorization'] = token;
            localStorage.setItem('token', token);
            return instance(error.config);
          })
          .catch((error) => {
            store.dispatch(logout());
            return Promise.reject(error);
          });
      }
    }
    return Promise.reject(error);
  }
);

export const removeInstance = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  delete instance.defaults.headers['Authorization'];
};

export default instance;
