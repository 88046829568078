/* eslint no-eval: 0 */

import React, { Component } from 'react';

class Checkbox extends Component {
  componentDidMount() {
    this.el.indeterminate = this.props.checked ? false : eval(this.props.indeterminate);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== eval(this.props.indeterminate)) {
      this.el.indeterminate = this.props.checked ? false : eval(this.props.indeterminate);
    }
  }

  render() {
    return (
      <label className="custom-control custom-checkbox mb-0" onClick={e => e.stopPropagation()}>
        <input {...this.props} type="checkbox" ref={el => (this.el = el)} />
        <span className="custom-control-label">{this.props.label}</span>
      </label>
    );
  }
}

export default Checkbox;
