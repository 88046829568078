import { connect } from 'react-redux';
import CategoryManagement from '../../pages/Account/CategoryManagement';
import {
  getContentTypes,
  addCategory,
  editCategory,
  deleteCategory,
  updateCategoriesBySlug
} from '../../actions/Categories';

const mapStateToProps = state => {
  return {
    User: state.User,
    Data: state.Data,
    Categories: state.Categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getContentTypes() {
      dispatch(getContentTypes());
    },
    addCategory(body) {
      return dispatch(addCategory(body));
    },
    editCategory(body, id) {
      return dispatch(editCategory(body, id));
    },
    deleteCategory(id) {
      return dispatch(deleteCategory(id));
    },
    updateCategoriesBySlug(slugs) {
      dispatch(updateCategoriesBySlug(slugs));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryManagement);
