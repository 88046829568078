import React, { Component } from "react"
import i18n from "../locale/i18n"
import VirtualList from "react-virtual-list"

const MyList = ({ virtual, itemHeight, checkedVillages, onAttach, maxLength }) => {
	return (
		<ul style={virtual.style}>
			{virtual.items.map((item, index) => {
				const isChecked = !!checkedVillages.find(i => i.id_village === item.id_village)
				return (
					<li key={index}>
						<label>
							<input
								type="checkbox"
								checked={isChecked}
								onChange={e => onAttach(item, e.target.checked)}
								disabled={maxLength ? checkedVillages.length >= 15 && !isChecked : false}
							/>
							<div className="list-check">
								<i className="material-icons">check</i>
								<span className="item-name">{item.name[i18n.language]}</span>
							</div>
						</label>
					</li>
				)
			})}
		</ul>
	)
}

let MyVirtualList = VirtualList()(MyList)

class VillagesList extends Component {
	componentDidMount() {
		MyVirtualList = VirtualList({ container: this.refs.container })(MyList)
	}
	render() {
		return (
			<div className="data-list" id="container" ref="container">
				<MyVirtualList {...this.props} itemHeight={50} />
			</div>
		)
	}
}

export default VillagesList
