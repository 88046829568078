import React, { Component } from 'react';
import { Button } from 'reactstrap';
import ProfileInfo from '../../components/ProfileInfo';
import ProfileEdit from '../../components/ProfileEdit';
import Loading from '../../components/Loading';
import i18n from '../../locale/i18n';

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false
    };
  }

  componentDidMount() {
    const {
      Groups: { data },
      getGroups
    } = this.props;
    if (!data.length) getGroups();
  }

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  handleEditProfile = (values, id) => {
    this.props.editProfile(values, id).then(() => {
      this.setState({ editMode: false });
    });
  };

  render() {
    const {
      User: { data, loading },
      Groups,
      deleteProfile
    } = this.props;
    const { editMode } = this.state;

    return (
      <div>
        <div className="app-content-header">
          <h1>{i18n.t('MY_PROFILE')}</h1>
        </div>
        <div className="app-content-inner content-sm">
          {loading && !editMode ? (
            <Loading />
          ) : (
            <div>
              <h3 className="text-center">{`${data.first_name} ${data.last_name}`}</h3>
              {editMode ? (
                <ProfileEdit
                  data={data}
                  handleSubmit={this.handleEditProfile}
                  handleReject={this.toggleEditMode}
                  handleDelete={deleteProfile}
                  loading={loading}
                  groups={Groups}
                  isOwner={true}
                />
              ) : (
                <div>
                  <div className="mt-4 edit-action">
                    <Button color="link" size="sm" onClick={this.toggleEditMode}>
                      {i18n.t('EDIT')}
                    </Button>
                  </div>
                  <ProfileInfo data={data} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MyProfile;
