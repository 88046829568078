import React, { Component } from 'react';
import HeaderCol from './HeaderCol';
import RowRenderer from './RowRenderer';
import Checkbox from '../Checkbox';

class DataTableScroll extends Component {
  render() {
    const {
      isAllChecked,
      toggleAllVillages,
      toggleColumn,
      handleSort,
      params,
      data,
      columns,
      toggleVillage,
      goToMouments,
      setHovered,
      checked,
      hovered,
      history
    } = this.props;

    return (
      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th className="fixed action-col">
                <Checkbox
                  name="all"
                  className="custom-control-input"
                  checked={isAllChecked(checked) === 2}
                  indeterminate={(isAllChecked(checked) === 1).toString()}
                  onChange={e => toggleAllVillages(e.target.checked)}
                />
              </th>
              {columns.map((col, i) => (
                <HeaderCol
                  col={col}
                  key={col.key}
                  toggleColumn={toggleColumn}
                  handleSort={handleSort}
                  params={params}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            <RowRenderer
              items={data}
              columns={columns}
              isfixed={true}
              checked={checked}
              hovered={hovered}
              history={history}
              toggleVillage={toggleVillage}
              goToMouments={goToMouments}
              setHovered={setHovered}
            />
          </tbody>
        </table>
      </div>
    );
  }
}

export default DataTableScroll;
