const initialState = {
  data: [],
  currentUser: null,
  loading: false
};

const Users = (state = initialState, action) => {
  switch (action.type) {
    case 'USERS_LIST_REQ':
      return { ...state, loading: true };
    case 'USERS_LIST_RES':
      return {
        ...state,
        data: action.payload,
        currentUser: state.currentUser ? action.payload.find(i => i.id_user === state.currentUser.id_user) : null,
        loading: false
      };
    case 'USERS_PROFILE_REQ':
      return { ...state, loading: true };
    case 'USERS_PROFILE_RES':
      return { ...state, currentUser: action.payload, loading: false };
    case 'USERS_PROFILE_EDIT_REQ':
      return { ...state, loading: true };
    case 'USERS_PROFILE_EDIT_RES':
      const { user } = action.payload;
      return {
        ...state,
        data: [...state.data.filter(i => i.id_user !== user.id_user), user],
        currentUser: { ...state.currentUser, ...user },
        loading: false
      };
    case 'USERS_SELECT':
      return { ...state, currentUser: state.data.find(item => item.id_user === action.payload) || null };
    case 'USERS_ADD_REQ':
      return { ...state, loading: true };
    case 'USERS_ADD_RES':
      return { ...state, loading: false, data: [...state.data, action.payload] };
    case 'USERS_DELETE_REQ':
      return { ...state, loading: true };
    case 'USERS_DELETE_RES':
      return {
        ...state,
        loading: false,
        currentUser: null,
        data: [...state.data.filter(i => i.id_user !== action.payload)]
      };
    case 'USER_LOGOUT':
      return { ...initialState };
    default:
      return state;
  }
};

export default Users;
