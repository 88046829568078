import React, { Component } from 'react';
import qs from 'qs';
import Linkify from 'react-linkify';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import {
  Table,
  Button,
  Nav,
  NavItem,
  NavLink,
  Modal,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';
import { isAdmin } from '../services/permissions';
import Loading from '../components/Loading';
import Files from '../components/Files';
import i18n from '../locale/i18n';
import VillagesMap from './VillagesMap';
import { isViewer } from '../services/permissions';
import LanguagesNav from '../components/LanguagesNav';

class Village extends Component {
  state = {
    t: i18n.getFixedT(i18n.language),
    language: i18n.language,
    expoertLoading: false,
    isMediaPrint: false,
    mapsModal: false,
    bookmark: null,
    activeCategory: ''
  };

  componentDidMount() {
    document.title = `UNDP_RTD - Village`;
    const {
      Villages: { current, bookmarks },
      location,
      history,
      getVillage,
      getBookmarks,
      match: { params }
    } = this.props;
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    const year = parsed.archive_year || null;
    const category = location.hash ? location.hash.replace('#', '') : null;

    if (!bookmarks.length) {
      getBookmarks();
    }

    if (!current || current.id_village !== params.id_village) {
      getVillage(params.id_village, year).then(data => {
        if (data && data.payload) {
          const { code, columns } = data.payload;
          const activeCategory = category ? category : columns.length ? columns[0].id_category : '';
          document.title = `UNDP_RTD - Village_${code}`;
          history.replace({ hash: activeCategory });
          this.setState({ activeCategory });
        }
      });
    } else {
      const { code, columns } = current;
      const activeCategory = category ? category : columns.length ? columns[0].id_category : '';
      document.title = `UNDP_RTD - Village_${code}`;
      history.replace({ hash: activeCategory });
      this.setState({ activeCategory });
    }

    window.onafterprint = () => {
      this.setState({ isMediaPrint: false });
    };
  }

  componentWillUnmount() {
    document.title = `UNDP_RTD`;
  }

  handlePrintData = () => {
    this.setState({ isMediaPrint: true }, () => {
      setTimeout(() => {
        window.print();
      }, 100);
    });
  };

  goToMouments = id => {
    this.props.history.push(`/village/${id}/monuments`);
  };

  goToVillages = (name, value) => {
    const {
      Data: { params },
      getDataFlat
    } = this.props;
    getDataFlat({ ...params, [name]: value, recent_state: false, id_user_state: null });
    this.props.history.push(`/`);
  };

  toggleBookmark = bookmark => {
    const { deleteBookmark } = this.props;
    if (bookmark) {
      deleteBookmark(bookmark.id_bookmark).then(() => {
        this.setState({
          bookmark: null
        });
      });
    } else {
      this.setState({
        bookmarkModal: true
      });
    }
  };

  toggleBookmarkModal = () => {
    this.setState(state => ({ bookmarkModal: !state.bookmarkModal }));
  };

  handleSubmitSave = (e, { name }) => {
    const {
      addBookmark,
      Villages: { current }
    } = this.props;
    addBookmark({ name, params: { id_village: current.id_village } }).then(({ payload }) => {
      this.setState({
        bookmarkModal: false,
        bookmark: payload
      });
    });
  };

  handleDeleteVillage = () => {
    const {
      deleteVillage,
      getDataFlat,
      history,
      Villages: { current }
    } = this.props;
    const confirmation = window.confirm(i18n.t('DELETE_VILLAGE_QUESTION'));

    if (confirmation) {
      deleteVillage(current.id_village).then(() => {
        getDataFlat({ lang: i18n.language, recent_state: true });
        history.replace('/');
      });
    }
  };

  handleChangeYear = e => {
    const {
      getVillage,
      history,
      match: { params }
    } = this.props;
    const year = e.target.value || null;
    getVillage(params.id_village, year);
    history.replace({ search: qs.stringify({ archive_year: year || '' }) });
  };

  setActiveCategory = activeCategory => {
    const { history } = this.props;
    this.setState({ activeCategory });
    history.replace({ hash: activeCategory });
  };

  handleChangeLang = lang => {
    if (this.state.language !== lang) {
      this.setState({
        t: i18n.getFixedT(lang),
        language: lang
      });
    }
  };

  villageExportHandler = () => {
    const {
      exportVillage,
      Villages: { current }
    } = this.props;
    this.setState({ expoertLoading: true });
    exportVillage(current.id_village)
      .then(() => this.setState({ expoertLoading: false }))
      .catch(() => this.setState({ expoertLoading: false }));
  };

  togglemapsModal = () => {
    this.setState(state => ({
      mapsModal: !state.mapsModal
    }));
  };

  openGooleMaps = () => {
    const {
      Villages: {
        current: { coordinates, name, state }
      }
    } = this.props;

    const village = {
      name: name[i18n.language],
      state: state.name[i18n.language],
      location: coordinates
    };

    this.setState({
      mapsModal: true,
      mapData: [village]
    });
  };

  hanleEditVillage = () => {
    const { activeCategory } = this.state;
    const {
      Villages: { current },
      history
    } = this.props;
    history.replace({
      pathname: `/village/${current.id_village}/edit`,
      hash: activeCategory
    });
  };

  render() {
    const {
      User: { languages },
      Villages: { current, loading, bookmarks },
      history
    } = this.props;
    const { activeCategory, bookmarkModal, language, t, expoertLoading, mapsModal, mapData, isMediaPrint } = this.state;
    const bookmark = current && bookmarks.find(i => i.params.id_village === current.id_village);

    return (
      <div className="app-content-wrapper">
        <LanguagesNav languages={languages} activeLang={language} handleChangeLang={this.handleChangeLang} />
        <div className="mt-4 app-content content-fluid">
          <div className="app-content-header d-print-none">
            <h1>
              {t('village')} {current && !loading && current.name[language]}
            </h1>
          </div>
          {loading ? (
            <Loading />
          ) : current ? (
            <div className="app-content-inner">
              <div className="content-inner-header">
                <h1 className="content-header-title">
                  {current.name[language]}{' '}
                  <Button
                    color="link"
                    className="village-bookmark"
                    onClick={() => this.toggleBookmark(bookmark)}
                    data-tooltip={i18n.t('FAVORITE')}>
                    {bookmark ? (
                      <i className="material-icons text-success">star</i>
                    ) : (
                      <i className="material-icons">star_border</i>
                    )}
                  </Button>
                  <Button
                    onClick={this.hanleEditVillage}
                    color="link"
                    data-tooltip={i18n.t(isViewer() ? 'SUBMIT' : 'EDIT')}>
                    <i className="material-icons ">mode_edit</i>
                  </Button>
                  {isAdmin() && (
                    <Button
                      onClick={this.handleDeleteVillage}
                      color="link text-danger"
                      data-tooltip={i18n.t(isViewer() ? 'SUBMIT' : 'DELETE')}>
                      <i className="material-icons">delete</i>
                    </Button>
                  )}
                  <Button
                    color="link"
                    className={expoertLoading ? 'btn-loading' : ''}
                    onClick={this.villageExportHandler}
                    data-tooltip={i18n.t('DOWNLOAD')}>
                    <i className="material-icons">file_download</i>
                  </Button>
                  <Button color="link" onClick={this.handlePrintData} data-tooltip={i18n.t('PRINT')}>
                    <i className="material-icons">print</i>
                  </Button>
                  {current.coordinates ? (
                    <Button color="link" onClick={() => this.openGooleMaps()} data-tooltip={i18n.t('SHOW_ON_MAP')}>
                      <i className="material-icons ">map</i>
                    </Button>
                  ) : null}
                </h1>
                <div className="content-header-subtitle">
                  <Button onClick={() => history.goBack()} color="link btn-back d-print-none">
                    <i className="material-icons">&#xE317;</i>
                    {t('BACK')}
                  </Button>
                  <ul>
                    <li>
                      <b>{t('CODE')}:</b> {current.code || ''}
                    </li>
                    <li>
                      <b>{t('state')}:</b>{' '}
                      <Button color="link" onClick={() => this.goToVillages('id_state', current.id_state)}>
                        {current.state.name[language]}
                      </Button>
                    </li>
                    <li>
                      <b>{t('district')}:</b>{' '}
                      <Button color="link" onClick={() => this.goToVillages('id_district', current.id_district)}>
                        {current.district.name[language]}
                      </Button>
                    </li>
                    <li>
                      <b>{t('MEMORIALS')}:</b>{' '}
                      <Button
                        color="link"
                        onClick={() => this.goToMouments(current.id_village)}
                        disabled={Boolean(!current.monument_count || current.monument_count === '0')}>
                        {current.monument_count}
                      </Button>
                    </li>
                    <li>
                      <b>{t('archive_year')}:</b>{' '}
                      <Input
                        type="select"
                        name="archive_years"
                        onChange={this.handleChangeYear}
                        value={current.archive_year || ''}>
                        <option value="">-----</option>
                        {current.archive_years.map((y, i) => (
                          <option key={i} value={y}>
                            {y}
                          </option>
                        ))}
                      </Input>
                    </li>
                    <li>
                      <b>{t('COORDINATES')}:</b>{' '}
                      {current.coordinates ? (
                        <Button color="link" onClick={() => this.openGooleMaps()}>
                          {Object.values(current.coordinates).join(', ')}
                        </Button>
                      ) : (
                        '- -'
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              {!!current.files && (
                <div className="d-print-none">
                  <h3 className="mb-3">{t('ATTACHED_FILES')}</h3>
                  <Files data={current.files} />
                </div>
              )}
              <div className="nav-tab-wrapper d-print-none">
                <Nav className="nav-tab">
                  {current.columns.map((cat, i) => (
                    <NavItem key={i}>
                      <NavLink
                        active={activeCategory === cat.id_category}
                        onClick={() => this.setActiveCategory(cat.id_category)}>
                        {cat.name[language]}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>

              <div className="content-box mb-5">
                <Table className="table-simple secondary village">
                  <thead>
                    <tr>
                      <th>{t('CATEGORY')}</th>
                      <th>{t('data')}</th>
                      <th className="text-center">{t('sources')}</th>
                      <th className="text-center">{t('archive_year')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {current.data
                      .filter(i => (isMediaPrint ? true : i.category.id_root === activeCategory))
                      .map((item, i) => (
                        <tr key={i}>
                          <td className="category-col">
                            {item.category.id_root !== item.category.id_parent && (
                              <span className="parent">{item.category.parent.name[language]}</span>
                            )}
                            {item.category.name[language]}
                          </td>
                          <td>
                            <Linkify properties={{ target: '_blank' }}>{item.data[language].content}</Linkify>
                          </td>
                          <td className="text-center">
                            {item.data[language].sources &&
                              item.data[language].sources.map((source, i) => (
                                <b className="source-code" key={i}>
                                  <span data-tooltip={source.description[language]}>{source.code}</span>
                                </b>
                              ))}
                          </td>
                          <td className="text-center">{item.data[language].archive_year}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          ) : (
            <div className="text-muted text-center p-5">not found</div>
          )}
        </div>
        <Modal isOpen={bookmarkModal} toggle={this.toggleBookmarkModal} size="sm">
          <ModalHeader toggle={this.toggleBookmarkModal}>{i18n.t('ADD_BOOKMARK')}</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitSave}>
            <ModalBody>
              <AvGroup>
                <AvInput
                  name="name"
                  type="text"
                  required
                  autoFocus
                  value={current ? `${current.state.name[language]} - ${current.name[language]} ` : ''}
                />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleBookmarkModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={mapsModal} toggle={this.togglemapsModal} className="map-modal">
          <Button color="default" className="close" onClick={this.togglemapsModal}>
            <i className="material-icons">close</i>
          </Button>
          {Boolean(mapData) && <VillagesMap villages={mapData} />}
        </Modal>
      </div>
    );
  }
}

export default Village;
