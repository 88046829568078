import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import i18n from '../../locale/i18n';

class CategoryManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      targetType: null,
      typeModal: false
    };
  }
  componentDidMount() {
    const { Categories, getContentTypes } = this.props;
    if (!Categories.contentTypes.length) getContentTypes();
  }

  handleValidSubmit = (ev, values) => {
    const { addContentType, editContentType, User } = this.props;
    const { targetType } = this.state;
    this.setState({ typeModal: false });
    values.name = { am: values.name_am, en: values.name_en, ru: values.name_ru };
    values.regex = window.btoa(values.regex);
    User.languages.forEach(({ language }) => {
      delete values[`name_${language}`];
    });
    if (!targetType) {
      addContentType(values).then(() => {
        toast.success(i18n.t('SUCCESS_ADD_CONTET_TYPE'));
      });
    } else {
      editContentType(values, targetType.id_content_type).then(() => {
        toast.success(i18n.t('SUCCESS_EDIT_CONTET_TYPE'));
        this.setState({ targetType: null });
      });
    }
  };

  handleEditType = type => {
    this.setState({
      typeModal: true,
      targetType: type
    });
  };

  toggleTypeModal = () => {
    this.setState({
      targetType: null,
      typeModal: !this.state.typeModal
    });
  };

  handleDelete = id => {
    const confirmation = window.confirm(i18n.t('DELETE_CONTET_TYPE_QUESTION'));
    if (confirmation) {
      this.props.deleteContentType(id).then(() => {
        toast.success(i18n.t('SUCCESS_DELETE_CONTET_TYPE'));
      });
    }
  };

  render() {
    const {
      Categories: { contentTypes },
      User: { language, languages }
    } = this.props;
    const { targetType, typeModal } = this.state;

    return (
      <div>
        <div className="app-content-header">
          <h1>{i18n.t('CONTENT_TYPES')}</h1>
        </div>
        <div className="app-content-inner">
          <div className="content-container content-sm">
            <ListGroup>
              {contentTypes.map((type, index) => (
                <ListGroupItem key={index}>
                  <div className="item-title">
                    {type.type} - {type['name_' + language]}
                  </div>
                  <div className="item-actions">
                    <Button color="link px-2" onClick={e => this.handleEditType(type)} data-tooltip={i18n.t('EDIT')}>
                      <i className="material-icons">mode_edit</i>
                    </Button>
                    <Button
                      color="link px-2"
                      onClick={e => this.handleDelete(type.id_content_type)}
                      data-tooltip={i18n.t('DELETE')}>
                      <i className="material-icons">delete</i>
                    </Button>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </div>

        <Modal isOpen={typeModal} toggle={this.toggleTypeModal}>
          <ModalHeader toggle={this.toggleTypeModal}>{targetType ? i18n.t('EDIT') : i18n.t('ADD')}</ModalHeader>
          <AvForm onValidSubmit={this.handleValidSubmit} model={targetType}>
            <ModalBody>
              {languages.map(item => (
                <AvGroup key={item.language}>
                  <AvInput name={`name_${item.language}`} type="text" required />
                  <Label className="form-control-label">
                    {i18n.t('name')} <b>({item.title})</b>
                  </Label>
                </AvGroup>
              ))}
              <AvGroup>
                <AvInput type="select" name="type" required>
                  <option value="" disabled />
                  <option value="string">String</option>
                  <option value="number">Number</option>
                </AvInput>
                <Label className="form-control-label">Type</Label>
              </AvGroup>
              <AvGroup>
                <AvInput name="regex" type="text" required />
                <Label className="form-control-label">Regex</Label>
              </AvGroup>
            </ModalBody>

            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleTypeModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{targetType ? i18n.t('SAVE') : i18n.t('CREATE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <div className="add-action">
          <Button color="success" onClick={this.toggleTypeModal}>
            <i className="material-icons">&#xE145;</i>
          </Button>
        </div>
      </div>
    );
  }
}

export default CategoryManagement;
