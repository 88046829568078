import React, { Component } from "react"
import { Link } from "react-router-dom"
import { AvForm, AvGroup, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap"
import Loading from "../../../components/Loading"
import { groupBy } from "../../../services/helpers"
import i18n from "../../../locale/i18n"
import { isAdmin } from "../../../services/permissions"

class Groups extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modal: false
		}
	}

	toggleAddModal = () => {
		this.setState({
			modal: !this.state.modal
		})
	}

	componentDidMount() {
		const { Groups: { data }, getGroups } = this.props
		if (!data.length) getGroups()
	}

	handleValidSubmit = (ev, values) => {
		this.props.addGroup(values).then(() => {
			this.setState({
				modal: false
			})
			this.addForm.reset()
		})
	}

	render() {
		const { Groups: { data, loading } } = this.props
		const groups = data.length ? groupBy(data, "type") : { predefined: [], custom: [] }

		return (
			<div>
				<div className="app-content-inner content-sm">
					{loading ? (
						<Loading />
					) : (
						<div>
							<ListGroup className="mb-3">
								{groups.predefined.map((item, index) => (
									<ListGroupItem key={item.id_group}>
										<Link to={`/account/groups/${item.id_group}`}>
											<div className="item-value">{`${item.title}`}</div>
											<i className="material-icons">&#xE315;</i>
										</Link>
									</ListGroupItem>
								))}
							</ListGroup>
							<h3 className="mt-5 mb-3">{i18n.t("CUSTOM_GROUPS")}</h3>
							<ListGroup>
								{groups.custom.map((item, index) => (
									<ListGroupItem key={item.id_group}>
										<Link to={`/account/groups/${item.id_group}`}>
											<div className="item-value">{`${item.title}`}</div>
											<i className="material-icons">&#xE315;</i>
										</Link>
									</ListGroupItem>
								))}
							</ListGroup>
						</div>
					)}
				</div>

				<Modal isOpen={this.state.modal} toggle={this.toggleAddModal} size="sm">
					<ModalHeader toggle={this.toggleAddModal}>{i18n.t("CREATE_GROUP")}</ModalHeader>
					<AvForm onValidSubmit={this.handleValidSubmit} ref={form => (this.addForm = form)}>
						<ModalBody>
							<AvGroup>
								<AvInput name="title" type="text" required />
								<Label className="form-control-label">{i18n.t("name")}</Label>
							</AvGroup>
							<h6 className="mb-3">{i18n.t("role")}</h6>
							<AvRadioGroup name="role" required errorMessage="Pick one!">
								<AvRadio label={i18n.t("moderator")} value="moderator" />
								<AvRadio label={i18n.t("viewer")} value="viewer" />
							</AvRadioGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="link" className="text-dark" onClick={this.toggleAddModal}>
								{i18n.t("CANCEL")}
							</Button>
							<Button color="link">{i18n.t("CREATE")}</Button>
						</ModalFooter>
					</AvForm>
				</Modal>
				{isAdmin() && (
					<div className="add-action">
						<Button color="success" onClick={this.toggleAddModal}>
							<i className="material-icons">&#xE145;</i>
						</Button>
					</div>
				)}
			</div>
		)
	}
}

export default Groups
