import { connect } from "react-redux"
import AdvancedSearchResults from "../pages/AdvancedSearchResults"
import {
	advanceSearch,
	advanceSearchSave,
	getAdvanceSearchList,
	advanceSearchExport,
	advanceSearchForMap
} from "../actions/Data"

const mapStateToProps = state => {
	return {
		Data: state.Data
	}
}

const mapDispatchToProps = dispatch => {
	return {
		advanceSearch(params) {
			return dispatch(advanceSearch(params))
		},
		advanceSearchSave(params) {
			return dispatch(advanceSearchSave(params))
		},
		getAdvanceSearchList() {
			dispatch(getAdvanceSearchList())
		},
		advanceSearchExport(params) {
			return dispatch(advanceSearchExport(params))
		},
		advanceSearchForMap(params) {
			return dispatch(advanceSearchForMap(params))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedSearchResults)
