import React, { Component } from "react"
import i18n from "../locale/i18n"

class Total extends Component {
	state = {
		text: ""
	}
	setText = () => {
		const { total, page, limit } = this.props
		const lt = Number(limit <= total ? limit : total)
		if (!total) {
			this.setState({
				text: ""
			})
		} else {
			const pageNum = page - 1
			const start = pageNum * lt + 1
			let end = pageNum * lt + lt

			if (end > total) {
				end = total
			}

			this.setState({
				text: i18n.t("PAGINATION_TOTAL_COUNER", { start, end, total })
			})
		}
	}
	componentDidMount() {
		this.setText()
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.page !== this.props.page || prevProps.total !== this.props.total) {
			this.setText()
		}
	}

	render() {
		const { text } = this.state
		return <span className="total-counter">{text}</span>
	}
}

export default Total
