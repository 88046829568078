import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { toastOptions } from '../constants/';
import { getProfile, changeLanguage, getLanguages, getUserData } from '../actions/User';
import { logout } from '../actions/Auth';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Villages from '../containers/Villages';
import VillagesMap from '../containers/VillagesMap';
import Village from '../containers/Village';
import VillageEdit from '../containers/VillageEdit';
import VillageAdd from '../containers/VillageAdd';
import Compare from '../containers/Compare';
import Login from '../containers/Login';
import Register from '../containers/Register';
import ForgotPassword from '../containers/ForgotPassword';
import ResetPassword from '../containers/ResetPassword';
import Approvals from '../containers/Approvals';
import Approval from '../containers/Approval';
import Forms from '../containers/Forms';

import Account from '../containers/Account';
import MyListings from '../containers/MyListings';
import AdvancedSearch from '../containers/AdvancedSearch';
import AdvancedSearchResults from '../containers/AdvancedSearchResults';

// import Monuments from '../containers/Monuments';
// import Monument from '../containers/Monument';
// import MonumentAdd from '../containers/MonumentAdd';
// import MonumentEdit from '../containers/MonumentEdit';
import Libraries from '../containers/Libraries';
import UserProfile from '../containers/Account/UserProfile';

import i18n from '../locale/i18n';

class App extends Component {
  componentDidMount() {
    const { User, getProfile, getLanguages, getUserData, history } = this.props;

    if (User.data) {
      getProfile(User.data.id_user).then(({ payload }) => {
        if (i18n.language !== payload.system_lang) {
          i18n.changeLanguage(payload.system_lang);
        }
      });
      getLanguages();
      getUserData(User.data.id_user);
    } else {
      logout();
    }
    i18n.on('languageChanged', this.onLanguageChanged);
    history.listen((route) => {
      window.scrollTo(0, 0);
    });
  }

  componentWillUnmount() {
    i18n.off('languageChanged', this.onLanguageChanged);
  }

  onLanguageChanged = (lng) => {
    this.props.changeLanguage(lng);
  };

  render() {
    const {
      User: { data }
    } = this.props;
    return (
      <div className="app">
        {data && <Header {...this.props} />}
        <div className="app-body">
          <main className="main">
            {data ? (
              <Switch>
                <Route path="/account" component={Account} />
                <Route path="/my-listings" component={MyListings} />
                <Route path="/forms" component={Forms} />
                <Route path="/libraries" component={Libraries} />
                <Route path="/approvals" component={Approvals} />
                <Route path="/approval/:type/:id" component={Approval} />
                <Route exact path="/" component={Villages} />
                <Route exact path="/map" component={VillagesMap} />
                <Route exact path="/village/:id_village" component={Village} />
                <Route exact path="/village/:id_village/edit" component={VillageEdit} />
                <Route exact path="/villages/add" component={VillageAdd} />
                <Route exact path="/compare" component={Compare} />
                <Route exact path="/advanced-search" component={AdvancedSearch} />
                <Route exact path="/advanced-search/edit" component={AdvancedSearch} />
                <Route exact path="/advanced-search/results" component={AdvancedSearchResults} />
                <Route exact path="/user/:userId" component={UserProfile} />
                <Redirect to="/" />
              </Switch>
            ) : (
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <Route exact path="/registration/:code" component={Register} />
                <Redirect to="/login" />
              </Switch>
            )}
          </main>
        </div>
        {data && <Footer />}
        <ToastContainer {...toastOptions} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { User: state.User };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile(id) {
      return dispatch(getProfile(id));
    },
    getLanguages() {
      dispatch(getLanguages());
    },
    logout() {
      dispatch(logout());
    },
    changeLanguage(lng) {
      dispatch(changeLanguage(lng));
    },
    getUserData(id) {
      dispatch(getUserData(id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
