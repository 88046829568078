import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AccountSidebar from "../components/AccountSidebar";

import SavedSearches from "../containers/MyListings/SavedSearches";
import FilteredSections from "../containers/MyListings/FilteredSections";
import Bookmarks from "../containers/MyListings/Bookmarks";
import Comparisons from "../containers/MyListings/Comparisons";

class MyListings extends Component {
	render() {
		return (
			<div className="app-content-wrapper">
				<AccountSidebar {...this.props} />
				<div className="app-content">
					<Switch>
						<Route exact path="/my-listings/saved-searches" component={SavedSearches} />
						<Route exact path="/my-listings/filtered-sections" component={FilteredSections} />
						<Route exact path="/my-listings/bookmarks" component={Bookmarks} />
						<Route exact path="/my-listings/comparisons" component={Comparisons} />
						<Redirect from="/my-listings" to="/my-listings/saved-searches" />
						<Redirect to="/my-listings/saved-searches" />
					</Switch>
				</div>
			</div>
		);
	}
}

export default MyListings;
