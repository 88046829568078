import React, { Component } from 'react';
import Loading from './Loading';
import CategoryTree from './CategoryTree';

class AppSidebar extends Component {
  render() {
    const {
      Categories: { loading },
      opened
    } = this.props;
    return (
      <div className={`app-sidebar sidebar-fixed  ${opened ? 'sidebar-opened' : ''}`}>
        {loading ? <Loading /> : <CategoryTree {...this.props} />}
      </div>
    );
  }
}

export default AppSidebar;
