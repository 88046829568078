import React, { Component } from 'react';
import { ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { clone } from '../../../services/helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import i18n from '../../../locale/i18n';

class GroupInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invitation: null,
      addExpires: null,
      addModal: false,
      detailsModal: false,
      editMode: false
    };
  }

  toggleAddModal = () => {
    this.setState({
      addModal: !this.state.addModal
    });
  };

  handleValidSubmit = (ev, values) => {
    this.props.addInvitation(values).then(() => {
      this.setState({
        addExpires: null,
        addModal: false
      });
      this.addForm.reset();
    });
  };

  handleSubmitEdit = (ev, values) => {
    const id = this.state.invitation.id_invitation;
    this.props.editInvitation(values, id).then(() => {
      this.setState({
        invitation: null,
        detailsModal: false
      });
    });
  };

  handleDelete = () => {
    const confirmation = window.confirm(`${i18n.t('DELETE_INVITATION_QUESTION')}?`);
    if (confirmation) {
      const id = this.state.invitation.id_invitation;
      this.props.deleteInvitation(id).then(() => {
        this.setState({
          invitation: null,
          detailsModal: false
        });
      });
    }
  };

  handleChangeDate = date => {
    this.setState({
      addExpires: date
    });
  };

  handleEeditDate = date => {
    this.setState(prev => {
      return {
        invitation: {
          ...prev.invitation,
          expires: date
        }
      };
    });
  };

  showDetails = item => {
    this.setState({
      detailsModal: true,
      editMode: false,
      invitation: clone(item)
    });
  };

  toggleDetailsModal = () => {
    this.setState({
      detailsModal: !this.state.detailsModal,
      editMode: false
    });
  };

  toggleEdit = item => {
    this.setState({
      editMode: !this.state.editMode
    });
  };

  render() {
    const {
      Groups: {
        currentGroup: { id_group }
      },
      Invitations,
      GroupInvitations
    } = this.props;
    const { addExpires, invitation, editMode } = this.state;
    let diff = null;
    if (addExpires) {
      const start = moment(addExpires);
      diff = start.diff(moment(), 'days');
    }
    return (
      <div>
        <div className="app-content-inner content-sm">
          {Invitations.loading ? (
            <Loading />
          ) : (
            <ListGroup>
              {GroupInvitations.map((item, index) => (
                <ListGroupItem key={index}>
                  <div className="item-value">{item.title || '--'}</div>
                  <Button color="link" onClick={() => this.showDetails(item)}>
                    {i18n.t('VIEW_DETAILS')}
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </div>

        <Modal isOpen={this.state.addModal} toggle={this.toggleAddModal}>
          <ModalHeader toggle={this.toggleAddModal}>{i18n.t('invitation')}</ModalHeader>
          <AvForm onValidSubmit={this.handleValidSubmit} ref={form => (this.addForm = form)}>
            <ModalBody>
              <AvGroup>
                <AvInput name="title" type="text" required />
                <Label className="form-control-label">{i18n.t('INVITATION_NAME')}</Label>
              </AvGroup>
              <AvGroup className="inline">
                <Label>{i18n.t('VALID_BEFORE')}</Label>
                <DatePicker
                  id="expires"
                  name="expires"
                  selected={addExpires}
                  minDate={new Date()}
                  onChange={this.handleChangeDate}
                  dateFormat="yyyy-MM-dd"
                  customInput={<AvInput name="expires" />}
                  required
                />
                {diff && <span>{diff} օր</span>}
              </AvGroup>
              <AvGroup className="inline">
                <Label>{i18n.t('MEMBERS_COUNT')}</Label>
                <AvInput name="max_registrants" type="number" required />
              </AvGroup>
              <AvInput name="id_group" type="hidden" value={id_group} />
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleAddModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('CREATE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.detailsModal} toggle={this.toggleDetailsModal}>
          <ModalHeader toggle={this.toggleDetailsModal}>{i18n.t('invitation')}</ModalHeader>
          {invitation && (
            <AvForm onValidSubmit={this.handleSubmitEdit} model={invitation}>
              {editMode ? (
                <ModalBody>
                  <AvGroup>
                    <AvInput name="title" type="text" required />
                    <Label className="form-control-label">{i18n.t('INVITATION_NAME')}</Label>
                  </AvGroup>
                  <AvGroup className="inline">
                    <Label>{i18n.t('VALID_BEFORE')}</Label>
                    <DatePicker
                      id="expires"
                      name="expires"
                      selected={new Date(invitation.expires)}
                      minDate={new Date()}
                      onChange={this.handleEeditDate}
                      dateFormat="yyyy-MM-dd"
                      customInput={<AvInput name="expires" />}
                      required
                    />
                  </AvGroup>
                  <AvGroup className="inline">
                    <Label>{i18n.t('MEMBERS_COUNT')}</Label>
                    <AvInput type="number" name="max_registrants" required />
                  </AvGroup>
                  <AvInput name="id_group" type="hidden" value={id_group} />
                </ModalBody>
              ) : (
                <ModalBody>
                  <div>
                    <ul className="invitation-details">
                      <li>
                        <b>{i18n.t('INVITATION_NAME')}:</b>
                        <span>{invitation.title}</span>
                      </li>
                      <li>
                        <b>{i18n.t('VALID_BEFORE')}:</b>
                        <span>{invitation.expires}</span>
                      </li>
                      <li>
                        <b>{i18n.t('MEMBERS_COUNT')}:</b>
                        <span>{invitation.max_registrants}</span>
                      </li>
                    </ul>
                    <div className="clipboard-wrapper">
                      <input type="text" readOnly value={`${window.location.origin}/registration/${invitation.hash}`} />
                      <CopyToClipboard
                        onCopy={() => toast.success(i18n.t('LINK_COPIED'))}
                        text={`${window.location.origin}/registration/${invitation.hash}`}>
                        <span>{i18n.t('COPY_LINK')}</span>
                      </CopyToClipboard>
                    </div>
                  </div>
                </ModalBody>
              )}
              <ModalFooter>
                {editMode
                  ? [
                      <Button key="delete" color="link" className="text-danger" onClick={this.handleDelete}>
                        {i18n.t('DELETE')}
                      </Button>,
                      <Button key="cancel" color="link" className="text-dark" onClick={this.toggleDetailsModal}>
                        {i18n.t('CANCEL')}
                      </Button>,
                      <Button key="submit" color="link">
                        {i18n.t('CONFIRM')}
                      </Button>
                    ]
                  : [
                      <Button key="close" color="link" className="text-dark" onClick={this.toggleDetailsModal}>
                        {i18n.t('CLOSE')}
                      </Button>,
                      <Button key="edit" color="link" onClick={this.toggleEdit}>
                        {i18n.t('CHANGE')}
                      </Button>
                    ]}
              </ModalFooter>
            </AvForm>
          )}
        </Modal>

        <div className="add-action">
          <Button color="success" onClick={this.toggleAddModal}>
            <i className="material-icons">&#xE145;</i>
          </Button>
        </div>
      </div>
    );
  }
}

export default GroupInvitations;
