import { connect } from "react-redux"
import ActivityLog from "../../pages/Account/ActivityLog"
import { getActivityLogs, getActivityLog, deleteActivityLog } from "../../actions/ActivityLog"

const mapStateToProps = state => {
	return {
		ActivityLog: state.ActivityLog
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getActivityLogs(params) {
			dispatch(getActivityLogs(params))
		},
		getActivityLog(id) {
			dispatch(getActivityLog(id))
		},
		deleteActivityLog(id) {
			dispatch(deleteActivityLog(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog)
