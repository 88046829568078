import React, { Component, Fragment } from 'react';
import { Table, Button } from 'reactstrap';
import errorHandler from '../../services/errorHandler';
import Pagination from '../../components/Pagination';
import Loading from '../../components/Loading';
import Files from '../../components/Files';
import { limits } from '../../constants/';
import instance from '../../services/api';
import i18n from '../../locale/i18n';
import { isObject } from '../../services/helpers';

class ApprovalSimple extends Component {
  state = {
    loading: false,
    old: [],
    data: [],
    params: {
      limit: limits.approval,
      page: 1
    },
    total: 0
  };

  componentDidMount() {
    this.getApprovalSimpleData();
  }

  getApprovalSimpleData = (params = this.state.params) => {
    const {
      match: {
        params: { type, id }
      }
    } = this.props;
    this.setState({ loading: true });
    instance
      .get(`/${type}/${id}/simpleApprovalList`, { params })
      .then(({ data }) => {
        this.setState({
          loading: false,
          params: params,
          ...data
        });
      })
      .catch(err => {
        this.setState({ loading: false });
        errorHandler(err);
      });
  };

  handlePageChange = pageNumber => {
    const { params } = this.state;
    this.getApprovalSimpleData({ ...params, page: pageNumber.selected + 1 });
  };

  actionHandler = (data, action) => {
    const {
      match: {
        params: { type, id }
      }
    } = this.props;
    this.setState({ loading: true });
    instance
      .post(`/${type}/${id}/${action}`, data)
      .then(({ data }) => {
        this.getApprovalSimpleData();
      })
      .catch(err => {
        this.setState({ loading: false });
        errorHandler(err);
      });
  };

  handleApprovePage = () => {
    let dataIds = [];
    let files = [];

    this.state.data.forEach(item => {
      if (item.type === 'file') {
        files = [...files, ...item.new.map(f => f.file.split('/images/')[1])];
      } else {
        dataIds = [...dataIds, item.new.id_data];
      }
    });
    const data = { file: files, data: dataIds };

    this.actionHandler(data, 'approve');
  };

  getOldValue = (filed, language, old) => {
    if (filed === 'sources') {
      return old.source.map((source, i) => (
        <b className="source-code" key={i}>
          <span data-tooltip={source.description}>{source.code}</span>
        </b>
      ));
    } else if (filed === 'villages') {
      return old.villages.map((v, i) => v.name[i18n.language]).join(', ');
    } else if (filed === 'images') {
      return <Files data={old.images} listView={true} />;
    } else if (filed === 'type') {
      return old.type.name ? old.type.name[language] : '---';
    } else if (isObject(old[filed])) {
      return old[filed][language];
    } else {
      return old[filed];
    }
  };

  getNewValue = (filed, value) => {
    if (filed === 'sources') {
      return value.map((source, i) => (
        <b className="source-code" key={i}>
          <span data-tooltip={source.description}>{source.code}</span>
        </b>
      ));
    } else if (filed === 'villages') {
      return value.map((v, i) => v.name[i18n.language]).join(', ');
    } else if (filed === 'images') {
      return <Files data={value} listView={true} />;
    } else {
      return value;
    }
  };

  getFileNames = item => {
    if (item.new) {
      return { file: item.new.map(f => f.file.split('/images/')[1]) };
    } else {
      console.log('Oops!! Something went wrong!');
    }
  };

  render() {
    const { data, old, params, total, loading } = this.state;
    const {
      languages,
      match: {
        params: { type }
      }
    } = this.props;

    return (
      <div>
        {loading ? (
          <Loading />
        ) : data && data.length ? (
          <div className="mb-3">
            <div className="text-right mb-3">
              <Button color="primary" onClick={this.handleApprovePage}>
                <i className="material-icons mr-2">done_all</i>
                {i18n.t('ACCEPT_PAGE')}
              </Button>
            </div>
            <Table className="table-simple table-approval">
              <thead>
                <tr>
                  <th className="col-highlight" colSpan={2}>
                    {i18n.t('OLD_DATA')}
                  </th>
                  <th className="w-50">{i18n.t('NEW_DATA')}</th>
                  <th>{i18n.t('INSTRUCTION')}</th>
                </tr>
              </thead>
              {type === 'village' ? (
                <tbody>
                  {data.map((item, i) => (
                    <Fragment key={i}>
                      <tr className="row-highlight">
                        <td className="col-highlight">
                          {item.category ? (
                            <strong data-tooltip={item.category.path} data-placement="bottom">
                              {item.category.parent} <br />
                              {item.category.name}
                            </strong>
                          ) : null}
                        </td>
                        <td>
                          <strong>
                            {item.archive_year} <br />
                            {item.language ? languages.find(l => item.language === l.language).title : null}
                          </strong>
                        </td>
                        <td colSpan={2}>{item.user.first_name + ' ' + item.user.last_name}</td>
                      </tr>
                      <tr>
                        <td className="col-highlight" colSpan={2}>
                          {item.old ? (
                            <Fragment>
                              {item.type === 'file' ? (
                                <Files data={item.old.map(f => f.file)} listView={true} />
                              ) : (
                                <Fragment>
                                  {item.old.content}
                                  {!!(item.old.sources && item.old.sources.length) && (
                                    <div>
                                      {item.old.sources.map((source, i) => (
                                        <b className="source-code" key={i}>
                                          <span data-tooltip={source.description}>{source.code}</span>
                                        </b>
                                      ))}
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </Fragment>
                          ) : (
                            '---'
                          )}
                        </td>
                        <td>
                          {item.type === 'file' ? (
                            <Files data={item.new.map(f => f.file)} listView={true} />
                          ) : (
                            <Fragment>
                              {item.new.content}
                              {!!(item.new.sources && item.new.sources.length) && (
                                <div>
                                  {item.new.sources.map((source, i) => (
                                    <b className="source-code" key={i}>
                                      <span data-tooltip={source.description}>{source.code}</span>
                                    </b>
                                  ))}
                                </div>
                              )}
                            </Fragment>
                          )}
                        </td>
                        <td className="nowrap p-0">
                          <Button
                            color="link text-danger"
                            onClick={() =>
                              this.actionHandler(
                                item.type === 'file' ? this.getFileNames(item) : { data: [item.new.id_data] },
                                'decline'
                              )
                            }>
                            {i18n.t('CANCEL')}
                          </Button>
                          <Button
                            color="link"
                            onClick={() =>
                              this.actionHandler(
                                item.type === 'file' ? this.getFileNames(item) : { data: [item.new.id_data] },
                                'approve'
                              )
                            }>
                            {i18n.t('CONFIRM')}
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {data.map(({ new: { filed, language, value, id_data }, user }, i) => (
                    <Fragment key={i}>
                      <tr className="row-highlight">
                        <td>
                          <strong>{i18n.t(filed)}</strong>
                        </td>
                        <td>{!!language && <strong>{languages.find(l => language === l.language).title}</strong>}</td>
                        <td colSpan={2}>{user.first_name + ' ' + user.last_name}</td>
                      </tr>
                      <tr>
                        <td className="col-highlight" colSpan={2}>
                          {old[filed] || filed === 'sources' ? this.getOldValue(filed, language, old) : '---'}
                        </td>
                        <td>{value ? this.getNewValue(filed, value) : '---'}</td>
                        <td className="nowrap p-0">
                          <Button
                            color="link text-danger"
                            onClick={() => this.actionHandler({ data: [id_data] }, 'decline')}>
                            {i18n.t('CANCEL')}
                          </Button>
                          <Button color="link" onClick={() => this.actionHandler({ data: [id_data] }, 'approve')}>
                            {i18n.t('CONFIRM')}
                          </Button>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              )}
            </Table>
          </div>
        ) : (
          <div className="empty-result">There is nothing to review yet, thank you</div>
        )}
        <Pagination total={total} limit={params.limit} page={params.page} onPageChange={this.handlePageChange} />
      </div>
    );
  }
}
export default ApprovalSimple;
