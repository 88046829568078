import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Nav, NavItem } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

import { getCount } from '../../../services/helpers';
import Loading from '../../../components/Loading';
import i18n from '../../../locale/i18n';
import { isAdmin } from '../../../services/permissions';

import GroupCategories from '../../../containers/Account/Groups/GroupCategories';
import GroupMembers from '../../../containers/Account/Groups/GroupMembers';
import GroupInvitations from '../../../containers/Account/Groups/GroupInvitations';
import GroupRegions from '../../../containers/Account/Groups/GroupRegions';

class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      notfound: false
    };
  }

  componentDidMount() {
    const {
      getGroup,
      selectGroup,
      match: {
        params: { groupId }
      },
      Groups,
      Users,
      Invitations,
      getInvitations,
      getGroupCategories,
      getGroupRegions,
      getUsers
    } = this.props;

    if (Groups.data.find(item => item.id_group === groupId)) {
      selectGroup(groupId);
    } else {
      getGroup(groupId).catch(err => {
        this.setState({
          notfound: true
        });
      });
    }

    getGroupCategories(groupId);
    getGroupRegions(groupId);

    if (!Invitations.data.length) getInvitations();
    if (!Users.data.length) getUsers();
  }

  componentWillUnmount() {
    this.props.clearGroup();
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleValidSubmit = (event, values) => {
    const {
      Groups: { currentGroup },
      editGroup
    } = this.props;
    editGroup(values, currentGroup.id_group).then(() => {
      this.setState({
        modal: false
      });
    });
  };

  handleDeleteGroup = () => {
    const confirmation = window.confirm(`${i18n.t('DELETE_GROUP_QUESTION')}?`);
    if (confirmation) {
      const {
        Groups: { currentGroup },
        deleteGroup,
        history
      } = this.props;
      deleteGroup(currentGroup.id_group).then(() => {
        history.push('/account/groups');
      });
    }
  };

  render() {
    const {
      Groups: { loading, currentGroup },
      match: {
        params: { groupId }
      },
      Users,
      Invitations
    } = this.props;
    const isCustom = currentGroup && currentGroup.type === 'custom';

    return (
      <div>
        {currentGroup ? (
          <div className="app-content-inner">
            <div className="content-inner-header">
              <h1 className="content-header-title">
                {currentGroup.title}{' '}
                {isCustom && isAdmin() && (
                  <Button
                    color="link"
                    className="text-dark p-0 ml-1"
                    onClick={this.toggleModal}
                    data-tooltip={i18n.t('EDIT')}>
                    <i className="material-icons">mode_edit</i>
                  </Button>
                )}
              </h1>
              <div className="content-header-subtitle">
                <Link to="/account/groups" className="btn btn-link btn-back">
                  <i className="material-icons">&#xE317;</i>
                  {i18n.t('BACK')}
                </Link>
                <ul>
                  {isCustom && (
                    <li>
                      <b>{i18n.t('classes')}:</b> {(currentGroup.categories && currentGroup.categories.length) || 0}
                    </li>
                  )}
                  {isCustom && (
                    <li>
                      <b>{i18n.t('place')}:</b> {(currentGroup.places && currentGroup.places.length) || 0}
                    </li>
                  )}
                  <li>
                    <b>{i18n.t('members')}:</b> {getCount(Users.data, 'id_group', groupId)}
                  </li>
                  <li>
                    <b>{i18n.t('invitations')}:</b> {getCount(Invitations.data, 'id_group', groupId)}
                  </li>
                </ul>
              </div>
              <Nav className="nav-tab">
                {isCustom && (
                  <Fragment>
                    <NavItem>
                      <NavLink className="nav-link" to={`/account/groups/${groupId}/categories`}>
                        {i18n.t('classes')}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className="nav-link" to={`/account/groups/${groupId}/regions`}>
                        {i18n.t('REGION_ACCESS')}
                      </NavLink>
                    </NavItem>
                  </Fragment>
                )}
                <NavItem>
                  <NavLink className="nav-link" to={`/account/groups/${groupId}/members`}>
                    {i18n.t('members')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to={`/account/groups/${groupId}/invitations`}>
                    {i18n.t('invitations')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <Switch>
              {isCustom && <Route exact path={`/account/groups/:groupId/categories`} component={GroupCategories} />}
              {isCustom && <Route exact path={`/account/groups/:groupId/regions`} component={GroupRegions} />}
              <Route exact path={`/account/groups/:groupId/members`} component={GroupMembers} />
              <Route exact path={`/account/groups/:groupId/invitations`} component={GroupInvitations} />
              <Redirect
                to={isCustom ? `/account/groups/${groupId}/categories` : `/account/groups/${groupId}/members`}
              />
            </Switch>
          </div>
        ) : loading ? (
          <Loading />
        ) : (
          <div>
            <h2 className="text-secondary text-center p-5">Not Found</h2>
          </div>
        )}

        <Modal isOpen={this.state.modal} toggle={this.toggleModal} size="sm">
          <ModalHeader toggle={this.toggleModal}>{i18n.t('EDIT_GROUP')}</ModalHeader>
          <AvForm onValidSubmit={this.handleValidSubmit} model={currentGroup}>
            <ModalBody>
              <AvGroup>
                <AvInput name="title" required />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
              <h6 className="mb-3">{i18n.t('role')}</h6>
              <AvRadioGroup name="role" required errorMessage="Pick one!">
                <AvRadio label={i18n.t('moderator')} value="moderator" />
                <AvRadio label={i18n.t('viewer')} value="viewer" />
              </AvRadioGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-danger" onClick={this.handleDeleteGroup}>
                {i18n.t('DELETE')}
              </Button>
              <Button color="link" className="text-dark" onClick={this.toggleModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default Groups;
