import React from 'react';
import { Progress } from 'reactstrap';

const FormProgress = ({ data }) => {
  const filledItems = data.filter((i) => i.content);
  return (
    <div className="form-progress mb-2">
      <Progress color="primary" value={(filledItems.length / data.length) * 100}>
        <span>{`${filledItems.length}/${data.length}`}</span>
      </Progress>
    </div>
  );
};

export default FormProgress;
