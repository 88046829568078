import { connect } from "react-redux"
import ApprovalMerged from "../../pages/Approvals/ApprovalMerged"

const mapStateToProps = state => {
	return {
		languages: state.User.languages
	}
}

export default connect(mapStateToProps)(ApprovalMerged)
