const currentYear = new Date().getFullYear();
export const summaryTabs = [
  { title: 'Պահպանված', key: 'drafts', linkText: 'Շարունակել' },
  { title: 'Ուղարկված', key: 'submitted', linkText: 'Դիտել' },
  { title: 'Հետ ուղարկված', key: 'sentback', linkText: 'Դիտել' },
  { title: 'Հաստատված', key: 'approved', linkText: 'Դիտել' }
];
export const sliderSettings = {
  arrows: true,
  speed: 500,
  dots: false,
  slidesToShow: 7,
  infinite: false,
  swipeToSlide: false
};

export const limits = {
  dataFlat: 15,
  advancedSearch: 50,
  library: 100,
  monuments: 20,
  approval: 50,
  activityLog: 50
};

export const initialLanguages = [{ language: 'am', title: 'Հայերեն', short_title: 'ՀԱՅ' }];

export const approvalNavItems = [
  { label: 'villages', url: '/approvals/village' },
  { label: 'monuments', url: '/approvals/monument' }
];

export const approvalInnerNavItems = (type, id) => [
  { label: 'FAST_APPROVALS', url: `/approval/${type}/${id}/simple` },
  { label: 'MERGED_APPROVALS', url: `/approval/${type}/${id}/merged` }
];

export const monumentColumns = [
  { label: 'ID', key: 'number', available: true, isFixed: true, isSortable: true },
  { label: 'memorial', key: 'name', available: true, isFixed: true, isSortable: true },
  { label: 'village', key: 'village', available: true, isFixed: true, isSortable: true, hideIfVillage: true },
  { label: 'state', key: 'state', available: false, isFixed: true, isSortable: true, hideIfVillage: true },
  { label: 'time', key: 'time', available: true, isFixed: false, isSortable: true },
  { label: 'address2', key: 'address', available: true, isFixed: false, isSortable: true },
  { label: 'description', key: 'description', available: true, isFixed: false, isSortable: true },
  { label: 'data', key: 'data', available: true, isFixed: false, isSortable: true },
  { label: 'source', key: 'source', available: true, isFixed: false, isSortable: false },
  { label: 'nearby_villages', key: 'villages', available: true, isFixed: false, isSortable: false }
];

export const paginationOptions = {
  containerClassName: 'pagination',
  activeClassName: 'active',
  previousLabel: 'Prev',
  pageRangeDisplayed: 10,
  marginPagesDisplayed: 1
};

export const toastOptions = {
  position: 'top-right',
  autoClose: 8000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  pauseOnHover: true
};

export const conditions = [
  { label: 'CONTAIN', key: 'contain' },
  { label: 'NOT_CONTAIN', key: 'not_contain' },
  { label: 'EQUALS', key: 'equals' },
  { label: 'LESS', key: 'less' },
  { label: 'MORE', key: 'more' }
];
export const queryCondition = [{ label: 'AND', key: 'and' }, { label: 'OR', key: 'or' }];

export const archiveYears = Array.from({ length: currentYear - 1899 }, (v, i) => currentYear - i);

export const alphabet = {
  am: [
    'ա',
    'բ',
    'գ',
    'դ',
    'ե',
    'զ',
    'է',
    'ը',
    'թ',
    'ժ',
    'ի',
    'լ',
    'խ',
    'ծ',
    'կ',
    'հ',
    'ձ',
    'ղ',
    'ճ',
    'մ',
    'յ',
    'ն',
    'շ',
    'ո',
    'չ',
    'պ',
    'ջ',
    'ռ',
    'ս',
    'վ',
    'տ',
    'ր',
    'ց',
    'ու',
    'փ',
    'ք',
    'և',
    'օ',
    'ֆ'
  ],
  ru: [
    'а',
    'б',
    'в',
    'г',
    'д',
    'е',
    'ё',
    'ж',
    'з',
    'и',
    'й',
    'к',
    'л',
    'м',
    'н',
    'о',
    'п',
    'р',
    'с',
    'т',
    'у',
    'ф',
    'х',
    'ц',
    'ч',
    'ш',
    'щ',
    'ъ',
    'ы',
    'ь',
    'э',
    'ю',
    'я'
  ],
  en: [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
};

export const colors = [
  '#FEDC32',
  '#1791C7',
  '#92C548',
  '#E91E26',
  '#F8BC96',
  '#C2B1EE',
  '#F2FBFF',
  '#ECFED2',
  '#DCECF2',
  '#FEDC32',
  '#1791C7',
  '#92C548',
  '#E91E26',
  '#F8BC96',
  '#C2B1EE',
  '#F2FBFF',
  '#ECFED2',
  '#DCECF2',
  '#FEDC32',
  '#1791C7',
  '#92C548',
  '#E91E26',
  '#F8BC96',
  '#C2B1EE',
  '#F2FBFF',
  '#ECFED2',
  '#DCECF2',
  '#FEDC32',
  '#1791C7',
  '#92C548',
  '#E91E26',
  '#F8BC96',
  '#C2B1EE',
  '#F2FBFF',
  '#ECFED2',
  '#DCECF2'
];
