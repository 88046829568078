import React, { Component } from 'react';
import Checkbox from './Checkbox';

class CategoryTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: []
    };
  }

  toggleCollapse = catId => {
    this.setState(({ expanded }) => {
      return {
        expanded: !expanded.includes(catId) ? [...expanded, catId] : expanded.filter(id => id !== catId)
      };
    });
  };

  collapsAll = () => {
    this.setState({
      expanded: []
    });
  };

  categoryList = arr => {
    const lang = this.props.User.language;
    const { expanded } = this.state;
    if (Boolean(arr))
      return (
        <ul className="panel-list">
          {arr.map((item, index) => {
            const vars = {
              checked: item.is_checked === 2,
              indeterminate: item.is_checked === 1
            };
            return (
              <li className={`list-item ${expanded.includes(item.id_category) ? ' active' : ''}`} key={index}>
                <div className="item-header" onClick={e => item.children && this.toggleCollapse(item.id_category)}>
                  <div className="item-title">
                    <Checkbox
                      name={item.id_category}
                      className="custom-control-input"
                      checked={vars.checked}
                      indeterminate={vars.indeterminate.toString()}
                      onChange={e => this.props.onCategoryToggle(item, e.target.checked)}
                    />
                    {item.name[lang]}
                  </div>
                  {item.children && <i className="material-icons item-arrow">keyboard_arrow_down</i>}
                </div>
                {item.children && expanded.includes(item.id_category) && (
                  <div className="item-body">{this.categoryList(item.children)}</div>
                )}
              </li>
            );
          })}
        </ul>
      );
  };

  render() {
    // const { tree } = this.state
    const {
      Categories: { data }
    } = this.props;
    return <div className="sidebar-nav">{this.categoryList(data)}</div>;
  }
}

export default CategoryTree;
