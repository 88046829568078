import { connect } from 'react-redux';
import Group from '../../../pages/Account/Groups/Group';
import {
  getGroup,
  getGroupCategories,
  getGroupRegions,
  selectGroup,
  clearGroup,
  editGroup,
  deleteGroup
} from '../../../actions/Groups';
import { getInvitations } from '../../../actions/Invitations';
import { getUsers } from '../../../actions/Users';

const mapStateToProps = state => {
  return {
    Groups: state.Groups,
    Users: state.Users,
    Invitations: state.Invitations
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGroup(id) {
      return dispatch(getGroup(id));
    },
    getGroupCategories(id) {
      dispatch(getGroupCategories(id));
    },
    getGroupRegions(id) {
      dispatch(getGroupRegions(id));
    },
    selectGroup(id) {
      dispatch(selectGroup(id));
    },
    clearGroup() {
      dispatch(clearGroup());
    },
    editGroup(body, id) {
      return dispatch(editGroup(body, id));
    },
    deleteGroup(id) {
      return dispatch(deleteGroup(id));
    },
    getInvitations() {
      dispatch(getInvitations());
    },
    getUsers() {
      dispatch(getUsers());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Group);
