import instance from "../services/api"
import errorHandler from "../services/errorHandler"
import { saveData } from "../services/helpers"

export const getVillages = () => {
  return dispatch => {
    // dispatch({ type: "VILLAGES_REQ" })
    return instance
      .get(`/village`)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGES_RES", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const filterVillage = params => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .get(`/village/filter`, { params: params })
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_FILTERED", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const getVillageFiles = id => {
  return dispatch => {
    return instance
      .get(`/village/${id}/file`)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_FILES_RES", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const getVillage = (id, archive_year) => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .get(`/village/${id}/data`, { params: { archive_year } })
      .then(({ data }) => {
        dispatch(getVillageFiles(id))
        return dispatch({ type: "VILLAGE_RES", payload: { ...data, archive_year } })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const exportVillage = id => {
  return dispatch => {
    return instance
      .get(`/village/${id}/export`, { responseType: "arraybuffer" })
      .then(res => {
        return saveData(res)
      })
      .catch(errorHandler)
  }
}

export const addVillage = body => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .post(`/village`, body)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_ADDED", payload: data.id_village })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const editVillageData = (body, id) => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .put(`/village/${id}/data`, body)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_EDITED", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const deleteVillage = id => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .delete(`/village/${id}`)
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_DELETED", payload: id })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const addVillageFilles = (files, id) => {
  return dispatch => {
    return instance
      .post(`/village/${id}/file`, files)
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_FILES_RES", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const deleteVillageFile = (file, id) => {
  return dispatch => {
    return instance
      .delete(`/village/${id}/file`, { data: { file: file.split("/images/")[1] } })
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_FILE_DELETED", payload: file })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const getVillageMonuments = id => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .get(`/village/${id}/monuments`)
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_MONUMENTS_RES", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const addVillageMonuments = (body, id) => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .put(`/village/${id}/monuments`, body)
      .then(({ data }) => {
        // dispatch({ type: "VILLAGE_MONUMENTS_ADDED", payload: data });
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const editVillageMonuments = (body, id) => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .put(`/village/${id}/monuments`, body)
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_MONUMENTS_EDITED", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const deleteVillageMonuments = id => {
  return dispatch => {
    dispatch({ type: "VILLAGES_REQ" })
    return instance
      .delete(`/village/${id}/monuments`)
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_MONUMENTS_DELETED", payload: id })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const clearCompare = () => {
  return dispatch => {
    dispatch({ type: "VILLAGES_COMPARE_CLEAR" })
  }
}

export const getCompareData = params => {
  return dispatch => {
    dispatch({ type: "VILLAGES_COMPARE_REQ", payload: params })
    return instance
      .post(`/village/compare`, params)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_COMPARE_RES", payload: data })
      })
      .catch(err => {
        dispatch({ type: "VILLAGE_ERROR" })
        errorHandler(err)
      })
  }
}

export const getCompareNumericData = params => {
  return dispatch => {
    return instance.post(`/village/compareNumeric`, params)
  }
}

export const exportCompareData = body => {
  return dispatch => {
    return instance
      .post(`/village/compare`, body, { params: { export: 1 }, responseType: "arraybuffer" })
      .then(res => {
        return saveData(res)
      })
      .catch(errorHandler)
  }
}

export const saveCompare = body => {
  return dispatch => {
    return instance
      .post(`/village/compareSave`, body)
      .then(({ data }) => {
        return dispatch({ type: "COMPARE_SAVED", payload: data })
      })
      .catch(errorHandler)
  }
}

export const getCompareList = () => {
  return dispatch => {
    return instance
      .get(`/village/compareSaveList`)
      .then(({ data }) => {
        dispatch({ type: "COMPARE_LIST", payload: data })
      })
      .catch(errorHandler)
  }
}

export const getBookmarks = () => {
  return dispatch => {
    return instance
      .get(`/village/bookmark`)
      .then(({ data }) => {
        dispatch({ type: "VILLAGE_BOOKMARKS_RES", payload: data })
      })
      .catch(errorHandler)
  }
}

export const addBookmark = body => {
  return dispatch => {
    return instance
      .post(`/village/bookmark`, body)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_BOOKMARK_ADD", payload: data })
      })
      .catch(errorHandler)
  }
}

export const editBookmark = (body, id) => {
  return dispatch => {
    return instance
      .put(`/village/bookmark/${id}`, body)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_BOOKMARK_EDITED", payload: data })
      })
      .catch(errorHandler)
  }
}

export const deleteBookmark = id => {
  return dispatch => {
    return instance
      .delete(`/village/bookmark/${id}`)
      .then(({ data }) => {
        return dispatch({ type: "VILLAGE_BOOKMARK_DELETE", payload: id })
      })
      .catch(errorHandler)
  }
}
