import React from 'react';
import { Button, UncontrolledDropdown as Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import i18n from '../../locale/i18n';
import classnames from 'classnames';

const HeaderCol = props => {
  const { toggleColumn, handleSort, col, params } = props;
  const { key, fixed, label, parent_label, full_path, sortable } = col;
  const { order_column, order_by } = params;

  return (
    <th key={key} className={`table-header ${fixed ? 'fixed' : ''}`}>
      <div className="table-header-inner">
        <Button color="link" title="Hide column" onClick={e => toggleColumn(key)}>
          <i className="material-icons">remove_circle</i>
        </Button>
        {sortable === false ? (
          <div className="table-header-tooltip" data-tooltip={full_path}>
            {parent_label ? <small>{parent_label}</small> : null}
            <span className="cell-text">{label}</span>
          </div>
        ) : (
          <Dropdown tag="span" className={classnames({ in: order_column === key })}>
            <DropdownToggle tag="div" className="th-toggle">
              <div className="table-header-tooltip" data-tooltip={full_path}>
                {parent_label ? <small>{parent_label}</small> : null}
                <span className="cell-text">{label}</span>
              </div>
              <i className="material-icons">more_vert</i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                className={classnames({
                  active: order_column === key && order_by === 'desc'
                })}
                onClick={() => handleSort(key, 'desc')}>
                <i className="icon icon-sort" />
                {i18n.t('DESCENDING')}
              </DropdownItem>
              <DropdownItem
                className={classnames({
                  active: order_column === key && order_by === 'asc'
                })}
                onClick={() => handleSort(key, 'asc')}>
                <i className="icon icon-sort" />
                {i18n.t('ASCENDING')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
    </th>
  );
};

export default HeaderCol;
