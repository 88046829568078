const Sources = (
  state = {
    data: [],
    total: 0,
    page: 1,
    keyword: "",
    limit: 1000,
    current: null,
    loading: false
  },
  action
) => {
  switch (action.type) {
    case "SOURCES_REQ":
      return {
        ...state,
        loading: true
      }
    case "SOURCES_SEARCH_REQ":
      return {
        ...state,
        ...action.payload
      }
    case "SOURCES_RES":
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case "SOURCE_RES":
      return {
        ...state,
        current: action.payload,
        loading: false
      }
    case "SOURCE_ADDED":
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      }
    case "SOURCE_EDITED":
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id_source === action.payload.id_source) {
            return action.payload
          } else {
            return item
          }
        }),
        loading: false
      }
    case "SOURCE_DELETED":
      return {
        ...state,
        data: state.data.filter(i => i.id_source !== action.payload),
        loading: false
      }
    default:
      return state
  }
}

export default Sources
