import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { Table } from "reactstrap"
import i18n from "../../locale/i18n"
import { formatDate } from "../../services/formatDate"
import Pagination from "../../components/Pagination"

class ActivityLog extends Component {
	componentDidMount() {
		const { ActivityLog: { data, params }, getActivityLogs } = this.props
		if (!data) {
			getActivityLogs(params)
		}
	}

	handlePageChange = pageNumber => {
		const { ActivityLog: { params }, getActivityLogs } = this.props
		getActivityLogs({ ...params, page: pageNumber.selected + 1 })
	}

	handleChangeYear = year => {
		const { ActivityLog: { params }, getActivityLogs } = this.props
		getActivityLogs({ ...params, year })
	}

	render() {
		const { ActivityLog: { data, params, years, total } } = this.props
		const dataDates = data ? Object.keys(data) : []

		return (
			<div>
				<div className="app-content-header">
					<h1>{i18n.t("ACTIVITY_LOG")}</h1>
				</div>
				<div className="app-content-inner">
					<h2 className="mb-3">{params.year || " "}</h2>
					<div className="activity-log">
						<div className="activity-log-content">
							<div className="content-box">
								{data ? (
									<Table className="table-simple table-log">
										<thead>
											<tr>
												<th>{i18n.t("WHEN")}</th>
												<th>{i18n.t("USER")}</th>
												<th>{i18n.t("ACTIVITY_TYPE")}</th>
												<th>{i18n.t("OBJECT")}</th>
											</tr>
										</thead>
										<tbody>
											{dataDates.map((date, i) => (
												<Fragment key={i}>
													<tr className="row-highlight">
														<td colSpan={4}>{formatDate(date)}</td>
													</tr>
													{data[date].map((log, j) => (
														<tr key={j}>
															<td>{log.hour}</td>
															<td>
																{log.user.first_name} {log.user.last_name}
															</td>
															<td>{log.action}</td>
															<td>
																<Link to={log.object.uri}>{log.object.message}</Link>
															</td>
														</tr>
													))}
												</Fragment>
											))}
										</tbody>
									</Table>
								) : (
									<div className="empty-result">No results</div>
								)}
							</div>
							<Pagination total={total} limit={params.limit} page={params.page} onPageChange={this.handlePageChange} />
						</div>
						<div className="vertical-filter">
							<div className="form-group">
								{years.map((year, i) => (
									<div className="form-check" key={i}>
										<label className="form-check-label">
											<input
												type="checkbox"
												name="year"
												value={year}
												onChange={e => this.handleChangeYear(e.target.value)}
												checked={year === params.year}
												className="form-check-input"
											/>
											<span>{year}</span>
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ActivityLog
// getActivityLogs
// getActivityLog
// deleteActivityLog
