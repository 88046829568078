import instance from '../services/api';
import errorHandler from '../services/errorHandler';

export const getForms = () => {
  return (dispatch) => {
    dispatch({ type: 'FORMS_REQ' });
    return instance
      .get(`/forms`)
      .then(({ data }) => {
        return dispatch({ type: 'FORMS_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

export const getForm = (id) => {
  return (dispatch) => {
    return instance
      .get(`/forms/${id}`)
      .then(({ data }) => {
        return dispatch({ type: 'FORM_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

export const getFormsSummary = () => {
  return (dispatch) => {
    dispatch({ type: 'FORMS_REQ' });
    return instance
      .get(`/forms/summary`)
      .then(({ data }) => {
        return dispatch({ type: 'FORMS_SUMMARY_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

export const resetCurrent = () => {
  return (dispatch) => {
    dispatch({ type: 'FORMS_RESET_CURRENT' });
  };
};

export const saveDraft = (body) => {
  return (dispatch) => {
    return instance
      .post('/forms/save', body)
      .then(({ data }) => {
        return dispatch({ type: 'FORMS_DRAFT_RES', payload: body });
      })
      .catch(errorHandler);
  };
};
