import { connect } from "react-redux"
import Comparisons from "../../pages/MyListings/Comparisons"
import { getCompareList } from "../../actions/Villages"

const mapStateToProps = state => {
	return {
		User: state.User
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getCompareList() {
			dispatch(getCompareList())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Comparisons)
