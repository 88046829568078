import instance from '../services/api';
import errorHandler from '../services/errorHandler';

const getAllCategories = () => {
  return dispatch => {
    dispatch({ type: 'CATEGORY_REQ' });
    return instance
      .get(`/category/all`)
      .then(({ data }) => {
        dispatch({ type: 'CATEGORIES_ALL_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

const getCategories = () => {
  return dispatch => {
    dispatch({ type: 'CATEGORY_REQ' });
    return instance
      .get(`/category`)
      .then(({ data }) => {
        dispatch({ type: 'CATEGORIES_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

const getContentTypes = () => {
  return dispatch => {
    return instance
      .get(`/category/contentTypes`)
      .then(({ data }) => {
        return dispatch({ type: 'CATEGORY_CONTENT_TYPES', payload: data });
      })
      .catch(errorHandler);
  };
};

const addContentType = body => {
  return dispatch => {
    return instance
      .post(`/category/contentTypes`, body)
      .then(({ data }) => {
        return dispatch({ type: 'CATEGORY_CONTENT_TYPE_ADD', payload: data });
      })
      .catch(errorHandler);
  };
};

const editContentType = (body, id) => {
  return dispatch => {
    return instance
      .put(`/category/contentTypes/${id}`, body)
      .then(({ data }) => {
        return dispatch({ type: 'CATEGORY_CONTENT_TYPE_EDIT', payload: data });
      })
      .catch(errorHandler);
  };
};
const deleteContentType = id => {
  return dispatch => {
    return instance
      .delete(`/category/contentTypes/${id}`)
      .then(({ data }) => {
        return dispatch({ type: 'CATEGORY_CONTENT_TYPE_DELETE', payload: id });
      })
      .catch(errorHandler);
  };
};

const toggleCategory = (category, checked) => {
  return dispatch => {
    return Promise.resolve(dispatch({ type: 'CATEGORY_TOGGLE', payload: { category, checked } }));
  };
};
const categoriesUpdated = tree => {
  return dispatch => {
    dispatch({ type: 'CATEGORIES_RES', payload: tree });
  };
};

const updateCategoriesBySlug = slugs => {
  return dispatch => {
    dispatch({ type: 'CATEGORIES_BY_SLUGS', payload: slugs });
  };
};

const addCategory = body => {
  return dispatch => {
    dispatch({ type: 'CATEGORY_REQ' });
    return instance
      .post(`/category`, body)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          dispatch({ type: 'CATEGORY_ADDED', payload: data });
        }
      })
      .catch(errorHandler);
  };
};

const editCategory = (body, id) => {
  return dispatch => {
    dispatch({ type: 'CATEGORY_REQ' });
    return instance
      .put(`/category/${id}`, body)
      .then(({ data }) => {
        dispatch({ type: 'CATEGORY_EDITED', payload: data });
      })
      .catch(errorHandler);
  };
};

const deleteCategory = id => {
  return dispatch => {
    dispatch({ type: 'CATEGORY_REQ' });
    return instance
      .delete(`/category/${id}`)
      .then(({ data }) => {
        dispatch(getCategories());
      })
      .catch(errorHandler);
  };
};

const getCategory = id => {
  return dispatch => {
    dispatch({ type: 'CATEGORY_REQ' });
    return instance
      .get(`/category/${id}`)
      .then(({ data }) => {
        dispatch({ type: 'CATEGORY_RES', payload: data });
      })
      .catch(errorHandler);
  };
};

export {
  getCategories,
  getAllCategories,
  categoriesUpdated,
  addCategory,
  editCategory,
  deleteCategory,
  getCategory,
  updateCategoriesBySlug,
  toggleCategory,
  getContentTypes,
  addContentType,
  editContentType,
  deleteContentType
};
