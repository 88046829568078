import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { Navbar, Nav, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { baseUrl } from "../services/helpers";
import { isAdmin, isViewer, isModerator } from "../services/permissions";

import i18n from "../locale/i18n";

class Header extends Component {
  state = {
    dropdownNots: false,
    dropdownProfile: false
  };

  toggle = () => {
    this.setState({
      dropdownNots: !this.state.dropdownNots
    });
  };

  toggleProfile = () => {
    this.setState({
      dropdownProfile: !this.state.dropdownProfile
    });
  };

  isActiveRoute = (match, location) => {
    return location.pathname.indexOf("/monument") === 0;
  };

  isHomePage = (match, location) => {
    return location.pathname === "/";
  };

  getNameLetters = ({ first_name, last_name }) => {
    if (first_name && last_name) {
      return first_name[0] + last_name[0];
    }
  };

  render() {
    const { User } = this.props;

    return (
      <header className="app-header">
        <Navbar color="primary" dark className="text-white">
          <NavLink className="navbar-brand" to="/" isActive={this.isHomePage}>
            <img src={baseUrl("img/ic_dashboard.svg")} alt="logo" className="mr-2" /> {i18n.t("villages")}
          </NavLink>
          <NavLink className="navbar-brand" to="/forms">
            <i className="nav-icon material-icons mr-2">playlist_add_check</i>
            {i18n.t("FORMS")}
          </NavLink>
          <div className="navbar-search" />
          <Nav navbar className="align-items-center">
            {isAdmin() && (
              <NavLink className="navbar-brand" to="/approvals">
                <i className="material-icons">notifications_active</i>
              </NavLink>
            )}
            {isModerator() && (
              <NavLink className="navbar-brand" to="/approvals">
                <i className="material-icons">notifications_active</i>
              </NavLink>
            )}
            {User.data && (
              <Dropdown nav={true} isOpen={this.state.dropdownProfile} toggle={this.toggleProfile}>
                <DropdownToggle
                  tag="a"
                  className="p-0"
                  onClick={this.toggleProfile}
                  data-toggle="dropdown"
                  aria-expanded={this.state.dropdownProfile}>
                  <span className="avatar avatar-info">{this.getNameLetters(User.data)}</span>
                </DropdownToggle>
                <DropdownMenu right>
                  <Link className="dropdown-item" onClick={this.toggleProfile} to="/account/my-profile">
                    <i className="material-icons">settings</i>
                    {i18n.t("SETTINGS")}
                  </Link>

                  <Link className="dropdown-item" onClick={this.toggleProfile} to="/my-listings">
                    <i className="material-icons">list</i>
                    {i18n.t("MY_LISTINGS")}
                  </Link>
                  <Link className="dropdown-item" onClick={this.toggleProfile} to="/libraries">
                    <i className="material-icons">library_books</i>
                    {i18n.t("SOURCES_LIBRARIES")}
                  </Link>
                  <a
                    href={i18n.t("ASSESSMENT_METHODOLOGY_LINK")}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown-item">
                    <i className="material-icons mr-2">picture_as_pdf</i>
                    {i18n.t("ASSESSMENT_METHODOLOGY")}
                  </a>
                  {isAdmin() && (
                    <a
                      href="http://rtd-guide.codics.solutions/admin.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item">
                      <i className="material-icons mr-2">info</i>
                      {i18n.t("USER_GUIDE_MANUAL")}
                    </a>
                  )}
                  {isViewer() && (
                    <a
                      href="http://rtd-guide.codics.solutions/viewer.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item">
                      <i className="material-icons mr-2">info</i>
                      {i18n.t("USER_GUIDE_MANUAL")}
                    </a>
                  )}
                  {isModerator() && (
                    <a
                      href="http://rtd-guide.codics.solutions/moderator.html"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="dropdown-item">
                      <i className="material-icons mr-2">info</i>
                      {i18n.t("USER_GUIDE_MANUAL")}
                    </a>
                  )}
                  <button className="dropdown-item" onClick={this.props.logout}>
                    <i className="material-icons">exit_to_app</i>
                    {i18n.t("LOGOUT")}
                  </button>
                </DropdownMenu>
              </Dropdown>
            )}
          </Nav>
        </Navbar>
      </header>
    );
  }
}

export default Header;
