const initialState = {
  data: null,
  params: {
    page: 1,
    limit: 50
  },
  current: null,
  total: 0,
  loading: false
}

const Approvals = (state = initialState, action) => {
  switch (action.type) {
    case "APPROVALS_REQ":
      return {
        ...state,
        params: { ...state.params, ...(action.payload || {}) },
        loading: true
      }
    case "APPROVALS_RES":
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    case "APPROVAL_RES":
      return {
        ...state,
        current: action.payload,
        loading: false
      }
    case "APPROVAL_DELETED":
      return {
        ...state,
        data: state.data.filter(i => i.id_action !== action.payload),
        loading: false
      }
    case "APPROVALS_ERR":
      return {
        ...state,
        loading: false
      }
    case "USER_LOGOUT":
      return {
        ...initialState
      }
    default:
      return state
  }
}

export default Approvals
