import React, { Component, Fragment } from "react"
import { Table } from "reactstrap"
import { formatDate } from "../../services/formatDate"
import errorHandler from "../../services/errorHandler"
import Pagination from "../../components/Pagination"
import Loading from "../../components/Loading"
import instance from "../../services/api"
import i18n from "../../locale/i18n"

class ApprovalList extends Component {
	state = {
		loading: false,
		data: null,
		type: this.props.match.params.type,
		params: {
			date: null,
			page: 1,
			limit: 50
		},
		total: 0
	}

	componentDidMount() {
		this.getApprovalList()
	}

	componentDidUpdate(prevProps, prevState) {
		const newType = this.props.match.params.type
		const prevType = prevProps.match.params.type
		if (newType !== prevType) {
			const { params } = this.state
			this.getApprovalList({ ...params, page: 1 }, newType)
		}
	}

	getApprovalList = (params = this.state.params, type = this.state.type) => {
		const url = type === "village" ? "/data/approvalList" : "/monument/approvalList"
		this.setState({ loading: true })
		instance
			.get(url, { params })
			.then(({ data }) => {
				this.setState({
					loading: false,
					type,
					params,
					...data
				})
			})
			.catch(err => {
				this.setState({ loading: false })
				errorHandler(err)
			})
	}

	handlePageChange = pageNumber => {
		const { params } = this.state
		this.getApprovalList({ ...params, page: pageNumber.selected + 1 })
	}

	render() {
		const { data, params, total, type, loading } = this.state
		const { history } = this.props
		const dataDates = data ? Object.keys(data) : []

		return (
			<div>
				{loading ? (
					<Loading />
				) : dataDates.length ? (
					<Table className="table-simple table-log">
						<thead>
							<tr>
								<th>{i18n.t("WHEN")}</th>
								<th>{i18n.t("OBJECT")}</th>
								<th>{i18n.t("ACTIVITY_TYPE")}</th>
								<th>{i18n.t("USER")}</th>
							</tr>
						</thead>
						<tbody>
							{dataDates.map((date, i) => (
								<Fragment key={i}>
									<tr className="row-highlight">
										<td colSpan={4}>{formatDate(date, "DD MMMM")}</td>
									</tr>
									{data[date].map((item, j) => (
										<tr
											key={j}
											className="with-action"
											onClick={() => history.push(`/approval/${type}/${item.object[`id_${type}`]}`)}>
											<td>{item.hour}</td>
											<td>{item.object.name}</td>
											<td>{item.action}</td>
											<td>{item.user.first_name + " " + item.user.last_name}</td>
										</tr>
									))}
								</Fragment>
							))}
						</tbody>
					</Table>
				) : (
					<div className="empty-result">There is nothing to review yet, thank you</div>
				)}
				<Pagination total={total} limit={params.limit} page={params.page} onPageChange={this.handlePageChange} />
			</div>
		)
	}
}
export default ApprovalList
