import { connect } from 'react-redux';
import Register from '../pages/Register';
import { registration } from '../actions/Auth';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    registration(body, code) {
      return dispatch(registration(body, code, props.history));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
