import React, { Component } from "react"
import { Button } from "reactstrap"

import i18n from "../../locale/i18n"

class SavedSearches extends Component {
	componentDidMount() {
		const { Data: { advancedSearch: { list } }, getAdvanceSearchList } = this.props
		if (!list.length) getAdvanceSearchList()
	}

	applySearch = params => {
		const { advanceSearch, history } = this.props

		advanceSearch(params)
		history.push({
			pathname: "/advanced-search/results"
		})
	}
	render() {
		const { Data: { advancedSearch: { list } } } = this.props
		return (
			<div>
				<div className="app-content-header">
					<h1>Պահպանած Որոնումներ</h1>
				</div>
				<div className="app-content-inner">
					<div className="content-container content-sm">
						<ul className="panel-list">
							{list.map((search, index) => (
								<li className="list-item" key={index}>
									<div className="item-header">
										<div className="item-title">{search.name}</div>
										<div className="item-actions">
											<Button
												color="link px-2"
												onClick={e => this.applySearch(search.params)}
												data-tooltip={i18n.t("APPLY_SEARCH")}>
												<i className="material-icons">forward</i>
											</Button>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default SavedSearches
