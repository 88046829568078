import React from 'react';
import classnames from 'classnames';
import { Col, FormGroup, Label } from 'reactstrap';
import FormFieldInput from './FormFieldInput';
import FormFieldPreview from './FormFieldPreview';

const FormField = ({ field, formData, onChange, toggleRejected, isGrid, showInputs }) => {
  const data = formData[field.name];
  if (!data) {
    return null;
  }
  return (
    <Col lg={isGrid ? 6 : 12} md={isGrid ? 6 : 12}>
      <FormGroup className={classnames({ 'form-row': !isGrid })}>
        <Label for={field.name} className="simple-label">
          {field.label}
        </Label>
        <div className="form-control-wrapper">
          {showInputs ? (
            <FormFieldInput field={field} data={data} onChange={onChange} />
          ) : (
            <FormFieldPreview field={field} data={data} toggleRejected={toggleRejected} />
          )}
        </div>
      </FormGroup>
    </Col>
  );
};

export default FormField;
