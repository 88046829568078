import { connect } from 'react-redux';
import FormPage from '../../pages/Forms/FormPage';
import { getForm, saveDraft, getFormsSummary } from '../../actions/Forms';
import { editVillageData } from '../../actions/Villages';

const mapStateToProps = (state) => {
  return {
    Forms: state.Forms,
    Regions: state.User.regions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getForm(id) {
      return dispatch(getForm(id));
    },
    updateForms() {
      dispatch(getFormsSummary());
    },
    saveDraft(body) {
      return dispatch(saveDraft(body));
    },
    editVillageData(body, id) {
      return dispatch(editVillageData(body, id));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormPage);
