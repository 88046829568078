import { connect } from "react-redux"
import Sources from "../../pages/Libraries/Sources"
import { getSources, searchSources, addSource, editSource, deleteSource } from "../../actions/Sources"

const mapStateToProps = state => {
	return {
		User: state.User,
		Sources: state.Sources
	}
}

const mapDispatchToProps = dispatch => {
	return {
		getSources(params) {
			dispatch(getSources(params))
		},
		searchSources(params) {
			dispatch(searchSources(params))
		},
		addSource(body) {
			return dispatch(addSource(body))
		},
		editSource(body, id) {
			return dispatch(editSource(body, id))
		},
		deleteSource(id) {
			return dispatch(deleteSource(id))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Sources)
