import { updateCategoriesBySlug, clone, toggleOne, getCategorySlugs, decodeTypes } from "../services/helpers"

const initialState = {
  data: [],
  slugs: [],
  all: [],
  updated: false,
  contentTypes: [],
  loading: false
}

const Categories = (state = initialState, action) => {
  switch (action.type) {
    case "CATEGORY_REQ":
      return {
        ...state,
        loading: true
      }
    case "CATEGORIES_ALL_RES":
      return {
        ...state,
        all: action.payload,
        loading: false
      }
    case "CATEGORIES_RES":
      return {
        ...state,
        data: action.payload,
        slugs: getCategorySlugs(action.payload),
        loading: false
      }
    case "CATEGORY_ADDED":
      return {
        ...state,
        data: action.payload,
        slugs: getCategorySlugs(action.payload),
        loading: false
      }
    case "CATEGORY_DELETED":
      return {
        ...state,
        data: action.payload,
        slugs: getCategorySlugs(action.payload),
        loading: false
      }
    case "CATEGORY_EDITED":
      return {
        ...state,
        data: action.payload,
        slugs: getCategorySlugs(action.payload),
        loading: false
      }
    case "CATEGORIES_BY_SLUGS":
      return {
        ...state,
        updated: true,
        data: state.data.length ? updateCategoriesBySlug(state.data, action.payload) : [],
        loading: false
      }
    case "CATEGORY_TOGGLE":
      const data = clone(state.data)
      const { category, checked } = action.payload
      toggleOne(data, category.id_category, checked, data)
      return {
        ...state,
        data: data,
        loading: false
      }
    case "USER_LOGOUT":
      return {
        ...initialState
      }
    case "CATEGORY_CONTENT_TYPES":
      return {
        ...state,
        contentTypes: decodeTypes(action.payload)
      }
    case "CATEGORY_CONTENT_TYPE_ADD":
      return {
        ...state,
        contentTypes: decodeTypes(action.payload)
      }
    case "CATEGORY_CONTENT_TYPE_EDIT":
      return {
        ...state,
        contentTypes: decodeTypes(action.payload)
      }
    case "CATEGORY_CONTENT_TYPE_DELETE":
      return { ...state, contentTypes: state.contentTypes.filter(t => t.id_content_type !== action.payload) }
    default:
      return state
  }
}

export default Categories
