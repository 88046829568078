import React, { Component, Fragment } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col } from "reactstrap"
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation"
import { alphabet, limits } from "../../constants/"
import Loading from "../../components/Loading"
import Pagination from "../../components/Pagination"
import { isAdmin } from "../../services/permissions"
import i18n from "../../locale/i18n"

class Library extends Component {
	state = {
		library: null,
		modal: false
	}

	componentDidMount() {
		const { Library: { data }, getLibrary } = this.props
		const params = {
			limit: limits.library,
			lang: i18n.language,
			page: 1
		}
		if (!data.length) getLibrary(params)
	}

	handleChangeLang = lang => {
		const { Library: { params } } = this.props
		this.props.searchLibrary({
			...params,
			first_letter: "",
			keyword: "",
			page: 1
		})
	}

	handleChangeLetter = letter => {
		const { Library: { params } } = this.props
		this.props.searchLibrary({
			...params,
			keyword: null,
			first_letter: letter === params.first_letter ? "" : letter,
			page: 1
		})
	}

	handleChangeKeyword = e => {
		const { Library: { params } } = this.props
		const value = e.target.value
		if (value === value.trim()) {
			this.props.searchLibrary({
				...params,
				keyword: value.trim(),
				first_letter: "",
				page: 1
			})
		}
	}

	handlePageChange = pageNumber => {
		const { Library: { params } } = this.props
		this.props.searchLibrary({ ...params, page: pageNumber.selected + 1 })
	}

	handleEdit = library => {
		this.setState({
			library: library,
			modal: true
		})
	}
	toggleModal = () => {
		this.setState(state => {
			return {
				library: null,
				modal: !state.modal
			}
		})
	}

	handleDelete = library => {
		const { Library: { lang } } = this.props
		const confirmation = window.confirm(`${i18n.t("DELETE_LIBRARY_QUESTION")} "${library.library}"?`)
		if (confirmation) {
			this.props.deleteLibrary(library.id_library, lang)
		}
	}

	handleSubmit = (e, data) => {
		const { library } = this.state
		const { Library: { lang }, addLibrary, editLibrary } = this.props

		if (library) {
			editLibrary(data, library.id_library, lang).then(() => this.setState({ library: null, modal: false }))
		} else {
			addLibrary(data, lang).then(() => this.setState({ modal: false }))
		}
	}

	render() {
		const { User: { languages }, Library: { data, loading, params, total } } = this.props
		const { modal, library } = this.state
		return (
			<Fragment>
				<div className="app-content-header">
					<h1>Աղբյուրներ և Բառարան</h1>
				</div>
				<div className="app-content-inner library-page-content-wrapper">
					<div className="app-content-filter">
						<Row>
							<Col md={5}>
								<div className="form-group p-0">
									<Input
										name="keyword"
										value={params.keyword}
										onChange={this.handleChangeKeyword}
										placeholder="Որոնում բանալի բառով"
									/>
								</div>
							</Col>
							<Col md={7} className="text-right">
								<fieldset name="lang" className="filter-lang form-group">
									{languages.map((item, i) => (
										<div className="form-check" key={i}>
											<label className="form-check-label ">
												<Input
													name="lang"
													type="radio"
													value={item.language}
													onChange={e => this.handleChangeLang(e.target.value)}
													checked={item.language === params.lang}
													className="form-check-input"
												/>
												<span>{item.short_title}</span>
											</label>
										</div>
									))}
								</fieldset>
							</Col>
						</Row>
					</div>
					{Boolean(data.length) && (
						<h2 className="mb-2 capitalize">
							{data[0].library}-{data[data.length - 1].library}
						</h2>
					)}
					<div className="library-page-content">
						<div className="content-box">
							{data.length ? (
								<ul className="library">
									{data.map((item, i) => (
										<li key={i}>
											{item.library}
											<div className="library-actions">
												<Button color="link" onClick={() => this.handleEdit(item)}>
													<i className="material-icons">mode_edit</i>
												</Button>
												<Button color="link" onClick={() => this.handleDelete(item)}>
													<i className="material-icons">delete</i>
												</Button>
											</div>
										</li>
									))}
								</ul>
							) : loading ? (
								<Loading />
							) : null}
							<Pagination total={total} limit={params.limit} page={params.page} onPageChange={this.handlePageChange} />
						</div>
						<div className="vertical-filter">
							<div className="form-group">
								{alphabet[params.lang].map((letter, i) => (
									<div className="form-check" key={i}>
										<label className="form-check-label">
											<Input
												type="checkbox"
												name="first_letter"
												value={letter}
												onChange={e => this.handleChangeLetter(e.target.value)}
												checked={letter === params.first_letter}
												className="form-check-input"
											/>
											<span>{letter}</span>
										</label>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				<Modal isOpen={modal} toggle={this.toggleModal} size="sm">
					<ModalHeader toggle={this.toggleModal}>{library ? i18n.t("EDIT") : i18n.t("ADD_NEW_WORD")}</ModalHeader>
					<AvForm onValidSubmit={this.handleSubmit} model={library}>
						<ModalBody>
							<AvGroup>
								<AvInput name="library" required />
							</AvGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="link" className="text-dark" onClick={this.toggleModal}>
								{i18n.t("CANCEL")}
							</Button>
							<Button color="link">{library ? i18n.t("SAVE") : i18n.t("CREATE")}</Button>
						</ModalFooter>
					</AvForm>
				</Modal>
				{isAdmin() && (
					<div className="add-action">
						<Button color="success" onClick={this.toggleModal}>
							<i className="material-icons">&#xE145;</i>
						</Button>
					</div>
				)}
			</Fragment>
		)
	}
}

export default Library
