import React, { Component, Fragment } from 'react';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Button, Input, Row, Col } from 'reactstrap';
import Loading from '../components/Loading';
import Charts from '../components/Charts';
import CompareTable from '../components/CompareTable';
import VillagesList from '../components/VillagesList';
import i18n from '../locale/i18n';
import { baseUrl } from '../services/helpers';
import errorHandler from '../services/errorHandler';

class Compare extends Component {
  state = {
    params: null,
    villageQuery: '',
    chartsModal: false,
    villageModal: false,
    expoertLoading: false,
    useCompareModal: false,
    saveCompareModal: false,
    checkedVillages: [],
    chartsLoading: false,
    chartsData: null
  };

  componentDidMount() {
    const {
      Villages,
      getCompareData,
      User: { comparisons },
      getCompareList,
      getVillages
    } = this.props;
    const parsed = localStorage.compare ? JSON.parse(localStorage.compare) : {};
    if (parsed.villages && parsed.categories) {
      const params = {
        villages: parsed.villages,
        categories: Object.values(parsed.categories)
      };

      getCompareData(params);
    }

    if (!Villages.data.length) getVillages();
    if (!comparisons.length) getCompareList();
  }

  componentWillUnmount() {
    this.props.clearCompare();
  }

  getChartData = () => {
    const {
      getCompareNumericData,
      Villages: { params }
    } = this.props;
    this.toggleChartsModal();
    this.setState({ chartsLoading: true });
    getCompareNumericData(params)
      .then(({ data }) => {
        this.setState({
          chartsLoading: false,
          chartsData: data
        });
      })
      .catch(errorHandler);
  };

  compareDataExpoertHandler = () => {
    const {
      exportCompareData,
      Villages: { params }
    } = this.props;
    this.setState({ expoertLoading: true });
    exportCompareData(params)
      .then(() => {
        this.setState({ expoertLoading: false });
      })
      .catch(() => {
        this.setState({ expoertLoading: false });
      });
  };

  duplicateVillage = (id, i) => {
    const { getCompareData, Villages } = this.props;
    const params = Villages.params;
    params.villages.splice(i + 1, 0, { id_village: id });

    const newParams = {
      villages: params.villages,
      categories: Object.values(params.categories)
    };

    getCompareData(newParams).then(() => {
      localStorage.setItem('compare', JSON.stringify(newParams));
    });
  };

  excludeVillage = i => {
    const { getCompareData, Villages } = this.props;
    const params = Villages.params;
    params.villages.splice(i, 1);
    getCompareData(params).then(() => {
      localStorage.setItem('compare', JSON.stringify(params));
    });
  };

  toggleSaveCompare = () => this.setState(state => ({ saveCompareModal: !state.saveCompareModal }));
  toggleUseCompareModal = () => this.setState(state => ({ useCompareModal: !state.useCompareModal }));

  toggleChartsModal = () => {
    document.body.classList.toggle('charts-preview');
    this.setState(state => ({ chartsModal: !state.chartsModal }));
  };

  handleSubmitSave = (event, values) => {
    const {
      saveCompare,
      Villages: { params }
    } = this.props;
    saveCompare({ ...params, name: values.name }).then(this.toggleSaveCompare);
  };

  handleSubmitUse = (event, values) => {
    const {
      getCompareData,
      User: { comparisons }
    } = this.props;
    const params = comparisons[values.index].params;
    this.setState({ useCompareModal: false });

    getCompareData(params).then(() => {
      localStorage.setItem('compare', JSON.stringify(params));
    });
  };

  handleChangeYear = (e, index) => {
    const {
      getCompareData,
      Villages: { params }
    } = this.props;
    if (e.target.value) {
      params.villages[index].archive_year = e.target.value;
    } else {
      delete params.villages[index].archive_year;
    }

    const newParams = {
      categories: params.categories,
      villages: params.villages
    };

    getCompareData(newParams).then(() => {
      localStorage.setItem('compare', JSON.stringify(newParams));
    });
  };

  handleChangeCategory = e => {
    const {
      getCompareData,
      Villages: { params }
    } = this.props;
    const newParams = { ...params, root: e.target.value };
    getCompareData(newParams).then(() => {
      localStorage.setItem('compare', JSON.stringify(newParams));
    });
  };

  toggleVillageModal = () => this.setState(state => ({ villageModal: !state.villageModal }));

  changeVillages = () => {
    const {
      Villages: { data, params }
    } = this.props;

    this.setState(({ checkedVillages }) => ({
      villageModal: true,
      checkedVillages: checkedVillages.length
        ? checkedVillages
        : params.villages.map(i => ({ ...data.find(v => v.id_village === i.id_village), ...i }))
    }));
  };

  attachVillage = (v, checked) => {
    this.setState(({ checkedVillages }) => ({
      checkedVillages: checked ? [...checkedVillages, v] : checkedVillages.filter(i => i.id_village !== v.id_village)
    }));
  };

  applyVillageChanges = () => {
    const {
      getCompareData,
      Villages: { params }
    } = this.props;
    const { checkedVillages } = this.state;
    const newParams = {
      ...params,
      villages: checkedVillages.map(i => ({ id_village: i.id_village, archive_year: i.archive_year }))
    };
    this.setState({
      villageModal: false
    });
    getCompareData(newParams).then(() => {
      localStorage.setItem('compare', JSON.stringify(newParams));
    });
  };

  render() {
    const {
      Villages: { params, compare, loading },
      User: { language, comparisons },
      VillageSearch,
      history
    } = this.props;
    const {
      saveCompareModal,
      useCompareModal,
      chartsModal,
      villageModal,
      villageQuery,
      checkedVillages,
      expoertLoading,
      chartsLoading,
      chartsData
    } = this.state;

    let categories = [];
    let villages = [];

    if (compare && compare.villages && compare.villages.length) {
      categories = compare.villages[0].data.map(i => i.category);
      villages = compare.villages;
    }

    return (
      <div className="app-content-wrapper">
        <div className="app-content content-fluid">
          <div className="app-content-header">
            <h1>{i18n.t('COMPARISON')}</h1>
          </div>
          <div className="app-content-inner">
            {loading ? (
              <Loading />
            ) : compare ? (
              <Fragment>
                <Button onClick={e => history.goBack()} color="link btn-back">
                  <i className="material-icons">&#xE317;</i>
                  {i18n.t('BACK')}
                </Button>
                <div className="compare-actions">
                  <Button color="success" onClick={this.changeVillages}>
                    <i className="material-icons">done_all</i>
                    {i18n.t('villages')}
                  </Button>
                  <Button color="success" onClick={this.getChartData}>
                    <i className="material-icons">show_chart</i>
                    {i18n.t('SHOW_IN_CHARTS')}
                  </Button>
                  <Button color="primary" onClick={this.toggleSaveCompare}>
                    <i className="material-icons">check</i>
                    {i18n.t('SAVE')}
                  </Button>
                  {Boolean(comparisons.length) && (
                    <Button color="primary" onClick={this.toggleUseCompareModal}>
                      <i className="material-icons">replay</i>
                      {i18n.t('USE_SAVE_COMPARISON')}
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={expoertLoading ? 'btn-loading' : ''}
                    onClick={this.compareDataExpoertHandler}
                    disabled={Boolean(!villages.length)}>
                    <i className="material-icons">file_download</i>
                    {i18n.t('DOWNLOAD')}
                  </Button>
                </div>
                <div className="table-responsive mb-5">
                  <CompareTable
                    villages={villages}
                    columns={compare.columns}
                    category={params.root}
                    rows={categories.filter(c => c.has_content)}
                    handleChangeCategory={this.handleChangeCategory}
                    handleChangeYear={this.handleChangeYear}
                    duplicateVillage={this.duplicateVillage}
                    excludeVillage={this.excludeVillage}
                  />
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>

        <Modal isOpen={saveCompareModal} toggle={this.toggleSaveCompare} size="sm">
          <ModalHeader toggle={this.toggleSaveCompare}>{i18n.t('SAVE_COMPARISON')}</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitSave}>
            <ModalBody>
              <AvGroup>
                <AvInput name="name" type="text" required />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleSaveCompare}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={useCompareModal} toggle={this.toggleUseCompareModal} size="sm">
          <ModalHeader toggle={this.toggleUseCompareModal}>{i18n.t('USE_SAVE_COMPARISON')}</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitUse}>
            <ModalBody>
              <AvGroup>
                <AvInput name="index" type="select" required>
                  <option value="" disabled>
                    {i18n.t('CHOOSE')}
                  </option>
                  {comparisons.map((item, index) => (
                    <option key={index} value={index}>
                      {item.name}
                    </option>
                  ))}
                </AvInput>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleUseCompareModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('USE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={chartsModal} toggle={this.toggleChartsModal} size="lg" className="charts-modal">
          <ModalHeader>
            <img src={baseUrl('/img/logo2.png')} alt="logo" height="37" />
            <div className="modal-actions">
              <Button color="link text-danger" onClick={() => window.print()} data-tooltip={i18n.t('PRINT')}>
                <i className="material-icons">print</i>
              </Button>
              <Button color="link" onClick={this.toggleChartsModal} data-tooltip={i18n.t('CLOSE')}>
                <i className="material-icons">close</i>
              </Button>
            </div>
          </ModalHeader>
          <ModalBody>
            {chartsLoading ? <Loading /> : chartsData ? <Charts data={chartsData} language={language} /> : null}
          </ModalBody>
        </Modal>

        <Modal isOpen={villageModal} toggle={this.toggleVillageModal} size="lg">
          <ModalHeader toggle={this.toggleVillageModal}>{i18n.t('ATTACH_TO_VILLAGE')}</ModalHeader>
          <ModalBody>
            <Row className="attach-data-row">
              <Col md="8" className="data-col">
                <div className="form-group w-50 p-0">
                  <Input
                    name="keyword"
                    value={villageQuery}
                    onChange={e => this.setState({ villageQuery: e.target.value })}
                    placeholder={i18n.t('SEARCH_KEYWORD')}
                  />
                </div>
                <VillagesList
                  items={VillageSearch(villageQuery, i18n.language)}
                  checkedVillages={checkedVillages}
                  onAttach={this.attachVillage}
                  maxLength={15}
                />
              </Col>
              <Col md="4" className="attached-col">
                <h3>{i18n.t('villages')}</h3>
                <ul className="attached-list">
                  {checkedVillages.map((item, index) => (
                    <li key={index}>
                      <span>{item.name[language]}</span>
                      <button className="no-style" onClick={e => this.attachVillage(item, false)}>
                        <i className="material-icons">close</i>
                      </button>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="text-dark" onClick={this.toggleVillageModal}>
              {i18n.t('CANCEL')}
            </Button>
            <Button color="link" onClick={this.applyVillageChanges}>
              {i18n.t('SAVE')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Compare;
