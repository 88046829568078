import { connect } from 'react-redux';
import GroupRegions from '../../../pages/Account/Groups/GroupRegions';
import { putGroupRegions } from '../../../actions/Groups';
import { groupBy } from '../../../services/helpers';

const updateRegions = (() => {
  let regions = {
    places: [],
    districts: [],
    states: []
  };
  return list => {
    if (!regions.places.length && list.places.length) {
      const districtPlaces = groupBy(list.places, 'id_district');
      const statePlaces = groupBy(list.places, 'id_state');
      const stateDistricts = groupBy(list.districts, 'id_state');
      regions = {
        places: list.places,
        districts: list.districts.map(d => ({ ...d, places: districtPlaces[d.id].map(p => p.id) })),
        states: list.states.map(s => ({
          ...s,
          places: statePlaces[s.id].map(p => p.id),
          districts: stateDistricts[s.id].map(d => d.id)
        }))
      };
    }
    return regions;
  };
})();

const mapStateToProps = state => {
  return {
    Regions: updateRegions(state.User.regions),
    Group: state.Groups.currentGroup
  };
};

const mapDispatchToProps = dispatch => {
  return {
    putGroupRegions(id, body, places) {
      dispatch(putGroupRegions(id, body, places));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupRegions);
