import { connect } from "react-redux";
import GroupInvitations from "../../../pages/Account/Groups/GroupInvitations";
import { addInvitation, editInvitation, deleteInvitation } from "../../../actions/Invitations";

const mapStateToProps = state => {
	return {
		Groups: state.Groups,
		Invitations: state.Invitations,
		GroupInvitations: state.Invitations.data.filter(i => i.id_group === state.Groups.currentGroup.id_group)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addInvitation(body) {
			return dispatch(addInvitation(body));
		},
		editInvitation(body, id) {
			return dispatch(editInvitation(body, id));
		},
		deleteInvitation(id) {
			return dispatch(deleteInvitation(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupInvitations);
