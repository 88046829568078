import { isJson } from "./helpers"

const tokenDecoder = token => {
	const base64Url = token.split(".")[1]
	if (!base64Url) {
		return null
	} else {
		const base64 = base64Url.replace("-", "+").replace("_", "/")
		const str = window.atob(base64)
		return isJson(str) ? JSON.parse(str) : null
	}
}

const initialUser = () => {
	const token = localStorage.token
	if (token) {
		const payload = tokenDecoder(token)
		const now = new Date().getTime() / 1000
		if (!payload || now > payload.exp) {
			return null
		} else {
			return {
				...payload.data,
				first_name: "-",
				last_name: "-"
			}
		}
	} else {
		return null
	}
}

export { tokenDecoder, initialUser }
