import React, { Component } from "react"
import qs from "qs"
import { Button } from "reactstrap"
import i18n from "../../locale/i18n"

class Comparisons extends Component {
	componentDidMount() {
		const { User: { comparisons }, getCompareList } = this.props
		if (!comparisons.length) getCompareList()
	}
	applyComparison = item => {
		this.props.history.push({
			pathname: "/compare",
			search: qs.stringify(item.params)
		})
	}

	render() {
		const { User: { comparisons } } = this.props
		return (
			<div>
				<div className="app-content-header">
					<h1>Պահպանած Համեմատություններ</h1>
				</div>
				<div className="app-content-inner">
					<div className="content-container content-sm">
						<ul className="panel-list">
							{comparisons.map((item, index) => (
								<li className="list-item" key={index}>
									<div className="item-header">
										<div className="item-title">{item.name}</div>
										<div className="item-actions">
											<Button
												color="link px-2"
												onClick={e => this.applyComparison(item)}
												data-tooltip={i18n.t("APPLY")}>
												<i className="material-icons">forward</i>
											</Button>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

export default Comparisons
