import React from 'react';
import { groupBy } from '../services/helpers';

const RegionsTree = ({ places, regions, togglePlaces }) => {
  const fullPlaces = regions.places.filter(i => places.has(i.id));
  const getTree = () => {
    const tree = groupBy(fullPlaces, 'id_state');
    for (const key in tree) {
      if (tree.hasOwnProperty(key)) {
        tree[key] = groupBy(tree[key], 'id_district');
      }
    }
    return tree;
  };
  const tree = getTree();
  return (
    <div className="regions-tree">
      <h3 className="mb-3">Հասանելի տարածքներ</h3>
      <ul>
        {Object.keys(tree).map(s => {
          const state = regions.states.find(i => i.id === +s);
          return (
            <li key={s}>
              <span>
                {state.name}
                <button className="btn btn-link text-danger ml-2 p-0" onClick={() => togglePlaces(false, state.places)}>
                  <i className="material-icons">close</i>
                </button>
              </span>
              <ul>
                {Object.keys(tree[s]).map(d => {
                  const district = regions.districts.find(i => i.id === +d);
                  return (
                    <li key={d}>
                      <span>
                        {district.name}
                        <button
                          className="btn btn-link text-danger ml-2 p-0"
                          onClick={() => togglePlaces(false, district.places)}>
                          <i className="material-icons">close</i>
                        </button>
                      </span>
                      <ul>
                        {tree[s][d].map(p => (
                          <li key={p.id}>
                            <span>
                              {p.name}
                              <button
                                className="btn btn-link text-danger ml-2 p-0"
                                onClick={() => togglePlaces(false, p.id)}>
                                <i className="material-icons">close</i>
                              </button>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default RegionsTree;
