import React, { Component } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import MapComponent from '../components/MapComponent';

class VillagesMap extends Component {
  render() {
    return (
      <div className="map-container">
        <MapComponent google={this.props.google} villages={this.props.villages} />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyA8QW-g1VM96iT-qK_8RGt_Q_aFtxrKEmo'
})(VillagesMap);
