import React, { Component } from "react"
import { paginationOptions } from "../constants/"
import Paginate from "react-paginate"

class Pagination extends Component {
	render() {
		const { page, total, limit, onPageChange } = this.props
		return (
			<div className="d-flex justify-content-end">
				{total > limit && (
					<Paginate
						{...paginationOptions}
						forcePage={page - 1}
						pageCount={Math.ceil(total / limit)}
						onPageChange={onPageChange}
					/>
				)}
			</div>
		)
	}
}

export default Pagination
