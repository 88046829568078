import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';

import FormsTopActions from '../../components/Forms/FormsTopActions';
import FormsDate from '../../components/Forms/FormsDate';
import FormField from '../../components/Forms/FormField';
import Loading from '../../components/Loading';
import { findById } from '../../services/helpers';
import instance from '../../services/api';
import { isViewer } from '../../services/permissions';

class FormReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      loading: true,
      currentData: null,
      rejetModal: false,
      counter: 0,
      viewMode: window.localStorage.getItem('viewMode') || 'grid'
    };
    this.findDistrict = findById();
    this.findState = findById();
    this.formType = props.location.pathname.split('/')[2];
  }

  componentDidMount() {
    this.getForm(this.props);
  }

  componentDidUpdate(prevProps, ps) {
    const np = this.props.match.params;
    const pp = prevProps.match.params;
    const { current } = this.props.Forms;
    if (ps.currentData) {
      if (np.id_form !== pp.id_form) {
        this.setState({ loading: true });
        this.getForm(this.props);
      } else if (np.id_place && np.id_place !== pp.id_place) {
        this.setFormData(current, np.id_place);
      }
    }
  }

  setFormData = (data, id_place) => {
    this.formType = this.props.location.pathname.split('/')[2];
    const currentData = data[this.formType] && data[this.formType].find((i) => +i.id_place === +id_place);
    if (currentData) {
      const { current } = this.props.Forms;
      const initialData = current.fields.reduce(
        (acc, i) => ({
          ...acc,
          [i.name]: { content: '', id_category: i.name, isValid: true, rejected: false }
        }),
        {}
      );
      const formData = currentData.data_json.reduce(
        (acc, i) => ({
          ...acc,
          [i.id_category]: { ...i, isValid: true, rejected: i.rejected || false }
        }),
        initialData
      );
      this.setState({ formData, currentData, loading: false });
    } else {
      this.props.history.push('/forms');
    }
  };

  getForm(props) {
    const {
      match,
      history,
      Forms: { current }
    } = props;
    const { id_form, id_place } = match.params;
    if (current && +current.id_form === +id_form) {
      this.setFormData(current, id_place);
    } else {
      this.props
        .getForm(id_form)
        .then(({ payload }) => {
          this.setFormData(payload, id_place);
        })
        .catch(() => history.push('/forms'));
    }
  }

  toggleMode = (mode) => {
    this.setState({ viewMode: mode });
    window.localStorage.setItem('viewMode', mode);
  };

  toggleRejected = ({ target }) => {
    const name = target.name;
    this.setState((prev) => ({
      formData: {
        ...prev.formData,
        [name]: {
          ...prev.formData[name],
          rejected: target.checked
        }
      },
      counter: target.checked ? prev.counter + 1 : prev.counter - 1
    }));
  };

  handleReject = (e, values) => {
    const { formData, currentData } = this.state;
    this.setState({ loading: true, rejetModal: false });
    instance
      .post('/forms/sendback', { ...currentData, ...values, data_json: Object.values(formData) })
      .then(({ data }) => {
        toast.success('Նախագիծը հաջողությամբ ուղարկված է հետ');
        this.props.updateForms();
        this.props.history.push('/forms');
      });
  };

  handleAccept = (e) => {
    const { currentData } = this.state;
    this.setState({ loading: true, rejetModal: false });

    instance.post('/forms/approve', { ...currentData }).then(() => {
      toast.success('Նախագիծը հաջողությամբ հաստատված է։');
      this.props.updateForms();
      this.props.history.push('/forms');
    });
  };

  toggleModal = () => {
    this.setState((prev) => ({ rejetModal: !prev.rejetModal }));
  };

  render() {
    const { formData, viewMode, loading, currentData, counter } = this.state;
    const {
      history,
      Forms: { current },
      Regions: { states, districts }
    } = this.props;
    const isGrid = viewMode === 'grid';
    const district = currentData && districts.length ? this.findDistrict(currentData.id_district, districts) : null;
    const state = currentData && states.length ? this.findState(currentData.id_state, states) : null;
    const isSubmittedForm = this.formType === 'submitted';

    return (
      <div className="app-content forms-page-content">
        <div className="app-content-header">
          <div className="form-group">
            <div className="form-control">{state && state.name}</div>
          </div>
          <div className="form-group">
            <div className="form-control">{district && district.name}</div>
          </div>
        </div>
        <div className="app-content-inner">
          <h1 className="forms-page-title">{isSubmittedForm ? 'Ուղարկված' : 'Հաստատված'} հարցաշարեր</h1>
          {!loading ? (
            <div className="content-box">
              <div className="content-inner-header">
                <FormsTopActions history={history} mode={viewMode} toggle={this.toggleMode} />
                <h1 className="content-header-title">{current.name}</h1>
                <div className="content-header-input-wrapper">
                  <div className="form-control content-header-input">{currentData.place_name}</div>
                  <FormsDate date={currentData.submitted_date} preffix="Ուղարկվել է՝" />
                </div>
              </div>
              <Row>
                {current.fields.map((field) => (
                  <FormField
                    field={field}
                    key={field.name}
                    isGrid={isGrid}
                    formData={formData}
                    toggleRejected={isSubmittedForm && !isViewer() ? this.toggleRejected : undefined}
                  />
                ))}
              </Row>
              {isSubmittedForm && !isViewer() && (
                <div className="form-actions mb-2">
                  <Button type="button" color="primary" onClick={this.toggleModal}>
                    Հետ Ուղարկել
                  </Button>
                  <Button color="success" id="formSubmitBtn" onClick={this.handleAccept} disabled={!!counter}>
                    Հաստատել
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <Loading />
          )}
        </div>
        <Modal isOpen={this.state.rejetModal} toggle={this.toggleModal} size="sm">
          <ModalHeader toggle={this.toggleModal}>Մերժման դրդապատճառ</ModalHeader>
          <AvForm onValidSubmit={this.handleReject}>
            <ModalBody>
              <AvGroup>
                <AvInput name="reject_message" type="textarea" rows="6" required />
              </AvGroup>
            </ModalBody>
            <div className="text-right p-3">
              <Button type="button" onClick={this.toggleModal}>
                Չեղարկել
              </Button>
              <Button color="success ml-2">Ուղարկել</Button>
            </div>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default FormReview;
