import { connect } from "react-redux";
import ForgotPassword from "../pages/ForgotPassword";
import { forgotPassword } from "../actions/Auth";

const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = dispatch => {
	return {
		forgotPassword(body) {
			return dispatch(forgotPassword(body));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
