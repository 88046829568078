import instance from "../services/api"
import errorHandler from "../services/errorHandler"

export const getLibrary = params => {
	return dispatch => {
		dispatch({ type: "LIBRARYS_REQ", payload: params })
		return instance
			.get(`/library`, { params: params })
			.then(({ data }) => {
				dispatch({ type: "LIBRARYS_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const searchLibrary = params => {
	return dispatch => {
		dispatch({ type: "LIBRARYS_REQ", payload: params })
		return instance
			.get(`/library/search`, { params: params })
			.then(({ data }) => {
				dispatch({ type: "LIBRARY_SEARCH_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const getLibraryById = id => {
	return dispatch => {
		dispatch({ type: "LIBRARY_REQ" })
		return instance
			.get(`/library/${id}`)
			.then(({ data }) => {
				dispatch({ type: "LIBRARY_RES", payload: data })
			})
			.catch(errorHandler)
	}
}

export const addLibrary = (body, lang) => {
	return dispatch => {
		dispatch({ type: "LIBRARY_REQ" })
		return instance
			.post(`/library`, body, { params: { lang } })
			.then(({ data }) => {
				dispatch({ type: "LIBRARY_ADDED", payload: data })
			})
			.catch(errorHandler)
	}
}

export const editLibrary = (body, id, lang) => {
	return dispatch => {
		dispatch({ type: "LIBRARY_REQ" })
		return instance
			.put(`/library/${id}`, body, { params: { lang } })
			.then(({ data }) => {
				dispatch({ type: "LIBRARY_EDITED", payload: data })
			})
			.catch(errorHandler)
	}
}

export const deleteLibrary = (id, lang) => {
	return dispatch => {
		dispatch({ type: "LIBRARY_REQ" })
		return instance
			.delete(`/library/${id}`, { params: { lang } })
			.then(({ data }) => {
				dispatch({ type: "LIBRARY_DELETED", payload: id })
			})
			.catch(errorHandler)
	}
}
