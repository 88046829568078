import { limits } from "../constants/"
const initialState = {
  data: [],
  current: null,
  params: {
    keyword: "",
    first_letter: "",
    lang: "am",
    page: 1,
    limit: limits.library
  },
  total: 0,
  loading: false
};

const Library = (state = initialState, action) => {
  switch (action.type) {
    case "LIBRARY_REQ":
      return {
        ...state,
        loading: true
      };
    case "LIBRARYS_RES":
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case "LIBRARYS_REQ":
      return {
        ...state,
        params: {
          ...state.params,
          ...action.payload
        },
        loading: true
      };
    case "LIBRARY_SEARCH_RES":
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case "LIBRARY_RES":
      return {
        ...state,
        current: action.payload,
        loading: false
      };
    case "LIBRARY_ADDED":
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false
      };
    case "LIBRARY_EDITED":
      return {
        ...state,
        data: state.data.map(item => {
          if (item.id_library === action.payload.id_library) {
            return action.payload
          } else {
            return item
          }
        }),
        loading: false
      };
    case "LIBRARY_DELETED":
      return {
        ...state,
        data: state.data.filter(i => i.id_library !== action.payload),
        loading: false
      };
    case "USER_LOGOUT":
      return { ...initialState };
    default:
      return state
  }
};

export default Library
