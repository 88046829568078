import React, { Component } from 'react';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import i18n from '../../locale/i18n';
import { isViewer } from '../../services/permissions';

class FilteredSections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editModal: false,
      currentState: null
    };
  }

  applyState = state => {
    const {
      User: { language },
      getDataFlat,
      history
    } = this.props;
    const params = {
      recent_state: false,
      id_user_state: state.id_user_state,
      hiddenColums: state.state.hiddenColums || [],
      order_column: state.state.order_column || null,
      archive_year: state.state.archive_year || '',
      order_by: state.state.order_by || 'asc',
      categories: state.state.categories || [],
      id_district: state.state.id_district || 0,
      id_state: state.state.id_state || 0,
      limit: state.state.limit || 100,
      page: state.state.page || 1,
      lang: language
    };

    getDataFlat(params);
    history.push('/');
  };

  handleDeleteState = (id, state) => {
    const confirmation = window.confirm(`Do you want to delete selected state?`);
    if (confirmation) {
      this.props.deleteState(id, state);
    }
  };

  handleEditState = state => this.setState({ editModal: true, currentState: state });
  toggleEditModal = () => this.setState({ editModal: !this.state.editModal, currentState: null });
  handleSubmitEdit = (event, values) => {
    const { User, editState } = this.props;
    const { currentState } = this.state;

    editState(User.data.id_user, currentState.id_user_state, values.name).then(() => {
      this.setState({ editModal: false, currentState: null });
    });
  };

  render() {
    const {
      User: { data, savedStates }
    } = this.props;
    const { editModal, currentState } = this.state;

    return (
      <div>
        <div className="app-content-header">
          <h1>Ֆիլտրած Սյունակներ</h1>
        </div>
        <div className="app-content-inner">
          <div className="content-container content-sm">
            <ul className="panel-list">
              {savedStates.map((state, index) => (
                <li className="list-item" key={index}>
                  <div className="item-header">
                    <div className="item-title">{state.name}</div>
                    <div className="item-actions">
                      <Button
                        color="link px-2"
                        onClick={e => this.applyState(state)}
                        data-tooltip={i18n.t('APPLY_STATE')}>
                        <i className="material-icons">forward</i>
                      </Button>
                      <Button
                        color="link px-2"
                        onClick={e => this.handleEditState(state)}
                        data-tooltip={i18n.t(isViewer() ? 'SUBMIT' : 'EDIT_STATE')}>
                        <i className="material-icons">mode_edit</i>
                      </Button>
                      <Button
                        color="link px-2"
                        onClick={e => this.handleDeleteState(data.id_user, state.id_user_state)}
                        data-tooltip={i18n.t(isViewer() ? 'SUBMIT' : 'DELETE_STATE')}>
                        <i className="material-icons">delete</i>
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Modal isOpen={editModal} toggle={this.toggleEditModal} size="sm">
          <ModalHeader toggle={this.toggleEditModal}>{i18n.t('EDIT')}</ModalHeader>
          <AvForm onValidSubmit={this.handleSubmitEdit} model={currentState}>
            <ModalBody>
              <AvGroup>
                <AvInput name="name" type="text" required />
                <Label className="form-control-label">{i18n.t('name')}</Label>
              </AvGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="link" className="text-dark" onClick={this.toggleEditModal}>
                {i18n.t('CANCEL')}
              </Button>
              <Button color="link">{i18n.t('SAVE')}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default FilteredSections;
