import React, { Component, Fragment } from 'react';
import { conditions } from '../constants/';
import i18n from '../locale/i18n';

class QueryCollapse extends Component {
  render() {
    const { item, toggle } = this.props;
    const cats = item.selectedCategories;
    const monument = item.keyword_source === 'monuments';
    let categories = '-';
    if (cats.length > 1) {
      categories = `${cats[0].name[i18n.language]}, ...`;
    } else if (cats.length) {
      if (monument) {
        categories = `${cats[0].name[i18n.language]}, ...`;
      } else {
        categories = `${cats[0].name[i18n.language]}`;
      }
    } else if (monument) {
      categories = i18n.t('monuments');
    }

    return (
      <div className="query-collapsed" onClick={toggle}>
        <div className="collapse-condition">{i18n.t(conditions.find(i => i.key === item.condition).label)}</div>
        <div className="collapse-item">
          <span className="item-label">{i18n.t('CATEGORIES')}</span>
          {categories}
        </div>
        <div className="collapse-item">
          {item.keyword.length > 1 ? (
            <Fragment>
              <span className="tag-item">{item.keyword[0]}</span>
              <span className="tag-item">+{item.keyword.length - 1}</span>
            </Fragment>
          ) : item.keyword.length ? (
            <span className="tag-item">{item.keyword[0]}</span>
          ) : (
            '-'
          )}
        </div>
        {Boolean(item.state) && (
          <div className="collapse-item">
            <span className="item-label">{i18n.t('state')}</span>
            {item.state[i18n.language]}
          </div>
        )}
        {Boolean(item.district) && (
          <div className="collapse-item">
            <span className="item-label">{i18n.t('district')}</span>
            {item.district[i18n.language]}
          </div>
        )}
        {Boolean(item.archive_year) && (
          <div className="collapse-item">
            <span className="item-label">{i18n.t('archive_year')}</span>
            {item.archive_year}
          </div>
        )}
      </div>
    );
  }
}
export default QueryCollapse;
